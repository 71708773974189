'use strict';
import SxQueryObject, { _constructz } from './SxQueryObject';
import SxQueryUtils from './SxQueryUtils';
var sxQuery = _constructz;
Object.keys(SxQueryUtils).forEach(function (key) {
    sxQuery[key] = SxQueryUtils[key];
});
var handler = {
    set: function (obj, prop, value) {
        SxQueryObject.prototype[prop] = value;
        return true;
    }
};
try {
    sxQuery.fn = new Proxy({}, handler);
}
catch (e) { // proxy not supported --> IE
    sxQuery.fn = SxQueryObject.prototype;
}
export default sxQuery;
