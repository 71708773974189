'use strict';
import Helper from '../utils/Helper';
import sxQuery from '../../sxQuery/sxQuery';
import NavigationPosition from '../enums/NavigationPosition';
var Smart404 = {
    checkAndHandle: function (context) {
        var pluginConfiguration = context.pluginConfiguration;
        var smart404Config = pluginConfiguration.smart404;
        var is404 = smart404Config.cssIdentifier !== undefined
            ? sxQuery(smart404Config.cssIdentifier).length > 0 // is a 404 selector present?
            : document.querySelector('title').text.toLowerCase().indexOf(smart404Config.identifier.toLowerCase()) > -1; // does the title contain identifier?
        if (is404) {
            is404 = window.location.search.indexOf(encodeURIComponent(pluginConfiguration.results.searchQueryParamName) + "=") === -1; // we already have a query param query - don't use smart404 as ss360 is currently single instance and this causes conflicts
        }
        if (is404) {
            context.setIsSmart404(true);
            var ss360Settings_1 = pluginConfiguration;
            var configCache_1 = {
                showErrors: ss360Settings_1.showErrors,
                results: Helper.copyObject(pluginConfiguration.results),
                layout: Helper.copyObject(pluginConfiguration.layout),
                contentGroups: Helper.copyObject(pluginConfiguration.contentGroups),
                filters: Helper.copyObject(pluginConfiguration.filters)
            };
            ss360Settings_1.showErrors = false;
            context.core.changeConfig('results.embedConfig', {
                contentBlock: smart404Config.resultSelector
            });
            var resultConfig = ss360Settings_1.results;
            var layoutConfig = ss360Settings_1.layout;
            resultConfig.caption = smart404Config.caption;
            resultConfig.group = false;
            resultConfig.queryCorrectionText = '';
            resultConfig.queryCorrectionRewrite = '';
            ss360Settings_1.contentGroups.otherName = '';
            var num = Math.min(smart404Config.num, 12);
            resultConfig.num = num;
            resultConfig.moreResultsPagingSize = num;
            resultConfig.highlightQueryTerms = true;
            layoutConfig.desktop.showDataPoints = false;
            layoutConfig.desktop.showUrl = true;
            layoutConfig.mobile.showDataPoints = false;
            layoutConfig.mobile.showUrl = true;
            layoutConfig.navigation.position = NavigationPosition.None;
            ss360Settings_1.filters.enabled = false;
            // get and clean up URL to form search query
            var pathParts = document.location.pathname.split('/').filter(function (part) { return part !== ''; });
            if (pathParts.length > 0) {
                var pathQuery_1 = pathParts[Math.max(0, pathParts.length - 1)].replace(/\.html?s?|\.php3?|\.aspx|\.jsp/, '').replace('404', '').replace(/[/-]/gi, ' ');
                // create target layer to avoid conflicts
                sxQuery('body').append("<section id=\"" + context.layer404Id + "\" class=\"ss360-n-section ss360-404\" style=\"display: block;\" aria-label=\"Link Suggestions\"></section>");
                // show and set config to previous settings
                setTimeout(function () {
                    context.core.showResults(pathQuery_1, {
                        sort: '_relevance',
                        shouldPushState: false,
                        callback: function () {
                            Object.keys(configCache_1).forEach(function (key) {
                                ss360Settings_1[key] = configCache_1[key];
                            });
                            context.core.changeConfig('results.embedConfig', configCache_1.results.embedConfig);
                            context.setIsSmart404(false);
                        },
                        submitSource: 'smart404'
                    });
                }, 0);
            }
            return true;
        }
        return false;
    }
};
export default Smart404;
