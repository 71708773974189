'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import sxQuery from '../sxQuery/sxQuery';
import UiHelper from './ui/UiHelper';
import StringHelper from '../global/StringHelper';
import NavigatorHelper from './utils/NavigatorHelper';
import UiBuilder from './ui/UiBuilder';
import ConfigurationHelper from './configuration/ConfigurationHelper';
import Helper from './utils/Helper';
import Handlers from './ui/Handlers';
import Smart404 from './components/Smart404';
import StyleApplier from './components/StyleApplier';
import VoiceSearch from './components/VoiceSearch';
import Results from './components/Results';
import Navigation from './components/Navigation';
import Sorting from './components/Sorting';
import Polyfill from './polyfills/Polyfill';
import Skelet from './ui/Skelet';
import LazyLoad from './utils/LazyLoad';
import Masonry from './layout/Masonry';
import SubConfigApplier from './utils/SubConfigApplier';
import Logger from '../global/Logger';
import Layer from './components/Layer';
import ContentGroupHelper from '../global/ContentGroupHelper';
import ErrorScreen from './components/ErrorScreen';
import ProductFinder from './components/ProductFinder';
import { createContext } from './Context';
import ApiConfig from './api/ApiConfig';
import SearchResultType from './enums/SearchResultType';
import Icons from './components/Icons';
import TrackingAction from './enums/TrackingAction';
import Plan from './enums/Plan';
var SEARCH_BASE = 'https://global.sitesearch360.com';
var ECOM_SEARCH_BASE = 'https://ecom.sitesearch360.com';
var ECOM_DEV_SEARCH_BASE = 'https://dev-api-v3.semknox.com';
var SiteSearch360Core = /** @class */ (function () {
    function SiteSearch360Core(ss360settings, isDefaultInstance) {
        if (isDefaultInstance === void 0) { isDefaultInstance = true; }
        // @ts-ignore
        this.VERSION = SS360_VERSION;
        this.initContext(ss360settings, isDefaultInstance);
        this.initTracking();
    }
    SiteSearch360Core.prototype.initContext = function (ss360Settings, isDefaultInstance) {
        if (isDefaultInstance === void 0) { isDefaultInstance = true; }
        var siteId = ss360Settings.siteId;
        this.siteId = siteId;
        var ecom = ss360Settings.ecom;
        var ecomDev = ss360Settings.ecomDev;
        var baseUrl = SEARCH_BASE;
        var suggestBaseUrl;
        var isFullBaseUrl = false;
        if (ecomDev) {
            baseUrl = ECOM_DEV_SEARCH_BASE;
        }
        else if (ecom) {
            baseUrl = ECOM_SEARCH_BASE;
        }
        if (ss360Settings.baseUrl !== undefined) {
            baseUrl = ss360Settings.baseUrl;
            isFullBaseUrl = true;
        }
        if (ss360Settings.suggestBaseUrl !== undefined) {
            suggestBaseUrl = ss360Settings.suggestBaseUrl;
            isFullBaseUrl = true;
        }
        this.context = createContext(new ApiConfig(baseUrl, ecomDev || ecom, siteId, isFullBaseUrl, suggestBaseUrl), ss360Settings, this, new Layer(), isDefaultInstance);
        if (ecom || ecomDev) {
            var insightsBase = (ecom ? ECOM_SEARCH_BASE : ECOM_DEV_SEARCH_BASE) + "/insights";
            var insightsParams = { projectId: siteId };
            ss360Settings.tracking.enhancedBaseUrl = insightsBase;
            ss360Settings.tracking.enhancedParams = insightsParams;
        }
        if (ss360Settings.baseUrl !== undefined) {
            baseUrl = ss360Settings.baseUrl;
            suggestBaseUrl = ss360Settings.suggestBaseUrl;
            isFullBaseUrl = true;
        }
        var pluginConfiguration = this.context.pluginConfiguration;
        this.pluginConfiguration = pluginConfiguration;
        this.trackingConfiguration = pluginConfiguration.tracking;
        this.resultsConfiguration = pluginConfiguration.results;
        this.filtersConfiguration = pluginConfiguration.filters;
        this.callbacksConfiguration = pluginConfiguration.callbacks;
        this.searchBoxConfiguration = pluginConfiguration.searchBox;
        this.contentGroupsConfiguration = pluginConfiguration.contentGroups;
        this.searchResultType = this.context.getSearchResultType();
        this.searchBoxSelector = pluginConfiguration.searchBox.selector;
        this.trackingEnabled = this.trackingConfiguration.logQueries && this.context.readCookie('ss360-tracking') !== '0';
        this.wereSuggestGroupsModified = false;
        this.areResultsVisible = false;
        this.fullScreenOpen = false;
        this.is404 = false;
        this.autoBlurTime = -1;
        this.searchBoxes = [];
        this.lastSearchTerm = '';
        this.hiddenParts = UiHelper.getHiddenParts(pluginConfiguration.layout);
        this.uiBuilder = this.createUiBuilder();
    };
    SiteSearch360Core.prototype.initTracking = function () {
        var _this = this;
        if (this.trackingEnabled) {
            this.enableInsights();
        }
        if (this.trackingConfiguration.logQueries) {
            sxQuery(window).on('keydown.ss360TrackingDisabler', undefined, function (e) {
                if (e.ctrlKey && e.shiftKey && e.key !== undefined && e.key.toLowerCase() === 'e') {
                    _this.trackingEnabled = !_this.trackingEnabled;
                    var cookieVal = _this.trackingEnabled ? '1' : '0';
                    if (_this.trackingEnabled) {
                        _this.enableInsights();
                        Logger.info('ss360: tracking enabled');
                    }
                    else {
                        _this.disableInsights();
                        Logger.info('ss360: tracking disabled');
                    }
                    _this.context.createCookie('ss360-tracking', cookieVal, 365);
                }
            });
        }
    };
    SiteSearch360Core.prototype.enableInsights = function () {
        var _this = this;
        this.context.createInsights();
        sxQuery(this.searchBoxSelector).on('focus.ss360insights', undefined, function (e) {
            _this.context.getInsights().trackSearchBoxFocus(e.target);
        });
    };
    SiteSearch360Core.prototype.disableInsights = function () {
        this.context.disableInsights();
        sxQuery(this.searchBoxSelector).off('focus.ss360insights');
    };
    SiteSearch360Core.prototype.changeConfig = function (key, value) {
        this.updateConfig(key, value);
    };
    SiteSearch360Core.prototype.updateConfig = function (key, value) {
        ConfigurationHelper.updateConfig(this.pluginConfiguration, key, value);
        if (key === 'siteId') {
            this.setSiteId(value);
        }
        else if (key === 'results.embedConfig') {
            this.searchResultConfig = value;
            if (value !== undefined && this.searchResultType === SearchResultType.Layover) {
                sxQuery('#ss360CloseLayerButton').remove();
                if (!this.pluginConfiguration.results.showSearchBoxEmbed) {
                    this.context.getExistingCustomSearch().remove();
                }
            }
        }
        else if (key === 'results.fullscreenConfig.caption' || key === 'results.fullScreenConfig.caption') {
            sxQuery("#" + this.context.consoleId + " > h" + this.uiBuilder.captionHeadingLevel).html(value);
        }
        else if (key === 'searchBox.placeholder') {
            this.context.getExistingCustomSearchBox().attr('placeholder', value);
        }
        else if (key.indexOf('contentGroups.') === 0 || key.indexOf('suggestions.') === 0 || key.indexOf('results.group') === 0) {
            this.updateSuggestionUrl(this.buildSuggestionUrl());
        }
        this.searchResultType = this.context.getSearchResultType();
        if (key.indexOf('layout') !== -1) {
            this.hiddenParts = UiHelper.getHiddenParts(this.pluginConfiguration.layout);
        }
        if (key.indexOf('layout') !== -1 || key.indexOf('results') !== -1) {
            this.uiBuilder = this.createUiBuilder();
        }
        if (key.indexOf('masonryCols') !== -1) {
            Masonry.notifyConfigChange(this.pluginConfiguration.layout);
        }
        if (key === 'activeSubConfigId') {
            SubConfigApplier.apply(this.context);
        }
    };
    SiteSearch360Core.prototype.setSiteId = function (newSiteId) {
        if (this.suggestionUrl !== undefined) {
            this.updateSuggestionUrl(this.suggestionUrl.replace("site=" + this.siteId, "site=" + newSiteId));
        }
        this.siteId = newSiteId;
        this.pluginConfiguration.siteId = this.siteId;
        if (this.context.hasInsights()) {
            this.context.createInsights();
        }
    };
    SiteSearch360Core.prototype.updateSuggestionUrl = function (newUrl) {
        this.suggestionUrl = newUrl;
        for (var i = 0; i < this.searchBoxes.length; i++) {
            this.searchBoxes[i].updateSuggestUrl(newUrl);
        }
    };
    SiteSearch360Core.prototype.createUiBuilder = function () {
        return new UiBuilder(this.context);
    };
    SiteSearch360Core.prototype.buildSuggestionUrl = function () {
        return this.context.urlBuilder.buildSuggestionUrl(this.context.reporter.shouldTrack(this.context));
    };
    SiteSearch360Core.prototype.addSearchField = function (layer, lastQuery) {
        var _this = this;
        if ((this.searchResultType === SearchResultType.Layover && !this.resultsConfiguration.showSearchBoxLayover)
            || (this.searchResultType === SearchResultType.Embed && !this.resultsConfiguration.showSearchBoxEmbed)
            || (this.searchResultType === SearchResultType.Fullscreen)) {
            return;
        }
        if (this.context.layer.getCustomSearchBox().length > 0) {
            return;
        }
        var ss360SearchWrapper = this.uiBuilder.buildSearchField(lastQuery);
        if (this.pluginConfiguration.voiceSearch.enabled === true) {
            new VoiceSearch(ss360SearchWrapper.find("#" + this.context.customSearchBoxId), function (query, settings) {
                _this.showResults(query, settings);
            }, this.pluginConfiguration.voiceSearch, true);
        }
        if (layer.find('.ss360-layer__content').length === 0) {
            layer.append(ss360SearchWrapper);
        }
        else {
            layer.prepend(ss360SearchWrapper);
        }
    };
    SiteSearch360Core.prototype.init = function () {
        var _this = this;
        this.successInit = true;
        // initialize search console for full screen mode
        this.context.getExistingSearchConsole().remove();
        var _a = this, uiBuilder = _a.uiBuilder, searchResultType = _a.searchResultType, pluginConfiguration = _a.pluginConfiguration, resultsConfiguration = _a.resultsConfiguration, searchBoxConfiguration = _a.searchBoxConfiguration;
        uiBuilder.renderLoader();
        if (searchResultType === SearchResultType.Fullscreen) {
            pluginConfiguration.searchBox.selector = "#" + this.context.customSearchBoxId;
            pluginConfiguration.searchBox.searchButton = "#" + this.context.customSearchButtonId;
            this.searchBoxSelector = "#" + this.context.customSearchBoxId;
            pluginConfiguration.results.embedConfig = {
                contentBlock: "#" + this.context.resultsId
            };
            uiBuilder.appendSearchConsole();
            // attach event handler
            sxQuery('#ss360CloseLayerButton').click(function () {
                _this.closeLayer();
            });
            var trigger_1 = sxQuery(resultsConfiguration.fullScreenConfig.trigger);
            trigger_1.click(function (event) {
                _this.showFullscreenLayer(event);
            });
            if (trigger_1.length > 0 && trigger_1.get()[0].nodeName === 'INPUT' && trigger_1.attr('type') !== 'button' && trigger_1.attr('type') !== 'submit') {
                trigger_1.on('paste', undefined, function (event) {
                    setTimeout(function () {
                        _this.showFullscreenLayer(event);
                        // @ts-ignore
                        sxQuery(searchBoxConfiguration.selector).val(trigger_1.val()).focus();
                    }, 0);
                });
                trigger_1.on('focus', undefined, function (event) {
                    _this.showFullscreenLayer(event);
                });
            }
        }
        var layoverTrigger = pluginConfiguration.results.layoverTrigger;
        if (searchResultType === SearchResultType.Layover && layoverTrigger !== undefined) {
            sxQuery(layoverTrigger).on('click', undefined, function (e) {
                e.preventDefault();
                e.stopPropagation();
                var layer = _this.context.getExistingLayer();
                layer.html('');
                UiHelper.prepareLayoverLayer(layer, function () {
                    _this.closeLayer();
                }, pluginConfiguration);
                _this.addSearchField(layer, '');
                _this.areResultsVisible = true;
            });
        }
        if (searchBoxConfiguration.searchButton !== undefined) {
            var searchButton_1 = sxQuery(searchBoxConfiguration.searchButton);
            if (searchButton_1.length > 0 && searchButton_1.attr('disabled') !== null) {
                sxQuery(searchBoxConfiguration.selector).on('input', function (e) {
                    if ((e.target.value || '').length > 0) {
                        searchButton_1.attr('disabled', null);
                    }
                    else {
                        searchButton_1.attr('disabled', 'disabled');
                    }
                });
            }
        }
        // handle autofocus
        if (searchBoxConfiguration.autofocus) {
            sxQuery(this.searchBoxSelector).on('focus.temp', undefined, function (e) {
                e.preventSuggests = true;
            });
            setTimeout(function () {
                sxQuery(_this.searchBoxSelector).focus();
                sxQuery(_this.searchBoxSelector).off('focus.temp');
            }, 200);
        }
        // initialize uniboxes
        var showVoiceSearchInSpecial = pluginConfiguration.specialMobileSuggest.enabled && pluginConfiguration.voiceSearch.enabled;
        var specialMobileVoiceSearchTrigger;
        if (showVoiceSearchInSpecial) {
            pluginConfiguration.specialMobileSuggest.shownCallback = function () {
                if (specialMobileVoiceSearchTrigger !== undefined && specialMobileVoiceSearchTrigger.reposition !== undefined) {
                    specialMobileVoiceSearchTrigger.reposition();
                }
            };
        }
        var uniboxes = sxQuery(this.searchBoxSelector).unibox(this.pluginConfiguration, false);
        if (uniboxes instanceof Array) {
            this.searchBoxes = uniboxes;
        }
        else {
            this.searchBoxes = this.searchBoxes.concat(uniboxes);
        }
        sxQuery(this.searchBoxSelector).each(function (singleSearchBox) {
            var $singleSearchBox = sxQuery(singleSearchBox);
            var $parent = sxQuery($singleSearchBox.parent());
            if ($parent.data('ss360') !== undefined && $parent.data('ss360').toString() === 'true') {
                var $searchButton_1 = searchBoxConfiguration.searchButton !== undefined ? $parent.find(searchBoxConfiguration.searchButton) : undefined;
                $singleSearchBox.addClass('ss360-custom-search__searchbox ss360-n-input');
                if ($searchButton_1 !== undefined) {
                    var hasLabel = searchBoxConfiguration.searchButtonLabel !== undefined;
                    var searchButtonIconColor = UiHelper.getSearchButtonIconColor(pluginConfiguration);
                    $searchButton_1.html(hasLabel ? searchBoxConfiguration.searchButtonLabel : Icons.getSvgIcon(Icons.MAGNIFIER, searchButtonIconColor));
                    $searchButton_1.addClass('ss360-custom-search__searchbutton ss360-custom-search__searchbutton--no-transition ss360-n-button ss360-ac-b ss360-ac-bg ss360-acd-b--hover ss360-acd-bg--hover');
                    if (!hasLabel) {
                        $searchButton_1.attr('aria-label', 'Search');
                    }
                    setTimeout(function () {
                        $searchButton_1.removeClass('ss360-custom-search__searchbutton--no-transition');
                    }, 100);
                }
                if ($singleSearchBox.attr('aria-label') === null && pluginConfiguration.accessibility.searchFieldLabel
                    && ($singleSearchBox.attr('id') === null || sxQuery("label[for=\"" + $singleSearchBox.attr('id') + "\"]").length === 0)) {
                    $singleSearchBox.attr('aria-label', pluginConfiguration.accessibility.searchFieldLabel);
                }
                $parent.addClass('ss360-flex ss360-n-section ss360-custom-search ss360-custom-search--no-margin');
            }
        });
        // lazy load unibox
        if (pluginConfiguration.suggestions.show) {
            var plainSearchBoxes_1 = sxQuery(this.searchBoxSelector);
            var loadUnibox = function () {
                plainSearchBoxes_1.unibox(pluginConfiguration, true, function (fullUniboxes) {
                    _this.searchBoxes = [];
                    if (fullUniboxes instanceof Array) {
                        _this.searchBoxes = fullUniboxes;
                    }
                    else {
                        _this.searchBoxes = _this.searchBoxes.concat(fullUniboxes);
                    }
                    _this.updateSuggestionUrl(_this.buildSuggestionUrl());
                    if (showVoiceSearchInSpecial) {
                        specialMobileVoiceSearchTrigger = new VoiceSearch(sxQuery("#" + _this.context.specialSearchBoxId), function (query, settings) {
                            _this.showResults(query, settings);
                            for (var i = 0; i < _this.searchBoxes.length; i++) {
                                var unibox = _this.searchBoxes[i];
                                if (unibox.hideSpecialSuggest !== undefined) {
                                    try {
                                        unibox.hideSpecialSuggest();
                                    }
                                    catch (ex) {
                                        Logger.error(ex);
                                    }
                                }
                            }
                        }, pluginConfiguration.voiceSearch);
                    }
                    StyleApplier.applyOnSuggestions(pluginConfiguration.style, _this.context.uniboxId);
                });
            };
            loadUnibox();
        }
        // attach search to buttons?
        if (searchBoxConfiguration.searchButton !== undefined) {
            sxQuery(searchBoxConfiguration.searchButton).removeAttribute('onclick').click(function (event) {
                _this.lastSearchButtonClickTime = new Date().getTime();
                var relevantSearchBox = _this.searchBoxes[0];
                if (_this.searchBoxes.length > 1 && _this.selectedSearchBox !== undefined) {
                    relevantSearchBox = (_this.searchBoxes.reduce(function (acc, sb) {
                        if (acc !== undefined) {
                            return acc;
                        }
                        var current = sb.getOriginalSearchBox !== undefined ? sb.getOriginalSearchBox() : sb;
                        if (current.get()[0] === _this.selectedSearchBox.get()[0]) {
                            return sb;
                        }
                        return undefined;
                    }, undefined) || _this.searchBoxes[0]);
                }
                Handlers.onSearchButtonClick(event, relevantSearchBox, pluginConfiguration.callbacks);
            });
        }
        // add/init the search layer
        this.context.layer.get(uiBuilder);
        var htmlBody = sxQuery('body');
        // htmlBody.append(layer);
        // click handlers to close search layer
        htmlBody.keydown(function (e) {
            Handlers.onBodyKeyDown(e, _this.areResultsVisible, _this.fullScreenOpen, searchResultType, _this.context);
        });
        htmlBody.mousedown(function (e) {
            Handlers.onBodyMouseDown(e, _this.areResultsVisible, _this.context);
        });
        htmlBody.mouseup(function () {
            Handlers.onBodyMouseUp(_this.context);
        });
        var htmlLayer = sxQuery("#" + this.context.layerId + ", #" + this.context.consoleId);
        htmlLayer.on('click, mousedown', function (event) {
            if (searchResultType === 'embed')
                return;
            event.stopPropagation();
        });
        htmlLayer.on('mouseup', function () {
            Handlers.onLayerMouseUp(_this.context);
        });
        // prepare config object
        this.searchResultConfig = resultsConfiguration.embedConfig;
        if (!(this.searchResultConfig instanceof Object)) {
            this.searchResultConfig = undefined;
        }
        // handle history
        sxQuery(window).on('popstate', function (e) {
            if (!Handlers.popstate(e, searchResultType, _this.embedDomElementCache, _this.context)) {
                // reset cache
                _this.embedDomElementCache = undefined;
            }
        });
        // are we at 404?
        if (pluginConfiguration.smart404 !== undefined && document.querySelector('title') !== null) {
            this.is404 = Smart404.checkAndHandle(this.context);
        }
        // apply styles
        var styleEventKey = this.context.generateId('ss360ApplyStyles');
        var areStylesBeingApplied = false;
        var reapply = function () {
            if (areStylesBeingApplied === false) {
                areStylesBeingApplied = true;
                setTimeout(function () {
                    StyleApplier.apply(searchResultType, pluginConfiguration.searchBox, pluginConfiguration.style);
                    areStylesBeingApplied = false;
                }, 200);
            }
        };
        reapply();
        sxQuery(window).off("resize." + styleEventKey + ", orientationchange." + styleEventKey).on("resize." + styleEventKey + ", orientationchange." + styleEventKey, reapply);
        if (pluginConfiguration.style.redrawTrigger !== undefined) {
            sxQuery(pluginConfiguration.style.redrawTrigger).off("click." + styleEventKey).on("click." + styleEventKey, reapply);
        }
        // voice search
        if (pluginConfiguration.voiceSearch.enabled === true) {
            sxQuery(this.searchBoxSelector).get().forEach(function (sbNode) { return new VoiceSearch(sxQuery(sbNode), function (query, settings) {
                _this.showResults(query, settings);
            }, pluginConfiguration.voiceSearch); });
        }
        // masonry grid
        Masonry.notifyConfigChange(pluginConfiguration.layout);
        // parenting form submitting
        if (searchBoxConfiguration.preventFormParentSubmit) {
            var $searchBoxes = sxQuery(searchBoxConfiguration.selector);
            $searchBoxes.each(function (searchBox) {
                searchBox = sxQuery(searchBox);
                var directParent = searchBox.parent();
                var form;
                if (directParent.get()[0].nodeName === 'FORM') {
                    form = directParent;
                }
                else if (directParent.parent().get()[0].nodeName === 'FORM') {
                    form = directParent.parent();
                }
                if (form !== undefined && (form.attr('action') || '').indexOf('.aspx') === -1) {
                    form.on('submit', function (e) { e.preventDefault(); e.stopPropagation(); });
                }
            });
        }
        // Product Finder Extension
        ProductFinder.init(this, pluginConfiguration.productFinder);
        // check whether everything is setup correctly
        var errors = Helper.getInitializationErrors(pluginConfiguration, this.is404);
        this.successInit = errors.length === 0;
        if (this.successInit) {
            Logger.info("SiteSearch360 " + this.VERSION + " initialized to " + this.searchBoxSelector);
        }
        else {
            if (pluginConfiguration.showErrors === true) {
                UiHelper.showError(errors.join('<br/>'));
                UiHelper.hideLoadingAnimation(this.context);
            }
            Logger.error("SiteSearch360 " + this.VERSION + " FAILED to initialize to " + this.searchBoxSelector + (errors !== undefined ? "\n\t" + errors.join('\n\t') : ''));
        }
        // notify plugin initialization
        if (this.callbacksConfiguration.init !== undefined) {
            try {
                setTimeout(function () {
                    _this.callbacksConfiguration.init();
                }, 0);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
    };
    SiteSearch360Core.prototype.buildQueryUrl = function (config) {
        var filters = config.filters || this.resultsConfiguration.filters;
        if ((filters === undefined || filters === null) && this.filtersConfiguration.preSelect.length > 0) {
            filters = [];
        }
        var setFilterNames = (filters || []).filter(function (entry) { return entry.name !== undefined && entry.name !== null; }).map(function (entry) { return entry.name.toLowerCase().trim(); });
        this.filtersConfiguration.preSelect.forEach(function (entry) {
            var lcName = (entry.name || '').toLocaleLowerCase().trim();
            if (setFilterNames.indexOf(lcName) === -1) {
                var filterEntry = { key: entry.key, name: entry.name };
                var optionsSet = false;
                if (entry.values !== undefined && entry.values.length > 0) {
                    optionsSet = true;
                    // @ts-ignore
                    filterEntry.values = entry.values.map(function (value) { return ({ name: value, value: value }); });
                }
                else if (entry.min !== undefined && entry.max !== undefined) {
                    // @ts-ignore
                    filterEntry.min = entry.min;
                    // @ts-ignore
                    filterEntry.max = entry.max;
                    optionsSet = true;
                }
                else if (entry.booleanValue !== undefined) {
                    // @ts-ignore
                    filterEntry.booleanValue = entry.booleanValue;
                    optionsSet = true;
                }
                if (optionsSet) {
                    filters.push(filterEntry);
                }
            }
        });
        return this.context.urlBuilder.buildQueryUrl({
            limit: config.limit,
            sort: config.sort,
            include: config.include,
            exclude: config.exclude,
            tracking: config.tracking,
            query: config.query,
            offset: config.offset,
            limitPerGroup: config.limitPerGroup,
            groupResults: config.group !== undefined ? config.group : this.resultsConfiguration.group,
            filters: filters,
            reporter: this.context.reporter,
            isPlain: config.isPlain,
            includeQueries: config.includeQueries,
            addQueries: config.addQueries,
            sortComplex: this.resultsConfiguration.sortComplex
        });
    };
    SiteSearch360Core.prototype.isInitialized = function () {
        return this.successInit !== undefined && this.successInit;
    };
    SiteSearch360Core.prototype.showFullscreenLayer = function (event) {
        if (event !== undefined) {
            event.stopPropagation();
        }
        this.fullScreenOpen = true;
        UiHelper.showFullscreenLayer(this.pluginConfiguration, this.context.getExistingSearchConsole());
    };
    SiteSearch360Core.prototype.hideSearchResults = function () {
        var _a = this, searchResultType = _a.searchResultType, pluginConfiguration = _a.pluginConfiguration;
        if (searchResultType === SearchResultType.Fullscreen || searchResultType === SearchResultType.Layover) {
            this.closeLayer();
        }
        else {
            this.context.getExistingLayer().fadeOut();
            if (this.embedDomElementCache !== undefined) {
                var $contentBlock = sxQuery(pluginConfiguration.results.embedConfig.contentBlock);
                $contentBlock.append(this.embedDomElementCache);
            }
            this.embedDomElementCache = undefined;
            NavigatorHelper.removeSearchQueryParam(pluginConfiguration.results, pluginConfiguration.results.searchQueryParamName);
        }
    };
    SiteSearch360Core.prototype.closeLayer = function () {
        var wereResultsVisible = this.areResultsVisible;
        this.areResultsVisible = false;
        sxQuery('body').removeClass('ss360-u-hide-y-overflow');
        if (wereResultsVisible) {
            sxQuery(window).off("beforeunload." + this.context.getInsightsEventKey());
            if (this.context.hasInsights()) {
                var allItems = this.context.getExistingLayer().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                this.context.getInsights().trackSerpLeave(this.context.getExistingLayer().find('.ss360-layer__content').get()[0], allItems[0], this.lastSearchTerm, allItems.length, this.context.getExistingLayer404().length > 0 ? SearchResultType.Smart404 : this.searchResultType);
            }
        }
        // fullscreen mode?
        if (this.searchResultType === SearchResultType.Fullscreen && this.fullScreenOpen) {
            this.fullScreenOpen = false;
            UiHelper.hideFullscreenLayer(this.pluginConfiguration, this.context.getExistingSearchConsole());
            NavigatorHelper.removeSearchQueryParam(this.context.pluginConfiguration.results);
        }
        else if (this.searchResultType === SearchResultType.Layover) {
            var $hideEls_1 = sxQuery("#" + this.context.layerId + ", #ss360Darken");
            $hideEls_1.removeClass('ss360-us ss360-fid');
            $hideEls_1.addClass('ss360-animated ss360-animated--fast ss360-fo');
            setTimeout(function () {
                $hideEls_1.removeClass('ss360-animated ss360-fo ss360-animated--fast');
                $hideEls_1.hide();
                sxQuery('#ss360Darken').remove();
            }, 150);
            NavigatorHelper.removeSearchQueryParam(this.context.pluginConfiguration.results);
            if (this.filtersConfiguration.enabled) {
                NavigatorHelper.removeSearchQueryParam(this.context.pluginConfiguration.results, 'ss360Filter');
            }
        }
        if (this.callbacksConfiguration.closeLayer !== undefined) {
            this.callbacksConfiguration.closeLayer.call(this);
        }
    };
    SiteSearch360Core.prototype.recordTyping = function () {
        this.context.reporter.initActionStartTime();
    };
    SiteSearch360Core.prototype.blur = function (event, selectedText, logAbandon) {
        var _this = this;
        var lbctGetter = function () { return _this.lastSearchButtonClickTime; };
        Handlers.searchBoxBlur(event, selectedText, logAbandon, this.autoBlurTime, lbctGetter, this.context);
    };
    SiteSearch360Core.prototype.focus = function (event, query) {
        this.selectedSearchBox = sxQuery(event.target);
        var customGroups = ContentGroupHelper.get(sxQuery(this.selectedSearchBox), true, false, this.pluginConfiguration.allowCookies, this.context);
        if (customGroups.include !== undefined || customGroups.exclude !== undefined) {
            this.wereSuggestGroupsModified = true;
            var includeCache = this.contentGroupsConfiguration.include;
            var excludeCache = this.contentGroupsConfiguration.exclude;
            if (customGroups.include !== undefined) {
                this.contentGroupsConfiguration.include = customGroups.include;
            }
            if (customGroups.exclude !== undefined) {
                this.contentGroupsConfiguration.exclude = customGroups.exclude;
            }
            this.updateSuggestionUrl(this.buildSuggestionUrl());
            this.contentGroupsConfiguration.include = includeCache;
            this.contentGroupsConfiguration.exclude = excludeCache;
        }
        else if (this.wereSuggestGroupsModified) {
            this.updateSuggestionUrl(this.buildSuggestionUrl());
            this.wereSuggestGroupsModified = false;
        }
        // add focus layer
        var focusLayer = this.searchBoxConfiguration.focusLayer;
        if (focusLayer !== undefined && focusLayer) {
            UiHelper.addDarkenInputLayer(this.selectedSearchBox);
        }
    };
    SiteSearch360Core.prototype.logQuery = function (query, action) {
        if (!this.context.pluginConfiguration.ecom && !this.context.pluginConfiguration.ecomDev) { // don't log this for ecom customers
            this.context.reporter.logQuery(query, action, this.context);
        }
    };
    SiteSearch360Core.prototype.followLink = function (selectedText, link, ctrlModifier, query) {
        if (link !== undefined && link.trim().length > 0) {
            this.logQuery(selectedText, TrackingAction.Select);
            if (ctrlModifier || this.pluginConfiguration.suggestions.linksOpenNewTab) {
                window.open(link, '_blank');
            }
            else {
                if (query !== undefined && query.length > 0) {
                    var queryDict = NavigatorHelper.buildQueryDict();
                    delete queryDict.ss360Filter;
                    if (this.resultsConfiguration.semanticMode && NavigatorHelper.hasHistoryAPI(this.resultsConfiguration)) {
                        NavigatorHelper.pushSemanticState(query, queryDict, this.pluginConfiguration);
                    }
                    else {
                        var qparam = this.resultsConfiguration.searchQueryParamName;
                        if (qparam !== '') {
                            queryDict[qparam] = query;
                            NavigatorHelper.pushState(queryDict, this.context.pluginConfiguration);
                        }
                    }
                }
                window.location.href = link;
            }
        }
        else {
            this.showResults(selectedText, {});
        }
    };
    SiteSearch360Core.prototype.prefetchResults = function (config) {
        var offset = config.offset;
        var contentGroup = config.contentGroup;
        var query = config.query;
        var callback = config.callback;
        var overrides = config.overrides;
        var filters = config.filters;
        var sort = config.sort !== undefined ? config.sort : this.resultsConfiguration.sorting;
        if (sort !== undefined && (sort instanceof Object)) {
            sort = JSON.stringify(sort);
        }
        // @ts-ignore
        if (sort === undefined || sort.trim().length === 0) {
            sort = '_relevance';
        }
        var safeKey = StringHelper.getSafeKey(contentGroup);
        var num = overrides !== undefined && overrides.num !== undefined ? overrides.num : this.resultsConfiguration.num;
        var pagingSize = overrides !== undefined && overrides.pageSize ? overrides.pageSize : this.resultsConfiguration.moreResultsPagingSize;
        var limit = Math.min(num - offset, pagingSize);
        var preloadedResultCount = config.preloaded !== undefined ? config.preloaded : offset -
            this.context.getExistingLayer().find(".ss360-group-" + safeKey + " ul li.ss360-suggests:not(.ss360-suggests--hidden)").length;
        var rest = pagingSize - preloadedResultCount;
        Results.prefetchAndRender({
            limit: limit,
            contentGroup: contentGroup,
            groupResults: config.group !== undefined ? config.group : this.resultsConfiguration.group,
            query: query,
            offset: offset,
            filters: filters !== undefined ? filters : this.resultsConfiguration.filters,
            // @ts-ignore
            sort: sort
        }, rest, this.uiBuilder, callback, this.context);
    };
    SiteSearch360Core.prototype.showResults = function (selectedText, config) {
        var _this = this;
        var rest = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            rest[_i - 2] = arguments[_i];
        }
        if (config === undefined || !(config instanceof Object)) { // fallback to the old structure
            config = {
                // @ts-ignore
                sort: config,
                filters: rest[0],
                shouldPushState: rest[1],
                searchButton: rest[2],
                callback: rest[3],
                submitSource: rest[4],
                sbRef: rest[5]
            };
        }
        var _a = this, pluginConfiguration = _a.pluginConfiguration, uiBuilder = _a.uiBuilder, resultsConfiguration = _a.resultsConfiguration, context = _a.context, callbacksConfiguration = _a.callbacksConfiguration, searchResultConfig = _a.searchResultConfig, contentGroupsConfiguration = _a.contentGroupsConfiguration, filtersConfiguration = _a.filtersConfiguration;
        uiBuilder.clearHasImagesCache();
        if (config.sbRef !== undefined) {
            this.selectedSearchBox = config.sbRef;
        }
        if (config.includeQueries !== undefined && config.includeQueries.length > 0 && (selectedText || '').trim().length === 0) {
            selectedText = config.includeQueries[0];
        }
        if (selectedText.trim() === (this.lastSearchTerm || '').trim() && pluginConfiguration.suggestions.triggersSearch && config.filters === undefined) {
            return; // don't retrigger the query
        }
        this.lastSearchTerm = selectedText;
        if (resultsConfiguration.embedConfig !== undefined && this.searchResultType === SearchResultType.Layover) {
            this.searchResultType = context.getSearchResultType(); // recheck if the block has been added
        }
        var preSearch = callbacksConfiguration.preSearch;
        var sort = config.sort !== undefined ? config.sort : resultsConfiguration.sorting;
        var keepGoing = (preSearch !== undefined && typeof preSearch === 'function' ? preSearch.call(this, selectedText, sort, this.selectedSearchBox, config.filters, config) : true)
            && selectedText.trim().length !== 0;
        if (!keepGoing) {
            return;
        }
        var queryModification = callbacksConfiguration.queryModification;
        if (queryModification !== undefined && typeof queryModification === 'function') {
            selectedText = queryModification(selectedText, config);
        }
        selectedText = selectedText.trim();
        var showSkeletonLoader = pluginConfiguration.style.loaderType === 'skeleton';
        if (!showSkeletonLoader) {
            UiHelper.showLoadingAnimation(this.context);
        }
        var hasSpecificResultPage = this.searchResultType === SearchResultType.Embed && searchResultConfig !== undefined && searchResultConfig.url !== undefined && searchResultConfig.url !== '';
        var shouldRedirect = hasSpecificResultPage && document.location.href.indexOf(searchResultConfig.url) === -1;
        var hasJustBeenRedirected = hasSpecificResultPage && this.selectedSearchBox === undefined && config.shouldPushState !== true;
        var cookieQuery = this.pluginConfiguration.allowCookies ? this.context.readCookie('ss360LastQuery') : selectedText;
        var shouldTrack = (this.context.hasInsights()) && (!hasJustBeenRedirected || this.is404 || cookieQuery !== selectedText);
        if (shouldTrack) {
            this.context.getInsights().trackSubmitSearch(selectedText, this.selectedSearchBox !== undefined ? sxQuery(this.selectedSearchBox).get()[0] : undefined, config.searchButton, config.submitSource);
        }
        // do we have to redirect?
        if (shouldRedirect) {
            NavigatorHelper.redirectToSearchResultPage(selectedText, searchResultConfig.url, context.pluginConfiguration, context);
            return;
        }
        if (this.pluginConfiguration.allowCookies) { // reset cookie
            this.context.createCookie('ss360LastQuery', '', -1);
        }
        if (context.getSearchResultType() === SearchResultType.Embed && this.embedDomElementCache === undefined) {
            this.embedDomElementCache = sxQuery(resultsConfiguration.embedConfig.contentBlock).children();
        }
        var pagingSize = resultsConfiguration.moreResultsPagingSize || 12;
        var maxResults = resultsConfiguration.num;
        var limit = resultsConfiguration.moreResultsButton ? 2 * pagingSize : maxResults;
        var hasMoreResultsButton = !!resultsConfiguration.moreResultsButton;
        var limitPerGroup = hasMoreResultsButton && resultsConfiguration.limitPerGroup
            && (contentGroupsConfiguration.include === undefined || contentGroupsConfiguration.include.length !== 1) && resultsConfiguration.group;
        limit = resultsConfiguration.limitPerGroup ? Math.min(limit, maxResults) : maxResults;
        var isPlainQuery = config.submitSource !== 'filter' && config.submitSource !== 'order';
        if (isPlainQuery && (config.submitSource === 'popstate' || config.submitSource === 'pageload')) {
            isPlainQuery = config.filters === undefined || config.filters.length === 0;
        }
        var include = contentGroupsConfiguration.include;
        var exclude = contentGroupsConfiguration.exclude;
        var isPageLoad = config.submitSource === 'pageload';
        var isPopState = config.submitSource === 'popstate';
        if (this.selectedSearchBox !== undefined || isPageLoad || isPopState) {
            var customGroups = ContentGroupHelper.get(sxQuery(this.selectedSearchBox), false, isPageLoad, pluginConfiguration.allowCookies, this.context);
            if (customGroups.include !== undefined) {
                include = customGroups.include;
            }
            if (customGroups.exclude !== undefined) {
                exclude = customGroups.exclude;
            }
        }
        var queryUrl = this.buildQueryUrl({
            limit: limit,
            sort: sort,
            include: include,
            exclude: exclude,
            tracking: context.reporter.shouldTrack(this.context),
            query: selectedText,
            offset: config.offset !== undefined ? config.offset : 0,
            limitPerGroup: limitPerGroup,
            filters: config.filters,
            isPlain: isPlainQuery,
            includeQueries: config.includeQueries,
            addQueries: config.addQueries
        });
        var escapedQuery = StringHelper.escapeHtml(selectedText);
        var layer = this.is404 && this.searchResultType !== SearchResultType.Layover ? this.context.getExistingLayer404() : this.context.getExistingLayer();
        var isFilterSubmit = config.submitSource === 'filter';
        var skeletonLayer = layer;
        var skeletonTimeout = -1;
        if (showSkeletonLoader) {
            // @ts-ignore
            skeletonTimeout = setTimeout(function () {
                _this.context.getExistingNoResultsLayer().remove();
                if (isFilterSubmit && filtersConfiguration.position === 'left') {
                    skeletonLayer = _this.context.getExistingFilteredResultsLayer();
                    skeletonLayer.html('');
                }
                else if (isFilterSubmit) {
                    skeletonLayer = layer.find('.ss360-layer__content');
                    skeletonLayer.find('.ss360-group').remove();
                }
                new Skelet({
                    searchResultType: _this.searchResultType,
                    uiBuilder: uiBuilder,
                    closeLayerHandler: function () {
                        _this.closeLayer();
                    },
                    searchFieldRenderer: function () {
                        _this.addSearchField(layer, _this.lastSearchTerm);
                    },
                    contentOnly: isFilterSubmit,
                    pluginConfig: context.pluginConfiguration,
                    layerId: context.layerId
                }).show(skeletonLayer, context.searchResultHeadingId, context);
            }, 250);
        }
        if (pluginConfiguration.allowCookies) {
            // @ts-ignore
            var storedQueries = this.context.readObject('unibox_search_history', []);
            var currentIndex = storedQueries.indexOf(selectedText);
            if (currentIndex !== -1) {
                storedQueries.splice(currentIndex, 1);
            }
            storedQueries.splice(0, 0, selectedText);
            var entrySizeOverflow = storedQueries.length - pluginConfiguration.suggestions.maxSearchHistoryEntries;
            if (entrySizeOverflow > 0) {
                storedQueries.splice(pluginConfiguration.suggestions.maxSearchHistoryEntries - 1, entrySizeOverflow);
            }
            this.context.storeObject('unibox_search_history', storedQueries);
        }
        var shouldTryReadQuery = config.submitSource === 'popstate' || config.submitSource === 'pageload';
        var resultsFetcher = shouldTryReadQuery ? Results.getOrFetch : Results.fetch;
        resultsFetcher(queryUrl, this.context, function (data) {
            _this.renderResults({
                data: data, skeletonTimeout: skeletonTimeout, layer: layer, selectedText: selectedText, escapedQuery: escapedQuery, limitPerGroup: limitPerGroup, config: config, isPlainQuery: isPlainQuery, skeletonLayer: skeletonLayer, sort: sort
            });
        }, function (status, statusText) {
            UiHelper.hideLoadingAnimation(_this.context);
            clearTimeout(skeletonTimeout);
            if (pluginConfiguration.showErrors === true && callbacksConfiguration.searchError === undefined) {
                UiHelper.showError("There is no siteId \"" + _this.siteId + "\", so no results can be retrieved. Please update your ss360Config object.");
            }
            else if (callbacksConfiguration.searchError !== undefined) {
                callbacksConfiguration.searchError();
            }
            ErrorScreen.show(status, statusText, context, function (selectedLayer) {
                _this.addSearchField(selectedLayer, selectedText);
            }, _this.showResults.bind(_this, selectedText, config), uiBuilder);
        }, pluginConfiguration.siteId);
    };
    SiteSearch360Core.prototype.renderResults = function (_a) {
        var _this = this;
        var data = _a.data, skeletonTimeout = _a.skeletonTimeout, layer = _a.layer, selectedText = _a.selectedText, escapedQuery = _a.escapedQuery, _b = _a.limitPerGroup, limitPerGroup = _b === void 0 ? true : _b, _c = _a.config, config = _c === void 0 ? {} : _c, _d = _a.isPlainQuery, isPlainQuery = _d === void 0 ? true : _d, skeletonLayer = _a.skeletonLayer, sort = _a.sort;
        if (layer === undefined) {
            layer = this.context.layer.get(this.uiBuilder);
        }
        if (escapedQuery === undefined) {
            escapedQuery = StringHelper.escapeHtml(selectedText);
        }
        if (skeletonLayer === undefined) {
            skeletonLayer = layer;
        }
        clearTimeout(skeletonTimeout);
        if (this.is404) {
            layer.find('.ss360-layer__content').remove();
        }
        var _e = this, resultsConfiguration = _e.resultsConfiguration, callbacksConfiguration = _e.callbacksConfiguration, pluginConfiguration = _e.pluginConfiguration, uiBuilder = _e.uiBuilder, filtersConfiguration = _e.filtersConfiguration;
        var filterIdToName = data.filterNameMapping;
        if (callbacksConfiguration.searchResult !== undefined) { // user builds its own search result?
            try {
                callbacksConfiguration.searchResult.call(this, data.plain);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        else { // default layout
            // redirect?
            var red = data.redirect;
            if (red !== undefined && red.length > 0) {
                NavigatorHelper.handleRedirect(red, selectedText, escapedQuery, this.context);
                return;
            }
            this.context.layer.empty();
            var layerContent = this.context.layer.getLayerContent();
            if (layerContent.length === 0) {
                layerContent = UiHelper.createLayerContent(this.searchResultType, pluginConfiguration.layout, this.context.searchResultHeadingId);
            }
            else {
                this.context.layer.resetLayerContent();
            }
            this.addSearchField(layer, this.lastSearchTerm);
            // are there parts of the content we show only on certain devices?
            UiHelper.updateLayerByHiddenParts(this.hiddenParts, layerContent);
            // build navigation
            this.navigation = new Navigation(data, this.context);
            this.navigation.build(layer, layerContent);
            // append layer content
            var contentWrapper = (this.navigation.getNav() === null || !this.navigation.shouldForceFlex()) ? layer : this.context.getExistingFlexWrapper();
            contentWrapper.append(layerContent);
            var headlineNode = this.context.layer.getHeadlineNode();
            var plan = data.plan;
            var BRANDED_PLANS = [Plan.Free, Plan.Columbo, Plan.Columbo2, Plan.DudaFree, Plan.FirespringFree, Plan.FirespringColumbo, Plan.FilrespringHolmes];
            var shouldRenderLogo = BRANDED_PLANS.indexOf(plan) !== -1 || (plan === Plan.Custom && data.attribution === true);
            var forceBranding = pluginConfiguration.forceBranding;
            if (!shouldRenderLogo && forceBranding !== false && forceBranding !== undefined) {
                // @ts-ignore
                shouldRenderLogo = forceBranding === true || BRANDED_PLANS.indexOf(forceBranding.toUpperCase()) !== -1;
            }
            var totalResults = Helper.getTotalCount(data, limitPerGroup, pluginConfiguration);
            var caption = resultsConfiguration.caption;
            if (caption !== undefined) {
                var formattedTotal = 'Intl' in window ? new Intl.NumberFormat().format(totalResults) : totalResults.toString();
                var headingLabel = "" + caption.replace('#QUERY#', escapedQuery).replace('#COUNT#', formattedTotal);
                if (shouldRenderLogo) {
                    layerContent.addClass('ss360-layer__content--branded');
                }
                if (headlineNode.length > 0) {
                    headlineNode.html("<a class=\"ss360-layer__heading-anchor\" tabindex=\"-1\" href=\"#\">" + headingLabel + "</a>");
                }
                else {
                    headlineNode = this.uiBuilder.buildHeadlineNode(headingLabel, shouldRenderLogo);
                    layerContent.append(headlineNode);
                }
            }
            else if (headlineNode.length > 0) {
                headlineNode.remove();
                headlineNode = null;
            }
            if (data.queryCorrection !== undefined && data.queryCorrection != null) {
                layerContent.append(uiBuilder.buildQueryCorrectionNode(data.queryCorrection, this.context.queryCorrectionId, this.pluginConfiguration));
            }
            else if (data.queryCorrectionRewrite !== undefined && data.queryCorrectionRewrite !== null) {
                layerContent.append(uiBuilder.buildQueryCorrectionRewriteNode(data.queryCorrectionRewrite));
            }
            var totalResultsShown = uiBuilder.renderSearchResults(data, this.navigation, layerContent, escapedQuery, totalResults, config.submitSource === 'popstate');
            if (totalResultsShown === 1 && resultsConfiguration.redirectOnSingle && isPlainQuery && NavigatorHelper.getFirstLink(data) !== 'https://www.sitesearch360.com') {
                NavigatorHelper.redirectToFirst(data);
                return;
            }
            if (this.navigation.getPosition() === 'top') { // XXX CSS dependent (51px, height of navigation)
                layerContent.css('max-height', 'calc(100% - 80px)');
            }
            if (totalResultsShown === 0) {
                uiBuilder.renderNoResultsText(layerContent, escapedQuery);
                this.context.getExistingLayer().find('.ss360-filter__mobile-toggle, .ss360-filter__backdrop').remove();
                this.context.getExistingFilterLayer().remove();
                this.context.getExistingFilteredResultsLayer().remove();
                this.context.getExistingSorting();
            }
            // sitesearch360 watermark in search result pages
            if (shouldRenderLogo) {
                uiBuilder.renderWatermark(layerContent);
            }
            if (filterIdToName === undefined
                && resultsConfiguration.nameParsing && data.filterOptions !== undefined && data.filterOptions.length > 0) {
                // create map to prevent duplicate url entries
                filterIdToName = (data.filterOptions || []).reduce(function (acc, entry) {
                    acc[entry.key] = entry.name;
                    return acc;
                }, {});
            }
            if (filterIdToName === undefined) {
                filterIdToName = {};
            }
            // sorting options
            if (data.sortingOptions !== undefined && data.sortingOptions.length > 0 && totalResults > 0 && !this.is404) {
                Sorting.render(layerContent, data.sortingOptions, data.activeSortingOption !== undefined ? data.activeSortingOption : data.sorting, function (query, c) {
                    if (c.filters !== undefined && resultsConfiguration.nameParsing) {
                        c.filters = c.filters.map(function (f) {
                            var key = Object.keys(f)[0];
                            if (key !== undefined && filterIdToName[key] !== undefined) {
                                var obj = {};
                                obj[filterIdToName[key]] = f[key];
                                return obj;
                            }
                            return f;
                        });
                    }
                    _this.showResults(query, c);
                }, selectedText, data.activeFilterOptions, pluginConfiguration, this.context.sortingId);
            }
            // polyfill for object-fit (IE)
            Polyfill.objectFit(this.context.getExistingLayer().find('.ss360-suggests__image-wrap'));
        }
        UiHelper.hideLoadingAnimation(this.context);
        skeletonLayer.attr('aria-busy', 'false');
        this.context.getExistingLayer().find('.ss360-skeleton__filter').remove();
        layer.removeClass('ss360--skeleton');
        layer.css('display', null);
        // lose focus on input to hide virtual keyboards
        if (!pluginConfiguration.suggestions.triggersSearch) {
            try {
                this.autoBlurTime = new Date().getTime();
                sxQuery(this.searchBoxConfiguration.selector).blur();
            }
            catch (e) {
                Logger.info(e);
            }
        }
        layer.removeClass('ss360-animated');
        layer.removeClass('ss360-layer--overlay');
        var renderFilter = function () {
            // render filter
            if (filtersConfiguration.enabled && (Object.keys(data.suggests).length > 0 || (data.activeFilterOptions && data.activeFilterOptions.length > 0))
                && data.filterOptions && data.filterOptions.length > 0) {
                filtersConfiguration.headingLevel = uiBuilder.captionHeadingLevel + 1;
                // @ts-ignore
                import(/* webpackChunkName: "filters" */ './components/Filter').then(function (_a) {
                    var Filter = _a.default;
                    var activeFilterOptions = data.activeFilterOptions;
                    if (activeFilterOptions !== undefined && activeFilterOptions.length > 0) {
                        activeFilterOptions = activeFilterOptions.map(function (option) {
                            if (option.key === undefined || (option.values === undefined && option.min === undefined && option.booleanValue === undefined))
                                return undefined;
                            var obj = {};
                            if (option.booleanValue !== undefined) {
                                obj[option.key] = true;
                            }
                            else if (option.values !== undefined) {
                                obj[option.key] = option.values.map(function (val) {
                                    if (val.value !== undefined)
                                        return val.value;
                                    if (val.key !== undefined)
                                        return val.key;
                                    return val.name;
                                });
                            }
                            else {
                                obj[option.key] = {
                                    min: option.min,
                                    max: option.max
                                };
                            }
                            return obj;
                        }).filter(function (option) { return option !== undefined; });
                    }
                    new Filter(_this.context, data.filterOptions, activeFilterOptions, selectedText.trim(), function (activeFilterValues) {
                        var filterArr = Object.keys(activeFilterValues).reduce(function (acc, key) {
                            var obj = {};
                            var name = (filterIdToName || {})[key];
                            var values = activeFilterValues[key];
                            obj.name = name;
                            obj.key = key;
                            if (values === true) {
                                obj.booleanValue = true;
                            }
                            else if (values instanceof Array) {
                                obj.values = values.map(function (val) { return ({ name: val, value: val }); });
                            }
                            else {
                                obj.min = values.min;
                                obj.max = values.max;
                            }
                            acc.push(obj);
                            return acc;
                        }, []);
                        _this.showResults(selectedText, {
                            sort: sort,
                            filters: filterArr,
                            shouldPushState: true,
                            submitSource: 'filter'
                        });
                    }, filterIdToName || {}, data.totalResults, data.filterMapping);
                });
            }
            else if (filtersConfiguration.enabled) {
                _this.context.getExistingFilteredResultsLayer().remove();
                _this.context.getExistingFilterLayer().remove();
            }
        };
        // layover vs embed
        if (this.searchResultType === SearchResultType.Layover) {
            // // layover
            UiHelper.prepareLayoverLayer(layer, function () {
                _this.closeLayer();
            }, pluginConfiguration);
            renderFilter();
        }
        else {
            // // embed
            renderFilter();
            if (!layer.isVisible()) {
                layer.fadeIn();
            }
            if (layer.parents(this.searchResultConfig.contentBlock, true).length === 0) {
                sxQuery(this.searchResultConfig.contentBlock).html(layer);
            }
        }
        // focus layer header
        if (!this.is404 && !pluginConfiguration.suggestions.triggersSearch && resultsConfiguration.focusResultBlock) {
            setTimeout(function () {
                _this.context.getExistingLayer().find('.ss360-layer__heading-anchor').focus();
            }, 5);
        }
        this.is404 = false;
        LazyLoad(this.context);
        // change the URL so that we can deep link or go back to this result page
        var queryDict = NavigatorHelper.buildQueryDict();
        if (resultsConfiguration.nameParsing && data.filterOptions !== undefined && data.filterOptions.length > 0) {
            // remove all filters
            Object.keys(filterIdToName || {}).forEach(function (key) {
                var filterName = filterIdToName[key];
                delete queryDict[NavigatorHelper.escapeQueryParam(filterName || '')];
            });
        }
        if (config.filters !== undefined && config.filters.length > 0) {
            // round up to two decimals
            config.filters.forEach(function (filter) {
                var keys = Object.keys(filter);
                var obj = filter;
                if (keys.length > 0 && obj !== undefined && obj.min !== undefined && obj.max !== undefined && !Helper.isNaN(obj.min) && !Helper.isNaN(obj.max)) {
                    var min = obj.min;
                    var max = obj.max;
                    if (min * 100 !== Math.round(min * 100)) {
                        obj.min = parseFloat(min.toFixed(2));
                    }
                    if (max * 100 !== Math.round(max * 100)) {
                        obj.max = parseFloat(max.toFixed(2));
                    }
                }
            });
            // add filters to query
            if (resultsConfiguration.nameParsing) {
                // add filters to url
                var filters = config.filters.map(function (f) {
                    var obj = {};
                    if (f.values !== undefined) {
                        obj[f.name] = f.values;
                    }
                    else if (f.booleanValue !== undefined) {
                        obj[f.name] = f.booleanValue;
                    }
                    else {
                        obj[f.name] = {
                            min: f.min,
                            max: f.max
                        };
                    }
                    return obj;
                });
                var toFixedNumber_1 = function (val) {
                    // @ts-ignore
                    if (parseFloat(val) !== Math.round(parseFloat(val))) {
                        // @ts-ignore
                        return parseFloat(val).toFixed(2);
                    }
                    return val;
                };
                // FIXME: filters should not be array of any
                filters.forEach(function (filter) {
                    var key = Object.keys(filter)[0];
                    if (key !== undefined) {
                        var originalVal = filter[key];
                        var val = void 0;
                        if (originalVal.min !== undefined && originalVal.max !== undefined) {
                            var unit = '';
                            var settings_1 = filtersConfiguration.settings;
                            if (settings_1 !== undefined && Object.keys(settings_1).length > 0) {
                                var filterName_1 = key.toLowerCase();
                                var filterId = ((data.filterOptions || []).filter(function (entry) { return (entry.name || '').toLowerCase() === filterName_1; })[0] || {}).key;
                                var keys = Object.keys(settings_1).filter(function (k) { return settings_1[k] !== undefined; });
                                for (var i = 0; i < keys.length; i++) {
                                    var settingKey = keys[i];
                                    if (settingKey === filterId || settingKey.toLowerCase() === filterName_1) {
                                        unit = settings_1[settingKey].unit !== undefined ? "~" + settings_1[settingKey].unit : '';
                                        break;
                                    }
                                }
                            }
                            val = toFixedNumber_1(originalVal.min) + "-" + toFixedNumber_1(originalVal.max) + NavigatorHelper.escapeQueryParam(unit);
                        }
                        else if (originalVal === true) {
                            val = '~true';
                        }
                        else {
                            val = originalVal.map(function (filterVal) { return NavigatorHelper.escapeQueryParam(filterVal.name); }).join(',');
                        }
                        queryDict[NavigatorHelper.escapeQueryParam(key)] = val;
                    }
                });
            }
            else {
                queryDict.ss360Filter = JSON.stringify(config.filters);
            }
        }
        else {
            delete queryDict.ss360Filter;
        }
        if (resultsConfiguration.nameParsing) {
            var sortingParamName = NavigatorHelper.escapeQueryParam(resultsConfiguration.sortingParamName);
            if (config.sort !== undefined && config.sort !== '_relevance') {
                queryDict[sortingParamName] = NavigatorHelper.escapeQueryParam(config.sort);
            }
            else {
                delete queryDict[sortingParamName];
            }
        }
        if (config.shouldPushState !== false && NavigatorHelper.hasHistoryAPI(resultsConfiguration)) {
            if (resultsConfiguration.semanticMode) {
                NavigatorHelper.pushSemanticState(selectedText, queryDict, pluginConfiguration);
            }
            else {
                var qparam = resultsConfiguration.searchQueryParamName;
                if (qparam !== '') {
                    queryDict[qparam] = selectedText;
                    var stateUrl = NavigatorHelper.pushState(queryDict, pluginConfiguration);
                    this.context.storeObject('ss360_last_query_result', __assign({ siteId: this.siteId, locationHref: stateUrl, timestampMs: new Date().getTime() }, data.plain));
                }
            }
        }
        var clearQueryEventName = "keydown." + this.context.generateId('clearLastResult');
        sxQuery(window).off(clearQueryEventName);
        sxQuery(window).on(clearQueryEventName, function (e) {
            if (e.ctrlKey && (e.key === 'F5' || e.code === 'F5')) {
                _this.context.storeObject('ss360_last_query_result', null);
                sxQuery(window).off(clearQueryEventName);
            }
        });
        // back click from search result
        if (Results.wasBackPressed(selectedText, pluginConfiguration.allowCookies, this.context)) {
            Results.handleBackPress(this.navigation, selectedText, this.searchResultType, this.context);
        }
        // we might need to clean the container one more time
        var embedConfig = resultsConfiguration.embedConfig;
        if (this.searchResultType === SearchResultType.Embed && embedConfig !== undefined && embedConfig.contentBlock !== undefined) {
            var $contentBlock = sxQuery(embedConfig.contentBlock);
            if ($contentBlock.find("#" + this.context.layerId).length === 1 && $contentBlock.children().length > 1) {
                $contentBlock.children().each(function (child) {
                    var $child = sxQuery(child);
                    if ($child.attr('id') !== 'ss360-layer') {
                        $child.remove();
                    }
                });
            }
        }
        if (callbacksConfiguration.postSearch !== undefined) {
            try {
                callbacksConfiguration.postSearch.call(this, data.plain);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        if (config.callback !== undefined) {
            try {
                config.callback.call(this, data);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        if (pluginConfiguration.layout.mobile.type === 'masonry' || pluginConfiguration.layout.desktop.type === 'masonry') {
            Masonry.init(this.context);
        }
        if (this.context.layer.getCustomSearchBox().length > 0 && (this.selectedSearchBox === undefined || this.context.layer.getCustomSearchBox().get()[0] !== this.selectedSearchBox.get()[0])) {
            this.context.layer.getCustomSearchBox().val(selectedText);
        }
        // log query for analytics
        this.context.reporter.reportSerp(selectedText, this.context);
        this.areResultsVisible = true;
        if (this.context.hasInsights()) {
            var allItems = this.context.getExistingLayer().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
            var resultType_1 = 'layover';
            if (resultsConfiguration.embedConfig !== undefined) {
                resultType_1 = 'embed';
            }
            else if (resultsConfiguration.fullScreenConfig !== undefined) {
                resultType_1 = 'fullscreen';
            }
            if (this.context.getExistingLayer404().length > 0) {
                resultType_1 = 'smart404';
            }
            sxQuery(window).off("beforeunload." + this.context.getInsightsEventKey());
            sxQuery(window).on("beforeunload." + this.context.getInsightsEventKey(), function () {
                if (_this.context.hasInsights()) {
                    var allSuggestItems = _this.context.getExistingLayer().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                    _this.context.getInsights().trackSerpLeave(_this.context.getExistingLayer().find('.ss360-layer__content').get()[0], allSuggestItems[0], selectedText, allSuggestItems.length, resultType_1);
                }
            });
            var filterData_1;
            if (filtersConfiguration.enabled && data.filterOptions) {
                filterData_1 = [];
                data.filterOptions.forEach(function (filterGroup) {
                    // @ts-ignore
                    filterData_1.push(filterGroup.key + "<#>" + filterGroup.name);
                });
                filterData_1 = JSON.stringify(filterData_1);
            }
            this.context.getInsights().trackSerpShow(this.context.getExistingLayer().find('.ss360-layer__content').get()[0], allItems[0], selectedText, allItems.length, resultType_1, filterData_1);
        }
    };
    SiteSearch360Core.prototype.getContext = function () {
        return this.context;
    };
    SiteSearch360Core.prototype.getSearchResultType = function () {
        return this.searchResultType;
    };
    SiteSearch360Core.prototype.showLoading = function () {
        UiHelper.showLoadingAnimation(this.context);
    };
    SiteSearch360Core.prototype.hideLoading = function () {
        UiHelper.hideLoadingAnimation(this.context);
    };
    SiteSearch360Core.prototype.setBaseUrl = function (url) {
        this.context.urlBuilder.setSearchBase(url);
    };
    SiteSearch360Core.prototype.setSuggestUrl = function (url) {
        this.context.urlBuilder.setSuggestBase(url);
        this.updateSuggestionUrl(this.buildSuggestionUrl());
    };
    SiteSearch360Core.prototype.getSS360Settings = function () {
        return this.pluginConfiguration;
    };
    SiteSearch360Core.prototype.getSettings = function () {
        return this.pluginConfiguration;
    };
    SiteSearch360Core.prototype.getUiBuilder = function () {
        return this.uiBuilder;
    };
    SiteSearch360Core.prototype.openTab = function (tabName) {
        if (this.navigation !== undefined) {
            this.navigation.focusTab(StringHelper.getSafeKey(tabName));
        }
    };
    return SiteSearch360Core;
}());
export default SiteSearch360Core;
