'use strict';
var entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
};
var inverseEntityMap = Object.keys(entityMap).reduce(function (acc, key) { acc[entityMap[key]] = key; return acc; }, {});
var StringHelper = {
    escapeHtml: function (string) {
        return String(string).replace(/[&<>"'/]/g, function (s) { return entityMap[s]; });
    },
    unescapeHtml: function (string) {
        string = String(string);
        Object.keys(inverseEntityMap).forEach(function (entity) {
            string = string.replace(new RegExp(entity, 'g'), inverseEntityMap[entity]);
        });
        return string;
    },
    getSafeKey: function (key) {
        return key.replace(/[ "§$%&/(){}+*,.;|:]/g, '_').replace(/#/g, '__').replace(/'/g, '---').toLowerCase();
    }
};
export default StringHelper;
