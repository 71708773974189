'use strict';
import Logger from './Logger';
var getByKey = function (sb, key) {
    // @ts-ignore
    var data = sb.data(key);
    if (data === undefined)
        return undefined;
    if (data.length === 0)
        return [];
    try {
        if (data.indexOf('"') === -1) {
            data = data.replace(/'/g, '"');
        }
        return JSON.parse(data);
    }
    catch (ex) {
        Logger.warn(ex);
    }
    return undefined;
};
var ContentGroupHelper = {
    get: function (sb, isSuggest, isPageLoad, allowCookies, context) {
        if (isSuggest === void 0) { isSuggest = false; }
        if (isPageLoad === void 0) { isPageLoad = false; }
        if (allowCookies === void 0) { allowCookies = false; }
        if (sb === undefined || sb.length === 0) {
            if (isPageLoad && !isSuggest) {
                var cookieVal = context.readCookie('ss360CGResults');
                if (cookieVal !== null) {
                    try {
                        return JSON.parse(cookieVal);
                    }
                    catch (ex) {
                        // ccl
                    }
                }
            }
            return {};
        }
        var includeKey = 'ss360Include';
        var excludeKey = 'ss360Exclude';
        var result = {
            include: getByKey(sb, includeKey),
            exclude: getByKey(sb, excludeKey)
        };
        if (isSuggest) {
            var suggestInclude = getByKey(sb, includeKey + "Suggest");
            var suggestExclude = getByKey(sb, excludeKey + "Suggest");
            if (suggestInclude !== undefined) {
                result.include = suggestInclude;
            }
            if (suggestExclude !== undefined) {
                result.exclude = suggestExclude;
            }
        }
        if (allowCookies && !isSuggest) {
            context.createCookie('ss360CGResults', JSON.stringify(result), 1 / 24);
        }
        return result;
    }
};
export default ContentGroupHelper;
