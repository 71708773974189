'use strict';
import sxQuery from '../../sxQuery/sxQuery';
var Sorting = {
    render: function (layerContent, sortingOptions, appliedSorting, callback, selectedText, activeFilterOptions, pluginConfig, sortingId) {
        var label = pluginConfig.results.sortingLabel;
        var sorting = sxQuery("<section id=\"" + sortingId + "\" role=\"search\" aria-label=\"" + (label
            || 'Sort Search Results') + "\" class=\"ss360-n-section ss360-flex ss360-flex--align-end ss360-flex--justify-end ss360-sorting\"></section>");
        if (label !== undefined) {
            sorting.append("<label for=\"" + sortingId + "_selection\" class=\"ss360-sorting__label\">" + label + "</label>");
        }
        sorting.append("<select id=\"" + sortingId + "_selection\" class=\"ss360-sorting__select\">");
        var relevanceLabel = pluginConfig.results.orderByRelevanceText || 'Relevance';
        sorting.find('select').append(sxQuery("<option value=\"_relevance\">" + relevanceLabel + "</option>"));
        for (var i = 0; i < sortingOptions.length; i++) {
            var sortingOption = sortingOptions[i];
            var option = void 0;
            if ((typeof sortingOption) === 'string') {
                option = sxQuery("<option value=\"" + sortingOption + "\">" + sortingOption + "</option>");
            }
            else {
                // @ts-ignore
                var so = sortingOption;
                var key = so.key;
                if (so.sort !== undefined) {
                    key = key + "_" + so.sort;
                }
                option = sxQuery("<option value=\"" + key + "\">" + so.name + "</option>");
            }
            sorting.find('select').append(option);
        }
        var $heading = layerContent.find('.ss360-layer__heading');
        var appended = false;
        if ($heading.length > 0) {
            var headingNode = $heading.get()[0];
            if (headingNode) {
                var parent = headingNode.parentNode;
                if (parent) {
                    parent.insertBefore(sorting.get()[0], parent.children[1]);
                    appended = true;
                }
            }
        }
        if (!appended) {
            layerContent.append(sorting);
        }
        // @ts-ignore
        var sortingOptionsMap = sortingOptions.reduce(function (acc, option) {
            if ((typeof option) === 'string') {
                // @ts-ignore
                var so = option;
                acc[so] = so;
            }
            else {
                // @ts-ignore
                var so = option;
                var key = so.key;
                if (so.sort !== undefined) {
                    key = key + "_" + so.sort;
                }
                acc[key] = option;
            }
            return acc;
        }, {});
        var val = appliedSorting || '_relevance';
        if ((typeof val) !== 'string') {
            // @ts-ignore
            var so = val || {};
            var direction = so.sort;
            if (direction === undefined) {
                direction = so.direction;
            }
            val = so.key;
            if (val && direction !== undefined) {
                val = val + "_" + direction;
            }
        }
        if (!val) {
            val = '_relevance';
        }
        var $select = sorting.find('select');
        // @ts-ignore
        $select.val(val);
        $select.on('change', function () {
            // @ts-ignore
            var sort = $select.val();
            if (sort !== '_relevance') {
                // @ts-ignore
                sort = sortingOptionsMap[sort];
            }
            if ((typeof sort) !== 'string') {
                // @ts-ignore
                var so = sort;
                if (pluginConfig.results.nameParsing && so.name !== undefined) {
                    sort = so.name;
                }
                else {
                    sort = JSON.stringify(sort);
                }
            }
            callback(selectedText, {
                // @ts-ignore
                sort: sort,
                shouldPushState: true,
                searchButton: $select.get()[0],
                submitSource: 'order',
                filters: activeFilterOptions
            });
        });
    }
};
export default Sorting;
