'use strict';
var ApiConfig = /** @class */ (function () {
    function ApiConfig(base, ecom, siteId, isFullUrl, suggestBaseUrl) {
        if (ecom === void 0) { ecom = false; }
        if (isFullUrl === void 0) { isFullUrl = false; }
        if (!ecom) {
            this.baseUrl = isFullUrl ? base : base + "/sites";
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : base + "/sites/suggest";
        }
        else {
            this.baseUrl = isFullUrl ? base : base + "/search?projectId=" + siteId;
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : base + "/search/suggestions?projectId=" + siteId;
        }
    }
    return ApiConfig;
}());
export default ApiConfig;
