'use strict';
import UiHelper from '../ui/UiHelper';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import StringHelper from '../../global/StringHelper';
import NavigationPosition from '../enums/NavigationPosition';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import LoaderType from '../enums/LoaderType';
import LayoutType from '../enums/LayoutType';
import DataPointDirection from '../enums/DataPointDirection';
var ConfigurationHelper = {
    getPart: function (object, key) {
        var parts = key.split('.');
        var target = object;
        for (var i = 0; i < parts.length; i++) {
            target = target[parts[i]];
            if (!target) {
                break;
            }
        }
        return target;
    },
    /**
     * Update the ss360Settings object
     *
     * @param {object} ss360Settings the object to be updated
     * @param {string} key the key of the target property
     * @param {*} value the value to be set
     */
    updateConfig: function (ss360Settings, key, value) {
        try {
            var parts = key.split('.');
            var propertyKey = parts.splice(parts.length - 1, 1)[0];
            var target = parts.length === 0 ? ss360Settings : ConfigurationHelper.getPart(ss360Settings, parts.join('.'));
            if (target !== undefined && target !== null) {
                target[propertyKey] = value;
            }
        }
        catch (err) {
            Logger.warn(err);
        }
    },
    assert: function (config, settings) {
        config = config || {};
        if (config.smart404 === undefined) {
            settings.smart404 = undefined;
        }
        settings.results.moreResultsPagingSize = Math.min(24, settings.results.moreResultsPagingSize);
        if (settings.results.sxMoreResultsPagingSize !== undefined) {
            settings.results.moreResultsPagingSize = settings.results.sxMoreResultsPagingSize;
        }
        // reverse for unibox
        settings.emptyQuerySuggests = settings.suggestions.emptyQuerySuggestions;
        if (settings.suggestions.show === false || settings.suggestions.showOnMobile === false) {
            settings.specialMobileSuggest.enabled = false;
        }
        // don't allow left navigation on mobile devices
        if (settings.layout.navigation.position === NavigationPosition.Left && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991)) {
            settings.layout.navigation.position = NavigationPosition.Top;
        }
        // auto skeleton loader only if explicitly set or defaultCss turned on
        if (settings.style.loaderType === LoaderType.Skeleton && !settings.style.defaultCss && (config.style || {}).loaderType !== LoaderType.Skeleton) {
            settings.style.loaderType = LoaderType.Circle;
        }
        if ((config.dataPoints === undefined || config.dataPoints.direction === undefined) && (settings.layout.mobile.type === LayoutType.Grid || settings.layout.desktop.type === LayoutType.Grid)) {
            settings.dataPoints.direction = DataPointDirection.Column;
        }
        if (config.results !== undefined && config.results.collapseDataPoints !== undefined && (config.dataPoints === undefined || config.dataPoints.collapseBy === undefined)) {
            settings.dataPoints.collapseBy = config.results.collapseDataPoints;
        }
        if (config.accessibility !== undefined && config.accessibility.resultTopHeadingLevel !== undefined) {
            config.accessibility.resultTopHeadingLevel = Math.max(1, parseInt(config.accessibility.resultTopHeadingLevel, 10) || 2);
        }
    },
    extendUniboxOptions: function (settings, options, core, configInstance) {
        var _a, _b;
        options.callbacks = {
            // @ts-ignore
            enterResult: settings.callbacks.enterResult,
            // @ts-ignore
            type: settings.callbacks.type,
            // @ts-ignore
            focus: settings.callbacks.focus,
            // @ts-ignore
            preSuggest: settings.callbacks.preSuggest,
            // @ts-ignore
            line: settings.callbacks.suggestLine,
            // @ts-ignore
            postRender: settings.callbacks.suggestPostRender,
            // @ts-ignore
            blur: settings.callbacks.blur
        };
        options.callbacks.enter = function (query, button, hideHandler) {
            if (!options.layout.navigation.keepOpenTab) {
                core.context.createCookie('ss360-open-tab', '', -1);
            }
            settings.callbacks.enter(query, undefined, undefined, undefined, button, hideHandler);
        };
        options.callbacks.suggestsBuilt = function (suggestBox, data) {
            var forceBranding = settings.forceBranding !== undefined ? settings.forceBranding : false;
            var BRANDED_PLANS = ['FREE', 'DUDA_FREE', 'FIRESPRING_FREE'];
            if (BRANDED_PLANS.indexOf(data.plan) !== -1 || (data.plan === 'CUSTOM' && data.attribution) || (forceBranding === true || BRANDED_PLANS.indexOf((forceBranding || '').toUpperCase()) !== -1)) {
                // eslint-disable-next-line max-len
                suggestBox.append("<div><a href=\"https://sitesearch360.com\" target=\"_blank\"><img alt=\"Powered by Site Search 360\" aria-label=\"Powered by Site Search 360\" role=\"presentation\" style=\"max-width:100px!important;float:right;height:auto!important\" src=\"" + UiHelper.getLogoSrc(settings.allowCookies) + "\"></a></div>");
            }
        };
        options.callbacks.suggestChange = settings.callbacks.suggestChange;
        var suggestSettings = settings.suggestions;
        options.maxWidth = suggestSettings.maxWidth;
        options.throttleTime = suggestSettings.throttleTime;
        options.instantVisualFeedback = suggestSettings.instantVisualFeedback;
        options.extraHtml = suggestSettings.extraHtml;
        options.highlight = suggestSettings.highlight;
        options.queryVisualizationHeadline = suggestSettings.queryVisualizationHeadline;
        options.suggestUrl = suggestSettings.url;
        options.showImagesSuggestions = suggestSettings.showImages;
        options.minChars = suggestSettings.minChars;
        options.triggersSearch = suggestSettings.triggersSearch;
        options.setSearchBoxTextOnSelect = !suggestSettings.linksOpenNewTab;
        options.placeholder = settings.searchBox.placeholder;
        options.animationSpeed = settings.style.animationSpeed;
        options.dataPoints = suggestSettings.dataPoints;
        options.viewAllLabel = suggestSettings.viewAllLabel;
        options.showOnMobile = suggestSettings.showOnMobile;
        options.mobileScrollOnFocus = suggestSettings.mobileScrollOnFocus;
        options.loaderSelector = '.ss360-loader';
        options.viewKeyMappings = SxQueryUtils.extend({
            ss360QuerySuggestions: configInstance !== undefined && configInstance.suggestions !== undefined ? configInstance.suggestions.querySuggestionHeadline : undefined,
            _: settings.contentGroups.otherName || undefined
        }, suggestSettings.viewKeyMappings || {});
        options.themeColor = settings.style.themeColor;
        options.forceBelow = settings.suggestions.forceBelow;
        options.suggestSelectionOrder = settings.suggestions.selectionOrder;
        options.defaultCss = settings.style.defaultCss;
        options.suggestTemplate = suggestSettings.suggestTemplate;
        options.maxHistorySuggestions = suggestSettings.maxSearchHistoryEntries;
        options.historySuggestionLabel = suggestSettings.searchHistoryLabel;
        options.ignoreEnter = options.results.ignoreEnter;
        options.accessibility.headingLevel = settings.accessibility.suggestHeadingLevel;
        options.specialMobileSuggest.autoHide = false;
        options.specialMobileSuggest.shownCallback = settings.specialMobileSuggest.shownCallback;
        if (settings.suggestions.groupCta !== undefined) {
            if (settings.suggestions.groupCta.callback === undefined) {
                settings.suggestions.groupCta.callback = function (query, selectedGroup) {
                    if (settings.allowCookies) {
                        core.context.createCookie('ss360-open-tab', query + "<#>" + StringHelper.getSafeKey(selectedGroup), 1 / 24);
                    }
                    settings.callbacks.enter(query);
                };
            }
            if (settings.suggestions.groupCta.groupLabels === undefined) {
                settings.suggestions.groupCta.groupLabels = {};
            }
            if (settings.suggestions.groupCta.groupLabels.ss360QuerySuggestions === undefined) {
                settings.suggestions.groupCta.groupLabels.ss360QuerySuggestions = null;
            }
        }
        options.groupCta = settings.suggestions.groupCta;
        options.specialMobileSuggest.hiddenCallback = function () {
            // focus layer header
            setTimeout(function () {
                core.context.getExistingLayer().find('.ss360-layer__heading-anchor').focus();
            }, 200);
        };
        options.searchButtonSelector = settings.searchBox.searchButton;
        options.trackingCallbacks = {
            select: function (searchBox, suggestBox, target, query, suggestions, position, link, contentGroup, source) {
                if (core.getContext().hasInsights()) {
                    sxQuery(window).off("beforeunload." + core.context.generateId('ss360Insights'));
                    core.getContext().getInsights().trackSelectSuggest(searchBox, suggestBox, target, query, suggestions.length, position, link, contentGroup, source);
                }
            },
            show: function (searchBox, suggestBox, suggestion, query, suggestions, queryDuration) {
                if (core.getContext().hasInsights()) {
                    core.getContext().getInsights().trackShowSuggests(searchBox, suggestBox, suggestion, query, suggestions.length, queryDuration);
                }
            },
            change: function (searchBox) {
                if (core.getContext().hasInsights()) {
                    core.getContext().getInsights().collectSearchBoxChange(searchBox);
                }
            },
            abandon: function (query, suggestionCount, searchBox) {
                if (core.getContext().hasInsights()) {
                    core.getContext().getInsights().trackSearchBoxAbandon(query, suggestionCount, searchBox);
                }
            }
        };
        options.showProductFinder = ((_b = (_a = settings.productFinder) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.type) === 'suggests';
        options.productFinderConfig = settings.productFinder;
    }
};
export default ConfigurationHelper;
