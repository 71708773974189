'use strict';
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// TODO: logger should be set on Context #multiple-instances
var loggers = {
    info: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.log.apply(console, __spreadArrays([param], rest));
    },
    warn: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.warn.apply(console, __spreadArrays([param], rest));
    },
    error: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.error.apply(console, __spreadArrays([param], rest));
    }
};
var LoggerLevel;
(function (LoggerLevel) {
    LoggerLevel["Info"] = "info";
    LoggerLevel["Warn"] = "warn";
    LoggerLevel["Error"] = "error";
})(LoggerLevel || (LoggerLevel = {}));
var Logger = {
    info: loggers.info,
    warn: loggers.warn,
    error: loggers.error,
    set: function (level, handler) {
        Logger[level] = handler;
    }
};
export default Logger;
