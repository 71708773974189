'use strict';
var getBaseUrl = function (url) {
    if (url.indexOf('?') === -1) {
        return url + "?";
    }
    if (url[url.length - 1] !== '&' && url[url.length - 1] !== '?') {
        return url + "&";
    }
    return url;
};
var UrlBuilder = /** @class */ (function () {
    function UrlBuilder(apiConfig, configuration) {
        this.apiConfig = apiConfig;
        this.searchBaseUrl = getBaseUrl(apiConfig.baseUrl);
        this.suggestBaseUrl = getBaseUrl(apiConfig.suggestBaseUrl);
        this.pluginConfiguration = configuration;
    }
    UrlBuilder.prototype.buildQueryUrl = function (params) {
        var limit = params.limit, sort = params.sort, include = params.include, exclude = params.exclude, tracking = params.tracking, query = params.query, offset = params.offset, limitPerGroup = params.limitPerGroup, groupResults = params.groupResults, filters = params.filters, reporter = params.reporter, isPlain = params.isPlain, includeQueries = params.includeQueries, sortComplex = params.sortComplex, addQueries = params.addQueries;
        var queryUrl = this.searchBaseUrl + "site=" + this.pluginConfiguration.siteId + "&timeToAction=" + reporter.getTimeToAction() + "&includeContent=true&limit=" + limit;
        if (sort !== undefined) {
            queryUrl += "&sort=" + encodeURIComponent(sort);
        }
        if (groupResults === false) {
            queryUrl += '&groupResults=false';
        }
        if (limitPerGroup) {
            queryUrl += '&limitPerGroup=true';
        }
        var results = this.pluginConfiguration.results;
        if (results.highlightQueryTerms === true) {
            queryUrl += '&highlightQueryTerms=true';
        }
        if (include !== undefined) {
            queryUrl += "&includeContentGroups=" + encodeURIComponent(JSON.stringify(include));
        }
        if (exclude !== undefined) {
            queryUrl += "&excludeContentGroups=" + encodeURIComponent(JSON.stringify(exclude));
        }
        if (filters !== undefined) {
            queryUrl += "&filters=" + encodeURIComponent(JSON.stringify(filters));
        }
        if (!tracking) {
            queryUrl += '&log=false';
        }
        if (this.pluginConfiguration.filters.enabled) {
            queryUrl += '&filterOptions=true';
        }
        queryUrl += "&offset=" + offset;
        queryUrl += "&query=" + encodeURIComponent(query);
        var cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += "&cache=" + cacheSettings + "&caching=" + cacheSettings;
        }
        if (!isPlain) {
            queryUrl += '&plain=false';
        }
        if (results.nameParsing && filters !== undefined) {
            queryUrl += '&nameParsing=true';
        }
        if (results.fuzziness !== undefined) {
            queryUrl += "&fuzziness=" + results.fuzziness;
        }
        if (includeQueries !== undefined && includeQueries.length > 0) {
            queryUrl += "&includeQueries=" + encodeURIComponent(JSON.stringify(includeQueries));
        }
        if (addQueries !== undefined && addQueries.length > 0) {
            queryUrl += "&addQueries=" + encodeURIComponent(JSON.stringify(addQueries));
        }
        if (sortComplex !== undefined) {
            queryUrl += "&sortComplex=" + encodeURIComponent(JSON.stringify(sortComplex));
        }
        queryUrl += '&kvtable=false';
        queryUrl += '&complexFilterFormat=true';
        return queryUrl;
    };
    UrlBuilder.prototype.buildSuggestionUrl = function (tracking) {
        var suggestions = this.pluginConfiguration.suggestions;
        if (!suggestions.show) {
            return '';
        }
        var queryUrl = this.suggestBaseUrl + "site=" + this.pluginConfiguration.siteId;
        var num = suggestions.num;
        if (num !== null) {
            queryUrl = queryUrl + "&limit=" + num;
        }
        if (suggestions.equalSearch) {
            queryUrl = queryUrl.replace('/sites/suggest', '/sites');
            queryUrl += '&includeContent=true';
        }
        var contentGroups = this.pluginConfiguration.contentGroups;
        var include = contentGroups.include, exclude = contentGroups.exclude;
        var ignoreOther = contentGroups.ignoreOther;
        var maxSuggestions = suggestions.maxSuggestions;
        if (Object.keys(maxSuggestions).length > 0) {
            include = Object.keys(maxSuggestions).map(function (key) { return key + "#" + maxSuggestions[key]; });
        }
        if (include !== undefined) {
            queryUrl += "&includeContentGroups=" + encodeURIComponent(JSON.stringify(include));
        }
        if (exclude !== undefined || ignoreOther) {
            if (exclude === undefined) {
                exclude = [];
            }
            if (ignoreOther && exclude.indexOf('_') === -1) {
                exclude.push('_');
            }
            queryUrl += "&excludeContentGroups=" + encodeURIComponent(JSON.stringify(exclude));
        }
        if (this.pluginConfiguration.results.group === false) {
            queryUrl += '&groupResults=false';
        }
        var maxQuerySuggestions = suggestions.maxQuerySuggestions;
        if (maxQuerySuggestions > 0) {
            queryUrl += "&maxQuerySuggestions=" + maxQuerySuggestions;
        }
        var cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += "&cache=" + cacheSettings + "&caching=" + cacheSettings;
        }
        var fuzziness = suggestions.fuzziness;
        if (fuzziness !== undefined) {
            queryUrl += "&fuzziness=" + fuzziness;
        }
        if (tracking) {
            queryUrl += '&log=true';
        }
        queryUrl += '&query=';
        return queryUrl;
    };
    UrlBuilder.prototype.setSearchBase = function (url) {
        this.searchBaseUrl = getBaseUrl(url);
    };
    UrlBuilder.prototype.setSuggestBase = function (url) {
        this.suggestBaseUrl = getBaseUrl(url);
    };
    return UrlBuilder;
}());
export default UrlBuilder;
