'use strict';
var UniboxDefaultConfig = {
    // these are the defaults.
    suggestUrl: '',
    showImagesSuggestions: true,
    ivfImagePath: '',
    ivfImageOffset: -80,
    missingErrorImage: undefined,
    queryVisualizationHeadline: '',
    highlight: true,
    throttleTime: 50,
    animationSpeed: 300,
    instantVisualFeedback: 'all',
    placeholder: undefined,
    extraHtml: undefined,
    lineCallback: undefined,
    noSuggests: undefined,
    emptyQuerySuggests: undefined,
    minChars: 3,
    maxWidth: 'auto',
    suggestOrder: [],
    suggestSelectionOrder: [],
    headingLevel: 4,
    enabled: true,
    mobileScrollOnFocus: true,
    triggersSearch: false,
    searchButtonSelector: undefined,
    defaultCss: true,
    suggestTemplate: undefined,
    groupCta: {
        show: false,
        label: 'View all #GROUP# results',
        groupLabels: {},
        callback: undefined,
        lowerCaseNames: false
    },
    maxHistorySuggestions: 5,
    historySuggestionLabel: 'You recently searched for:',
    ignoreEnter: false,
    setSearchBoxTextOnSelect: true,
    showProductFinder: false
};
export default UniboxDefaultConfig;
