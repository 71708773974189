'use strict';
var _animations = {};
var _animationNodeFlag = 0;
// private methods and properties
var _requestAnimation = function (tick) { return (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16); };
var _stopAnimation = function (id) {
    if (!window.cancelAnimationFrame) {
        clearTimeout(id);
    }
    else {
        window.cancelAnimationFrame(id);
    }
};
var SxQueryShared = {
    _notifyAnimation: function (node, type, id) {
        if (!node.sxQueryAnimationFlag) {
            node.sxQueryAnimationFlag = _animationNodeFlag;
            _animationNodeFlag++;
        }
        if (_animations[node.sxQueryAnimationFlag] === undefined) {
            _animations[node.sxQueryAnimationFlag] = {};
        }
        _animations[node.sxQueryAnimationFlag][type] = id;
    },
    _clearAnimation: function (node, type) {
        var flg = node.sxQueryAnimationFlag;
        if (flg !== undefined && _animations[flg] !== undefined && (type in _animations[flg])) {
            _stopAnimation(_animations[flg][type]);
        }
    },
    _requestAnimation: _requestAnimation,
    _stopAnimation: _stopAnimation,
    prefersReducedMotion: function () {
        return window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    },
    parseHTML: function (str) {
        var tmp = document.implementation.createHTMLDocument('');
        tmp.body.innerHTML = str;
        var res = [];
        for (var i = 0; i < tmp.body.children.length; i++) {
            res.push(document.importNode(tmp.body.children[i], true));
        }
        return res;
    }
};
export default SxQueryShared;
