'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import StringHelper from '../../global/StringHelper';
import NavigatorHelper from '../utils/NavigatorHelper';
import UiHelper from '../ui/UiHelper';
import LazyLoad from '../utils/LazyLoad';
import SearchResponse from '../model/SearchResponse';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
var Results = {
    fetch: function (url, context, callback, failCallback) {
        SxQueryUtils.get(url, function (data) {
            var searchResponse = new SearchResponse(data);
            if (callback !== undefined) {
                callback(searchResponse);
            }
        }, failCallback, undefined, !context.pluginConfiguration.ignoreShopSysHeader);
    },
    getOrFetch: function (url, context, callback, failCallback, siteId) {
        // TODO: cast to api response
        var cachedResult = context.readObject('ss360_last_query_result');
        if (cachedResult !== undefined && cachedResult.timestampMs !== undefined && cachedResult.siteId !== undefined
            && cachedResult.locationHref === window.location.href && (cachedResult.timestampMs + 5 * 60 * 1000) > new Date().getTime() && cachedResult.siteId === siteId) { // window location must match and the result must not be older than 5 minutes
            if (cachedResult.suggests !== undefined && cachedResult.suggests['*'] !== undefined) {
                delete cachedResult.suggests['*'];
            }
            callback(new SearchResponse(cachedResult));
        }
        else {
            Results.fetch(url, context, callback, failCallback);
        }
    },
    prefetchAndRender: function (s, rest, uiBuilder, callback, context) {
        var safeKey = StringHelper.getSafeKey(s.contentGroup);
        var resultTarget = context.getExistingLayer().find(".ss360-group-" + safeKey + " ul.ss360-list");
        var groupParent = context.getExistingLayer().find(".ss360-group-" + safeKey);
        var skippedResults = 0;
        if (groupParent.data('ss360SkippedResults') !== undefined) {
            skippedResults += parseInt(groupParent.data('ss360SkippedResults'), 10);
        }
        var queryUrl = context.core.buildQueryUrl({
            limit: s.limit,
            include: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [s.contentGroup] : context.pluginConfiguration.contentGroups.include,
            exclude: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [] : context.pluginConfiguration.contentGroups.exclude,
            tracking: false,
            query: s.query,
            offset: s.offset + skippedResults,
            limitPerGroup: false,
            isPlain: false,
            filters: s.filters,
            group: s.groupResults !== undefined ? s.groupResults : undefined,
            sort: s.sort
        });
        Results.fetch(queryUrl, context, function (data) {
            var results = data.getSuggestsArray(s.contentGroup);
            var hiddenParts = UiHelper.getHiddenParts(context.pluginConfiguration.layout);
            var wereResultsRendered = false;
            if (results !== undefined) {
                results.forEach(function (suggest, idx) {
                    if (UiHelper.hasVisibleContent(suggest, hiddenParts)) {
                        var $item = uiBuilder.buildSuggestItem(suggest, s.query, s.contentGroup, rest <= idx);
                        resultTarget.append($item);
                        wereResultsRendered = true;
                    }
                    else {
                        skippedResults++;
                    }
                });
                if (skippedResults !== 0) {
                    groupParent.data('ss360SkippedResults', skippedResults);
                }
                if (!wereResultsRendered && s.offset + skippedResults < context.pluginConfiguration.results.num) { // we try to fetch and render the next portion of results
                    Results.prefetchAndRender(s, rest, uiBuilder, callback, context);
                    return;
                }
                if (context.pluginConfiguration.callbacks.resultsPreloaded !== undefined) {
                    context.pluginConfiguration.callbacks.resultsPreloaded(data.plain);
                }
            }
            if (callback !== undefined && typeof callback === 'function') {
                callback(results);
            }
        });
    },
    wasBackPressed: function (query, allowCookies, context) {
        return allowCookies ? context.readCookie('ss360-query--c') === query : window.location.search.indexOf('ss360Offset=') !== -1;
    },
    handleBackPress: function (navigation, query, searchResultType, context) {
        var queryDict = NavigatorHelper.buildQueryDict();
        var pageSize = context.pluginConfiguration.results.moreResultsPagingSize;
        var allowCookies = context.pluginConfiguration.allowCookies;
        var offset = parseInt(allowCookies ? context.readCookie('ss360-offset--c') : queryDict.ss360Offset, 10);
        var cg = allowCookies ? context.readCookie('ss360-cg--c') : queryDict.ss360ContentGroup;
        if (cg === null || cg === undefined) {
            cg = '_';
        }
        var cgKey = StringHelper.getSafeKey(cg);
        var groupWrapper = cg ? context.getExistingLayer().find(".ss360-group-" + cgKey) : context.getExistingLayer().find('.ss360-group-_:first');
        var performScroll = function () {
            // switch content group if necessary
            navigation.focusTab(cgKey);
            // perform the scroll
            var relevantResult = sxQuery(groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)').get()[offset]);
            navigation.scrollTo(relevantResult, searchResultType);
            setTimeout(function () {
                relevantResult.find('.ss360-suggests__link').focus();
            }, 5);
        };
        var filterOptions = NavigatorHelper.getFilters(context.pluginConfiguration.results);
        if (offset < groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)').length) { // the relevant result is visible
            performScroll();
        }
        else if (offset < groupWrapper.find('.ss360-suggests').length) {
            var newOffset = groupWrapper.find('.ss360-suggests').length;
            var callback = function () {
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) { // the relevant result is not visible but is loaded
                    groupWrapper.find('.ss360-more-results').remove();
                }
                LazyLoad(context);
            };
            context.core.prefetchResults({
                offset: newOffset,
                contentGroup: cg,
                query: query,
                callback: callback,
                filters: filterOptions
            }); // prefetch more results
            var hidden = groupWrapper.find('.ss360-suggests.ss360-suggests--hidden');
            hidden.removeClass('ss360-suggests--hidden'); // show all loaded results
            performScroll();
        }
        else { // the relevant result has to be fetched
            UiHelper.showLoadingAnimation(context);
            var toPreloadCount = (offset + 1) - groupWrapper.find('.ss360-suggests').length;
            var pagesToPreload_1 = Math.ceil(toPreloadCount / pageSize) + 1;
            var overrides = {
                num: offset + pagesToPreload_1 * pageSize,
                pageSize: pagesToPreload_1 * pageSize
            };
            groupWrapper.find('.ss360-suggests').removeClass('ss360-suggests--hidden');
            var loadedResultCount_1 = groupWrapper.find('.ss360-suggests').length;
            var callback = function () {
                var expectedResultCount = pageSize * pagesToPreload_1 + loadedResultCount_1;
                var allExpectedResultsLoaded = groupWrapper.find('.ss360-suggests').length >= expectedResultCount;
                var allSuggests = groupWrapper.find('.ss360-suggests');
                for (var i = 0; i < allSuggests.length; i++) {
                    var $suggest = sxQuery(allSuggests.get()[i]);
                    if (!allExpectedResultsLoaded || i < (allSuggests.length - pageSize)) {
                        $suggest.removeClass('ss360-suggests--hidden');
                    }
                    else {
                        $suggest.addClass('ss360-suggests--hidden');
                    }
                }
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) {
                    groupWrapper.find('.ss360-more-results').remove();
                }
                UiHelper.hideLoadingAnimation(context);
                performScroll();
                LazyLoad(context);
            };
            context.core.prefetchResults({
                offset: loadedResultCount_1,
                contentGroup: cg,
                query: query,
                callback: callback,
                overrides: overrides,
                filters: filterOptions
            });
        }
        // cleanup
        if (!allowCookies) {
            delete queryDict.ss360ContentGroup;
            delete queryDict.ss360Offset;
            NavigatorHelper.replaceState(queryDict, context.pluginConfiguration);
        }
        else {
            context.createCookie('ss360-cg--c', '', 1 / 24);
            context.createCookie('ss360-offset--c', '-1', 1 / 24);
            context.createCookie('ss360-query--c', '', 1 / 24);
        }
    }
};
export default Results;
