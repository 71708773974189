'use strict';
import sxQuery from '../sxQuery/sxQuery';
import Helper from '../siteSearch/utils/Helper';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
var InsightsRequestType;
(function (InsightsRequestType) {
    InsightsRequestType["Post"] = "POST";
    InsightsRequestType["Get"] = "GET";
    InsightsRequestType["Image"] = "IMAGE";
})(InsightsRequestType || (InsightsRequestType = {}));
var getStringValue = function (val) {
    if (val === undefined) {
        return '---';
    }
    return val.trim();
};
var encodeComponent = function (component) { return encodeURIComponent(component !== undefined && component.split ? component.split('/').join('___') : component); };
var DAY_MS = 24 * 60 * 60 * 1000; // day in ms
var SESSION_DURATION_MS = 10 * 60 * 1000; // session duration after last interaction
var getNumberValue = function (val) {
    if (val === undefined || Helper.isNaN(val)) {
        return -1;
    }
    return val;
};
var getClientWidth = function () { return getNumberValue(document.documentElement.clientWidth || window.innerWidth); };
var getOffsetX = function (el) { return getNumberValue(el ? Math.floor(el.getBoundingClientRect().left + window.scrollX) : undefined); };
var getOffsetY = function (el) { return getNumberValue(el ? Math.floor(el.getBoundingClientRect().top + window.scrollY) : undefined); };
var getWidth = function (el) { return getNumberValue(el ? Math.floor(el.getBoundingClientRect().width) : undefined); };
var getHeight = function (el) { return getNumberValue(el ? Math.floor(el.getBoundingClientRect().height) : undefined); };
var generateElementSelector = function (el, checkParent) {
    if (checkParent === void 0) { checkParent = true; }
    if (!el) {
        return undefined;
    }
    var className = '';
    try {
        className = el.className && el.className ? "." + el.className.split(' ').join('.') : '';
    }
    catch (ex) {
        className = (el.getAttribute ? el.getAttribute('class') : '') || '';
    }
    var id = el.getAttribute('id') ? "#" + el.getAttribute('id') : '';
    var parentEl = el.parentNode;
    var selector = '';
    if (parentEl && checkParent) {
        // @ts-ignore
        selector = generateElementSelector(parentEl, false) + " ";
    }
    selector += el.tagName.toLowerCase() + id + className;
    return selector;
};
var SS360Insights = /** @class */ (function () {
    function SS360Insights(siteId, allowCookies, trackingConfig, cookiePostfix) {
        this.siteId = siteId;
        this.allowCookies = allowCookies;
        this.searchQueryCache = { query: undefined, timestamp: undefined };
        this.suggestionQueryCache = { query: undefined, timestamp: undefined };
        this.cookiePostfix = cookiePostfix;
        this.config = {
            baseUrl: 'https://insights.sitesearch360.com/insights',
            endpoints: {
                // eslint-disable-next-line max-len
                serpSelect: '/serp/select/##siteId##/##sessionId##/##timestamp##/##query##/##clientWidth##/##resultOffsetX##/##resultOffsetY##/##resultWidth##/##resultHeight##/##resultBlockWidth##/##resultBlockHeight##/##resultCount##/##position##/##positionInContentGroup##/##link##/##searchResultType##/##referrer##',
                searchBoxFocus: '/searchbox/focus',
                searchBoxBulkChange: '/searchbox/change/bulk',
                searchBoxAbandon: '/searchbox/abandon',
                // eslint-disable-next-line max-len
                searchSubmit: '/search/submit/##siteId##/##sessionId##/##timestamp##/##query##/##searchBoxWidth##/##searchBoxHeight##/##clientWidth##/##searchBoxOffsetX##/##searchBoxOffsetY##/##searchBoxId##/##searchButtonId##/##referrer##',
                suggestShow: '/suggests/show',
                // eslint-disable-next-line max-len
                suggestSelect: '/suggests/select/##siteId##/##sessionId##/##timestamp##/##query##/##searchBoxWidth##/##searchBoxHeight##/##clientWidth##/##resultCount##/##suggestOffsetX##/##suggestOffsetY##/##searchBoxId##/##resultWidth##/##resultHeight##/##resultBlockWidth##/##resultBlockHeight##/##position##/##link##/##referrer##',
                serpShow: '/serp/show',
                // eslint-disable-next-line max-len
                serpAbandon: '/serp/abandon/##siteId##/##sessionId##/##timestamp##/##query##/##clientWidth##/##serpBlockOffsetX##/##serpBlockOffsetY##/##resultWidth##/##resultHeight##/##resultBlockWidth##/##resultBlockHeight##/##resultCount##/##searchResultType##/##referrer##',
                filterInteraction: '/filter/interaction'
            }
        };
        if (trackingConfig.enhancedParams !== undefined) {
            this.additionalParameters = trackingConfig.enhancedParams;
        }
        else {
            this.additionalParameters = {};
        }
        if (trackingConfig.userGroup !== undefined) {
            this.additionalParameters.userGroup = trackingConfig.userGroup;
        }
        if (trackingConfig.enhancedBaseUrl !== undefined) {
            this.config.baseUrl = trackingConfig.enhancedBaseUrl;
        }
        this.sessionId = this.getSessionId();
        this.searchBoxChanges = {};
        this.latestSearchBoxValues = {};
    }
    SS360Insights.prototype.updateLastInteractionTime = function () {
        SxQueryUtils.createCookie("ssi--lastInteraction" + this.cookiePostfix, new Date().getTime().toString(), (SESSION_DURATION_MS) / DAY_MS);
    };
    SS360Insights.prototype.loadSessionId = function () { return SxQueryUtils.readCookie("ssi--sessionId" + this.cookiePostfix); };
    SS360Insights.prototype.loadLastInteraction = function () {
        var lastInteraction = SxQueryUtils.readCookie("ssi--lastInteraction" + this.cookiePostfix);
        return lastInteraction !== undefined && lastInteraction !== null ? parseInt(lastInteraction, 10) : undefined;
    };
    SS360Insights.prototype.getSessionId = function () {
        if (!this.allowCookies) {
            return this.generateSessionId();
        }
        var sessionId = this.loadSessionId();
        if (sessionId === null) {
            return this.generateSessionId();
        }
        var lastInteraction = this.loadLastInteraction();
        if (lastInteraction === undefined || (new Date().getTime() - lastInteraction) > SESSION_DURATION_MS) {
            return this.generateSessionId(sessionId);
        }
        return sessionId;
    };
    SS360Insights.prototype.generateSessionId = function (previousSessionId) {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        var sessionId = s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
        var baseUrl = this.config.baseUrl;
        var additionalParamString = '';
        // eslint-disable-next-line no-undef
        // @ts-ignore
        if (SS360_VERSION !== undefined) {
            var versionNumber = void 0;
            // eslint-disable-next-line no-undef
            // @ts-ignore
            var versionParts = SS360_VERSION.toString().split('.'); // major.minor.patch
            if (versionParts.length > 1) {
                versionNumber = versionParts[0] + "." + versionParts[1];
            }
            if (versionNumber !== undefined) {
                additionalParamString += "version=" + encodeURIComponent(versionNumber);
            }
        }
        var sessionLog = {
            type: InsightsRequestType.Post,
            url: baseUrl + "/session" + (additionalParamString.length > 0 ? '?' : '') + additionalParamString,
            data: {
                siteId: this.siteId,
                sessionId: sessionId,
                timestamp: new Date().getTime(),
                isPersistent: this.allowCookies,
                referrer: getStringValue(window.location.href)
            }
        };
        if (previousSessionId !== undefined) {
            // @ts-ignore
            sessionLog.data.prevSessionId = previousSessionId;
        }
        this.sendRequest(sessionLog);
        if (this.allowCookies) {
            SxQueryUtils.createCookie("ssi--sessionId" + this.cookiePostfix, sessionId, 365);
            this.updateLastInteractionTime(); // generating session id counts as interaction
        }
        return sessionId;
    };
    SS360Insights.prototype.buildAdditionalParamsString = function () {
        var _this = this;
        if (this.additionalParameters === undefined || this.additionalParameters === null || Object.keys(this.additionalParameters).length === 0)
            return '';
        return Object.keys(this.additionalParameters).reduce(function (acc, key) { return acc + "&" + encodeURIComponent(key) + "=" + encodeURIComponent(_this.additionalParameters[key]); }, '').substring(1);
    };
    SS360Insights.prototype.sendRequest = function (request) {
        var additionalParameters = this.buildAdditionalParamsString();
        if (additionalParameters !== undefined && additionalParameters.length > 0) {
            var requestUrl = request.url;
            if (requestUrl.indexOf('?') === -1) {
                requestUrl = requestUrl + "?";
            }
            else if (requestUrl[requestUrl.length - 1] !== '&' && requestUrl[requestUrl.length - 1] !== '?') {
                requestUrl = requestUrl + "&";
            }
            request.url = "" + requestUrl + additionalParameters;
        }
        if (request.type === InsightsRequestType.Image) {
            var i = new Image(1, 1);
            i.src = request.url;
        }
        else if (request.type === InsightsRequestType.Get) {
            SxQueryUtils.get(request.url);
        }
        else if (request.type === InsightsRequestType.Post) {
            SxQueryUtils.post(request.url, request.data);
        }
    };
    SS360Insights.prototype.createImageRequest = function (endpoint, sessionId, data) {
        Object.keys(data).forEach(function (key) {
            endpoint = endpoint.replace("##" + key + "##", encodeComponent(data[key]));
        });
        endpoint = endpoint.replace('##siteId##', encodeComponent(this.siteId));
        endpoint = endpoint.replace('##sessionId##', encodeComponent(sessionId));
        endpoint = endpoint.replace('##timestamp##', encodeComponent(new Date().getTime().toString()));
        endpoint = endpoint.replace('##clientWidth##', encodeComponent(getClientWidth().toString()));
        endpoint = endpoint.replace(/##.+?##/g, '');
        return {
            type: InsightsRequestType.Image,
            url: this.config.baseUrl + endpoint
        };
    };
    SS360Insights.prototype.pretrack = function () {
        if (this.allowCookies) {
            this.updateLastInteractionTime();
        }
    };
    SS360Insights.prototype.trackSerpClick = function (query, eventTarget, resultBlock, resultCount, position, positionInContentGroup, link, searchResultType) {
        this.pretrack();
        var resultOffsetX = getOffsetX(eventTarget);
        var resultOffsetY = getOffsetY(eventTarget);
        var resultWidth = getWidth(eventTarget);
        var resultHeight = getHeight(eventTarget);
        var resultBlockWidth = getWidth(resultBlock);
        var resultBlockHeight = getHeight(resultBlock);
        // @ts-ignore
        var identifier = eventTarget !== undefined && eventTarget !== null ? sxQuery(eventTarget).data('ss360Identifier') : undefined;
        var identifierParam = identifier !== undefined && identifier !== null ? "?identifier=" + encodeURIComponent(identifier) : '';
        var request = this.createImageRequest("" + this.config.endpoints.serpSelect + identifierParam, this.sessionId, {
            query: getStringValue(query),
            resultOffsetX: getNumberValue(resultOffsetX),
            resultOffsetY: getNumberValue(resultOffsetY),
            resultWidth: getNumberValue(resultWidth),
            resultHeight: getNumberValue(resultHeight),
            resultBlockWidth: getNumberValue(resultBlockWidth),
            resultBlockHeight: getNumberValue(resultBlockHeight),
            resultCount: getNumberValue(resultCount),
            position: getNumberValue(position),
            positionInContentGroup: getNumberValue(positionInContentGroup),
            link: getStringValue(link),
            searchResultType: getStringValue(searchResultType),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
    };
    SS360Insights.prototype.trackSearchBoxFocus = function (searchBox) {
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            searchBoxWidth: getWidth(searchBox),
            searchBoxHeight: getHeight(searchBox),
            clientWidth: getClientWidth(),
            searchBoxOffsetX: getOffsetX(searchBox),
            searchBoxOffsetY: getOffsetY(searchBox),
            searchBoxId: generateElementSelector(searchBox, true),
            referrer: getStringValue(window.location.href),
            query: getStringValue(searchBox.value)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.searchBoxFocus,
            type: InsightsRequestType.Post,
            data: data
        });
    };
    SS360Insights.prototype.collectSearchBoxChange = function (searchBox) {
        var _this = this;
        this.pretrack();
        var sessionId = this.sessionId;
        var sbSelector = generateElementSelector(searchBox, true);
        var searchBoxChanges = this.searchBoxChanges;
        if (!(sbSelector in searchBoxChanges) || searchBoxChanges[sbSelector] === undefined) {
            searchBoxChanges[sbSelector] = {
                data: [],
                siteId: this.siteId,
                sessionId: sessionId,
                searchBoxWidth: getNumberValue(getWidth(searchBox)),
                searchBoxHeight: getNumberValue(getHeight(searchBox)),
                searchBoxOffsetX: getNumberValue(getOffsetX(searchBox)),
                searchBoxOffsetY: getNumberValue(getOffsetY(searchBox)),
                clientWidth: getClientWidth(),
                searchBoxId: sbSelector,
                referrer: getStringValue(window.location.href)
            };
        }
        var val = getStringValue(searchBox.value);
        if (this.latestSearchBoxValues[sbSelector] === val) {
            return; // no need to track empty change
        }
        this.suggestionQueryCache.query = searchBox.value;
        this.suggestionQueryCache.timestamp = new Date().getTime();
        this.latestSearchBoxValues[sbSelector] = val;
        clearTimeout(searchBoxChanges[sbSelector].timeoutId);
        searchBoxChanges[sbSelector].data.push({
            timestamp: (new Date()).getTime(),
            // @ts-ignore
            query: getStringValue(sxQuery(searchBox).val())
        });
        searchBoxChanges[sbSelector].timeoutId = setTimeout(function () {
            var data = searchBoxChanges[sbSelector];
            data.data = JSON.stringify(data.data);
            _this.sendRequest({
                url: _this.config.baseUrl + _this.config.endpoints.searchBoxBulkChange,
                type: InsightsRequestType.Post,
                data: data
            });
            searchBoxChanges[sbSelector] = undefined;
        }, 300);
    };
    SS360Insights.prototype.trackSubmitSearch = function (query, searchBox, searchButton, searchBoxId) {
        this.pretrack();
        this.searchQueryCache.query = query;
        this.searchQueryCache.timestamp = new Date().getTime();
        var request = this.createImageRequest(this.config.endpoints.searchSubmit, this.sessionId, {
            query: getStringValue(query),
            searchBoxWidth: getNumberValue(getWidth(searchBox)),
            searchBoxHeight: getNumberValue(getHeight(searchBox)),
            searchBoxOffsetX: getNumberValue(getOffsetX(searchBox)),
            searchBoxOffsetY: getNumberValue(getOffsetY(searchBox)),
            searchBoxId: searchBoxId !== undefined ? getStringValue(searchBoxId) : getStringValue(generateElementSelector(searchBox, true)),
            searchButtonId: getStringValue(generateElementSelector(searchButton, true)),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
    };
    SS360Insights.prototype.trackSearchBoxAbandon = function (query, resultCount, searchBox) {
        if (query === undefined) {
            query = '';
        }
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            searchBoxWidth: getWidth(searchBox),
            searchBoxHeight: getHeight(searchBox),
            clientWidth: getClientWidth(),
            resultCount: getNumberValue(resultCount),
            searchBoxOffsetX: getOffsetX(searchBox),
            searchBoxOffsetY: getOffsetY(searchBox),
            searchBoxId: generateElementSelector(searchBox, true),
            referrer: getStringValue(window.location.href)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.searchBoxAbandon,
            type: InsightsRequestType.Post,
            data: data
        });
    };
    SS360Insights.prototype.trackShowSuggests = function (searchBox, suggestBlock, suggestItem, query, resultCount, queryDuration) {
        this.pretrack();
        // var queryDuration = suggestionQueryCache.query === query ? (new Date().getTime() - suggestionQueryCache.timestamp) : undefined;
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            searchBoxWidth: getWidth(searchBox),
            searchBoxHeight: getHeight(searchBox),
            clientWidth: getClientWidth(),
            resultCount: getNumberValue(resultCount),
            suggestBoxOffsetX: getOffsetX(suggestBlock),
            suggestBoxOffsetY: getOffsetY(suggestBlock),
            searchBoxId: generateElementSelector(searchBox, true),
            resultWidth: getWidth(suggestItem),
            resultHeight: getHeight(suggestItem),
            resultBlockHeight: getHeight(suggestBlock),
            resultBlockWidth: getWidth(suggestBlock),
            referrer: getStringValue(window.location.href)
        };
        if (queryDuration !== undefined) {
            // @ts-ignore
            data.queryDuration = queryDuration;
        }
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.suggestShow,
            type: InsightsRequestType.Post,
            data: data
        });
    };
    SS360Insights.prototype.trackSelectSuggest = function (searchBox, suggestBlock, suggestItem, query, resultCount, position, link, contentGroup, source) {
        this.pretrack();
        var url = this.config.endpoints.suggestSelect;
        // @ts-ignore
        var identifier = suggestItem !== undefined && suggestItem !== null ? sxQuery(suggestItem).data('ss360Identifier') : undefined;
        if ((identifier === null || identifier === undefined) && suggestItem !== null && suggestItem !== undefined) {
            identifier = sxQuery(suggestItem).parents('.unibox__selectable', true).data('ss360Identifier');
        }
        var hasQueryParams = contentGroup !== undefined || source !== undefined || (identifier !== undefined && identifier !== null);
        if (hasQueryParams) {
            url = url + "?";
        }
        if (contentGroup !== undefined) {
            url = url + "contentGroup=" + encodeURIComponent(contentGroup);
        }
        if (source !== undefined) {
            url = "" + url + (contentGroup !== undefined ? '&' : '') + "source=" + encodeURIComponent(source);
        }
        if (identifier !== undefined && identifier !== null) {
            url = "" + url + (contentGroup !== undefined || source !== undefined ? '&' : '') + "identifier=" + encodeURIComponent(identifier);
        }
        var request = this.createImageRequest(url, this.sessionId, {
            query: getStringValue(query),
            searchBoxWidth: getNumberValue(getWidth(searchBox)),
            searchBoxHeight: getNumberValue(getHeight(searchBox)),
            searchBoxOffsetX: getNumberValue(getOffsetX(searchBox)),
            searchBoxOffsetY: getNumberValue(getOffsetY(searchBox)),
            searchBoxId: getStringValue(generateElementSelector(searchBox, true)),
            resultCount: getNumberValue(resultCount),
            suggestOffsetX: getNumberValue(getOffsetX(suggestItem)),
            suggestOffsetY: getNumberValue(getOffsetY(suggestItem)),
            resultWidth: getNumberValue(getWidth(suggestItem)),
            resultHeight: getNumberValue(getHeight(suggestItem)),
            resultBlockWidth: getNumberValue(getWidth(suggestBlock)),
            resultBlockHeight: getNumberValue(getHeight(suggestBlock)),
            position: getNumberValue(position),
            link: getStringValue(link),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
    };
    SS360Insights.prototype.trackSerpShow = function (resultBlock, resultItem, query, resultCount, resultType, filterValues) {
        this.pretrack();
        var sessionId = this.sessionId;
        var queryDuration = this.searchQueryCache.query === query ? (new Date().getTime() - this.searchQueryCache.timestamp) : undefined;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            clientWidth: getClientWidth(),
            resultCount: getNumberValue(resultCount),
            serpBlockOffsetX: getOffsetX(resultBlock),
            serpBlockOffsetY: getOffsetY(resultBlock),
            resultWidth: getWidth(resultItem),
            resultHeight: getHeight(resultItem),
            resultBlockWidth: getWidth(resultBlock),
            resultBlockHeight: getHeight(resultBlock),
            searchResultType: resultType,
            referrer: getStringValue(window.location.href),
            filters: getStringValue(filterValues)
        };
        if (queryDuration !== undefined) {
            // @ts-ignore
            data.queryDuration = queryDuration;
        }
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.serpShow,
            type: InsightsRequestType.Post,
            data: data
        });
    };
    SS360Insights.prototype.trackSerpLeave = function (resultBlock, resultItem, query, resultCount, resultType) {
        this.pretrack();
        var request = this.createImageRequest(this.config.endpoints.serpAbandon, this.sessionId, {
            query: getStringValue(query),
            serpBlockOffsetX: getNumberValue(getOffsetX(resultBlock)),
            serpBlockOffsetY: getNumberValue(getOffsetY(resultBlock)),
            resultWidth: getNumberValue(getWidth(resultItem)),
            resultHeight: getNumberValue(getHeight(resultItem)),
            resultBlockWidth: getNumberValue(getWidth(resultBlock)),
            resultBlockHeight: getNumberValue(getHeight(resultBlock)),
            resultCount: getNumberValue(resultCount),
            searchResultType: getStringValue(resultType),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
    };
    SS360Insights.prototype.trackFilterInteraction = function (query, filterBlock, filterData) {
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            clientWidth: getClientWidth(),
            targetOffsetX: getOffsetX(filterBlock),
            targetOffsetY: getOffsetY(filterBlock),
            filterBlockWidth: getWidth(filterBlock),
            filterBlockHeight: getHeight(filterBlock),
            referrer: getStringValue(window.location.href),
            filters: getStringValue(filterData)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.filterInteraction,
            type: InsightsRequestType.Post,
            data: data
        });
    };
    SS360Insights.prototype.setBaseUrl = function (baseUrl) {
        this.config.baseUrl = baseUrl;
    };
    SS360Insights.prototype.setAdditionalParams = function (opts) {
        this.additionalParameters = opts;
    };
    return SS360Insights;
}());
export default SS360Insights;
