'use strict';
import Logger from '../global/Logger';
import { querySelectorAllz } from './SxQueryObject';
import SxQueryShared from './SxQueryShared';
var parseHTML = SxQueryShared.parseHTML;
var createCookie = function (name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = '';
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/; SameSite=Strict";
};
var readCookie = function (name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
};
var extend = function (out) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var output = out || {};
    var _loop_1 = function (i) {
        var input = args[i];
        if (input) {
            Object.keys(input).forEach(function (key) {
                if (input[key] !== undefined) {
                    if (isObject(output[key]) && isObject(input[key])) {
                        output[key] = extend(output[key], input[key]);
                    }
                    else {
                        output[key] = input[key];
                    }
                }
            });
        }
    };
    for (var i = 0; i < args.length; i++) {
        _loop_1(i);
    }
    return output;
};
var isObject = function (el) { return el !== undefined && typeof el === 'object' && !(el instanceof Array); };
var each = function (data, callback) {
    if (callback && typeof callback === 'function') {
        if (data instanceof Array) { // array
            data.forEach(function (item, i) {
                callback(i, item, i);
            });
        }
        else { // object
            Object.keys(data).forEach(function (key, idx) {
                callback(key, data[key], idx);
            });
        }
    }
    return data;
};
var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
})(Method || (Method = {}));
var ajax = function (dataObject, retry, disableAdditionalHeaders) {
    if (retry === void 0) { retry = true; }
    if (disableAdditionalHeaders === void 0) { disableAdditionalHeaders = false; }
    dataObject = dataObject || { url: '' };
    var method = dataObject.method || 'GET';
    var dataType = dataObject.dataType;
    var url = dataObject.url;
    var success = dataObject.success || function () { };
    var error = dataObject.error || function () { };
    var forceXDR = 'XDomainRequest' in window;
    // eslint-disable-next-line no-undef
    // @ts-ignore
    var request = forceXDR ? new XDomainRequest() : new XMLHttpRequest();
    request.open(method, forceXDR ? url.replace('https://', '//') : url, true);
    request.onload = function () {
        if (forceXDR || (request.status >= 200 && request.status < 400)) {
            var response = request.responseText;
            if (request.status === 204) {
                success();
            }
            else if (!dataType || dataType === 'json') {
                var result = null;
                try {
                    result = JSON.parse(response);
                }
                catch (e) {
                    Logger.warn(e);
                    success(response);
                }
                if (result !== null) {
                    success(result);
                }
            }
        }
        else {
            error(request.status, request.responseText || request.statusText);
            // mmmmm...
        }
    };
    request.onerror = function () {
        if (retry && !disableAdditionalHeaders) {
            ajax(dataObject, false, true);
        }
        else {
            error(request.status, request.statusText);
        }
    };
    var send = function () {
        try {
            if (!disableAdditionalHeaders) { // some browsers do not understand the Access-Control-Allow-Headers: '*' flag
                var versionParts = SS360_VERSION.split('.');
                var major = versionParts.splice(0, 1)[0];
                var minor = versionParts.join('.');
                request.setRequestHeader('SEARCHINTEGRATION', 'JAVASCRIPT');
                request.setRequestHeader('SEARCHINTEGRATIONVER', major);
                request.setRequestHeader('EXTVER', minor);
            }
            if (method !== 'POST') {
                request.send();
            }
            else {
                var data_1 = '';
                each(dataObject.data, function (key, value) {
                    data_1 += key + "=" + value + "&";
                });
                if (data_1.length > 0) {
                    data_1 = data_1.substring(0, data_1.length - 1);
                }
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.send(data_1);
            }
        }
        catch (e) {
            error(request.status, request.statusText, e);
        }
    };
    if (forceXDR) {
        setTimeout(send, 0);
    }
    else {
        send();
    }
};
var querySelectorAll = querySelectorAllz;
var prefersReducedMotion = SxQueryShared.prefersReducedMotion;
var srOnlyCss = 'position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0';
var requestAnimationFrame = SxQueryShared._requestAnimation;
export default {
    each: each,
    indexInNodeList: function (el, arr) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === el) {
                return i;
            }
        }
        return -1;
    },
    createCookie: createCookie,
    readCookie: readCookie,
    linkOpensInNewTab: function (event) {
        return event.ctrlKey // CTRL
            || (event.which === 2 || event.button === 4) // middle mouse click
            || (event.target && event.target.target && event.target.target === '_blank'); // target="_blank"
    },
    matchesMediaQuery: function (type, breakpoint) {
        if (window.matchMedia) {
            var mQuery = type === 'max' ? "(max-width: " + breakpoint + "px)" : "(min-width: " + breakpoint + "px)";
            return window.matchMedia(mQuery).matches;
        }
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (type === 'max') {
            return w <= breakpoint;
        }
        return w >= breakpoint;
    },
    parseHTML: parseHTML,
    extend: extend,
    isObject: isObject,
    ajax: ajax,
    get: function (url, success, error, dataType, includeShopSysHeaders) {
        if (includeShopSysHeaders === void 0) { includeShopSysHeaders = false; }
        ajax({
            url: url,
            success: success,
            error: error,
            dataType: dataType
        }, true, !includeShopSysHeaders);
    },
    post: function (url, data, success, dataType, includeShopSysHeaders) {
        if (includeShopSysHeaders === void 0) { includeShopSysHeaders = false; }
        ajax({
            url: url,
            success: success,
            dataType: dataType,
            method: Method.POST,
            data: data
        }, true, !includeShopSysHeaders);
    },
    querySelectorAll: querySelectorAll,
    prefersReducedMotion: prefersReducedMotion,
    srOnlyCss: srOnlyCss,
    requestAnimationFrame: requestAnimationFrame,
    _storeObject: function (key, value) {
        try {
            var valueString = JSON.stringify(value);
            if ('localStorage' in window) {
                if (value !== null) {
                    window.localStorage.setItem(key, valueString);
                }
                else {
                    window.localStorage.removeItem(key);
                }
            }
            else {
                createCookie(key, valueString, 365);
            }
        }
        catch (err) {
            // ccl
        }
    },
    _readObject: function (key, defaultValue) {
        var valueString;
        if ('localStorage' in window) {
            try {
                valueString = window.localStorage.getItem(key);
            }
            catch (err) {
                return defaultValue; // access denied
            }
        }
        else {
            valueString = readCookie(key);
        }
        if (valueString === null || valueString === undefined) {
            return defaultValue;
        }
        try {
            return JSON.parse(valueString);
        }
        catch (err) {
            return defaultValue;
        }
    }
};
