'use strict';
import ResultGroup from './ResultGroup';
var SortingOrder;
(function (SortingOrder) {
    SortingOrder["ASC"] = "ASC";
    SortingOrder["DESC"] = "DESC";
})(SortingOrder || (SortingOrder = {}));
var SearchResponse = /** @class */ (function () {
    function SearchResponse(data) {
        this.plain = data;
        if (data.query !== undefined) {
            this.query = data.query;
        }
        else if (data.interpretedQuery !== undefined) {
            this.query = data.interpretedQuery.original;
        }
        if (data.suggests === undefined) { // product search fallbacks
            if (data.searchResults !== undefined) {
                data.suggests = data.searchResults.reduce(function (acc, entry) {
                    if ((entry.results || []).length !== 0) {
                        acc[entry.name] = entry.results;
                    }
                    return acc;
                }, {});
                if (data.totalResultsPerContentGroup === undefined) {
                    data.totalResultsPerContentGroup = data.searchResults.reduce(function (acc, entry) {
                        if (entry.totalResults !== undefined && entry.type !== 'all') {
                            acc[entry.name] = entry.totalResults;
                        }
                        return acc;
                    }, {});
                }
            }
        }
        // this can happen when a customer pins a custom result in the all results tab + toggles the pinned only setting --> otherwise we'd show an empty serp while a pinned result would be expected
        if (Object.keys(data.suggests || {}).length === 0 && data.searchResults !== undefined) {
            var allResultsPlacements = data.searchResults.filter(function (val) { return val.type === 'all'; })[0];
            if (allResultsPlacements !== undefined && allResultsPlacements.placements !== undefined) {
                data.suggests = {
                    _: allResultsPlacements.placements
                };
                if (data.totalResults === 0) {
                    data.totalResults = allResultsPlacements.placements.length;
                }
            }
        }
        this.setSuggests(data.suggests);
        this.searchResults = data.searchResults;
        this.activeFilterOptions = data.activeFilterOptions;
        this.filterOptions = data.filterOptions;
        this.groupedResultsAvailable = data.groupedResultsAvailable;
        this.totalResults = data.totalResults;
        this.sortingOptions = data.sortingOptions;
        this.sorting = data.sorting;
        this.activeSortingOption = data.activeSortingOption;
        this.filterNameMapping = data.filterNameMapping;
        this.redirect = data.redirect;
        this.plan = data.plan;
        this.attribution = data.attribution;
        this.queryCorrection = data.queryCorrection;
        this.queryCorrectionRewrite = data.queryCorrectionRewrite;
        this.filterMapping = data.filterMapping;
        this.totalResultsPerContentGroup = data.totalResultsPerContentGroup;
        this.sortingOrder = data.sortingOrder !== undefined ? data.sortingOrder : SortingOrder.DESC;
    }
    SearchResponse.prototype.setSuggests = function (suggests) {
        this.suggests = Object.keys(suggests || {}).map(function (key) { return new ResultGroup(key, suggests[key]); });
    };
    SearchResponse.prototype.getSuggestsArray = function (groupName) {
        var group = this.suggests.filter(function (g) { return g.name === groupName; })[0];
        return group !== undefined ? group.suggests : undefined;
    };
    SearchResponse.prototype.addResultGroup = function (groupName, suggestions, searchSuggests) {
        var group = new ResultGroup(groupName, suggestions, searchSuggests);
        this.suggests.push(group);
    };
    SearchResponse.prototype.getResultGroupNames = function () {
        return this.suggests.map(function (g) { return g.name; });
    };
    return SearchResponse;
}());
export default SearchResponse;
