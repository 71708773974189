'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import CallbackEnhanceType from '../enums/CallbackEnhanceType';
import TrackingAction from '../enums/TrackingAction';
var Helper = {
    getInitializationErrors: function (pluginConfig, is404) {
        var res = [];
        if (document.querySelectorAll('script[src*=sitesearch360-v]:not([type="module"])').length > 1 || document.querySelectorAll('script[src*=sitesearch360-v][type="module"]').length > 1) {
            res.push('There is more than one sitesearch360 script on this page. Please remove one.');
        }
        var searchBoxSelector = pluginConfig.searchBox.selector;
        if (!is404 && pluginConfig.results.layoverTrigger === undefined && sxQuery(searchBoxSelector).length === 0) {
            res.push("There is no input element for the searchBox.selector \"" + searchBoxSelector + "\". Please update your ss360Config object.");
        }
        return res;
    },
    enhanceCallback: function (callback, expected, enhanceType, context) {
        if (callback === undefined) {
            return expected;
        }
        if (enhanceType === CallbackEnhanceType.EnterResult || enhanceType === CallbackEnhanceType.Type) {
            return callback;
        }
        if (callback !== expected) {
            if (enhanceType === CallbackEnhanceType.Enter) {
                return function (text) {
                    context.core.logQuery(text, TrackingAction.Search);
                    try {
                        callback.call(this, text);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Focus) {
                return function (event, selectedText) {
                    context.core.focus(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Blur) {
                return function (event, selectedText) {
                    context.core.blur(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
        }
        return callback;
    },
    copyObject: function (obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    getTotalCount: function (data, limitPerGroup, pluginConfig) {
        if (data.groupedResultsAvailable !== undefined) {
            return data.groupedResultsAvailable;
        }
        var groupResults = pluginConfig.results.group;
        var _a = pluginConfig.contentGroups, ignoreOther = _a.ignoreOther, exclude = _a.exclude;
        var limit = pluginConfig.results.num;
        var totalResults = data.totalResults;
        var limitThroughInclude = pluginConfig.results.limitPerGroup && groupResults && (pluginConfig.contentGroups.include || []).length === 1;
        if (!limitPerGroup && groupResults && !limitThroughInclude) {
            totalResults = data.suggests.reduce(function (acc, resultGroup) {
                var key = resultGroup.name;
                if (key === '_' && ignoreOther) {
                    return acc;
                }
                return acc + resultGroup.suggests.length;
            }, 0);
        }
        else if (data.totalResultsPerContentGroup && Object.keys(data.totalResultsPerContentGroup).length > 0) {
            totalResults = Object.keys(data.totalResultsPerContentGroup).reduce(function (acc, key) {
                if ((key === '_' && ignoreOther && !limitThroughInclude) || (key !== '_' && exclude !== undefined && exclude.indexOf(key) !== -1)) {
                    return acc;
                }
                return acc + Math.min(limit, data.totalResultsPerContentGroup[key]);
            }, 0);
        }
        else if (!groupResults) {
            totalResults = Math.min(totalResults, limit);
        }
        return totalResults;
    },
    isNaN: function (number) {
        // eslint-disable-next-line no-restricted-properties
        var checker = ('isNaN' in window.Number && (typeof window.Number.isNaN === 'function')) ? window.Number.isNaN : window.isNaN;
        return checker(number);
    },
    colorToRgb: function (hexOrRgb) {
        try {
            hexOrRgb = hexOrRgb.trim();
        }
        catch (ex) {
            // ccl
        }
        if ((hexOrRgb.indexOf('rgba(') === 0 || hexOrRgb.indexOf('rgb(')) && hexOrRgb.indexOf(')') === hexOrRgb.length - 1) {
            var rgbaParts = hexOrRgb.substring(0, hexOrRgb.length - 1).replace(/rgba?\(/, '').split(',').map(function (part) { return part.trim(); });
            if (rgbaParts.length >= 3) {
                return {
                    r: parseInt(rgbaParts[0], 10),
                    g: parseInt(rgbaParts[1], 10),
                    b: parseInt(rgbaParts[2], 10)
                };
            }
        }
        if (hexOrRgb.length === 4) {
            hexOrRgb = "#" + Array(3).join(hexOrRgb[1]) + Array(3).join(hexOrRgb[2]) + Array(3).join(hexOrRgb[3]);
        }
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexOrRgb);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
};
export default Helper;
