'use strict';
import sxQuery from '../../sxQuery/sxQuery';
var InfiniteScrollObserver = /** @class */ (function () {
    function InfiniteScrollObserver(callback) {
        this.callback = callback;
    }
    InfiniteScrollObserver.prototype.observe = function (group, layerId) {
        var _this = this;
        var results = group.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-masonry-break)');
        if (results.length === 0) { // nothing to observe
            return;
        }
        // observe the last visible result
        var observedEntry = results.get()[results.length - 1];
        if ('IntersectionObserver' in window) {
            var observer_1 = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        observer_1.disconnect();
                        _this.callback();
                    }
                });
            }, {
                rootMargin: '250px'
            });
            observer_1.observe(observedEntry);
        }
        else {
            var active_1 = false;
            var $document_1 = sxQuery(document);
            var $window_1 = sxQuery(window);
            var $overlay_1 = sxQuery("#" + layerId + ".ss360-layer--overlay .ss360-layer__content");
            var eventId_1 = "ss360InifniteScroll_" + Math.round(Math.random() * 1000); // to support multiple observers
            var scrollHandler_1 = function () {
                if (active_1 === false) {
                    active_1 = true;
                    setTimeout(function () {
                        if ((observedEntry.getBoundingClientRect().top <= (window.innerHeight + 150) && observedEntry.getBoundingClientRect().bottom >= 0) && getComputedStyle(observedEntry).display !== 'none') {
                            $overlay_1.off("scroll." + eventId_1, scrollHandler_1);
                            $document_1.off("scroll." + eventId_1, scrollHandler_1);
                            $window_1.off("scroll." + eventId_1, scrollHandler_1);
                            $window_1.off("scroll." + eventId_1, scrollHandler_1);
                            _this.callback();
                        }
                        active_1 = false;
                    }, 200);
                }
            };
            $overlay_1.on("scroll." + eventId_1, scrollHandler_1);
            $document_1.on("scroll." + eventId_1, scrollHandler_1);
            $window_1.on("scroll." + eventId_1, scrollHandler_1);
            $window_1.on("scroll." + eventId_1, scrollHandler_1);
            scrollHandler_1();
        }
    };
    return InfiniteScrollObserver;
}());
export default InfiniteScrollObserver;
