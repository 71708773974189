'use strict';
var SearchSuggest = /** @class */ (function () {
    function SearchSuggest(data) {
        this.variants = [];
        this.plain = data;
        try {
            if (data instanceof Array) {
                this.variants = JSON.parse(JSON.stringify(data));
                this.suggest = this.variants.splice(0, 1)[0];
                this.suggest.variants = this.variants;
            }
            else {
                this.suggest = data;
                this.suggest.variants = [];
            }
            this.pinned = this.suggest.pinned;
            this.relevance = this.suggest.relevance;
        }
        catch (ex) {
            // ccl
        }
    }
    SearchSuggest.prototype.setOriginalContentGroup = function (group) {
        this.suggest._originalGroup = group;
    };
    SearchSuggest.prototype.getOriginalContentGroup = function () {
        return this.suggest._originalGroup;
    };
    SearchSuggest.prototype.getLink = function (highlightMatchedContent, query) {
        if (highlightMatchedContent === void 0) { highlightMatchedContent = false; }
        var link = this.suggest.link;
        if (highlightMatchedContent && link.indexOf('ss360SearchTerm=') === -1) {
            var linkParts = link.split('#');
            var linkBase = linkParts[0];
            var linkHash = linkParts.length === 2 ? "#" + linkParts[1] : '';
            var paramChar = linkBase.indexOf('?') !== -1 ? '&' : '?';
            link = "" + linkBase + paramChar + "ss360SearchTerm=" + encodeURIComponent(query) + linkHash;
        }
        return link;
    };
    SearchSuggest.prototype.getName = function () {
        return this.suggest.name;
    };
    SearchSuggest.prototype.getType = function () {
        return this.suggest.type;
    };
    SearchSuggest.prototype.getHtml = function () {
        return this.suggest.html;
    };
    SearchSuggest.prototype.getImage = function (optimized) {
        if (optimized === void 0) { optimized = false; }
        if (optimized) {
            var dataPoints = this.getDataPoints();
            for (var i = 0; i < dataPoints.length; i++) {
                var dp = dataPoints[i];
                if (dp.key === 'resultImageUrl' && dp.value !== undefined && dp.value !== null && dp.value.length > 0) {
                    return dp.value;
                }
            }
        }
        return this.suggest.image;
    };
    SearchSuggest.prototype.getOriginalImage = function () {
        return this.suggest.originalImage || '';
    };
    SearchSuggest.prototype.getDataPoints = function () {
        return this.suggest.dataPoints || [];
    };
    SearchSuggest.prototype.hasImage = function () {
        var image = this.suggest.image;
        return image !== undefined && image !== null && image.length > 0;
    };
    SearchSuggest.prototype.setImage = function (image) {
        this.suggest.originalImage = this.suggest.image;
        this.suggest.image = image;
    };
    SearchSuggest.prototype.setPlaceholderImage = function (image) {
        if (this.hasImage() || image === undefined || image === null || image.length === 0)
            return;
        this.suggest.image = image;
    };
    SearchSuggest.prototype.getContent = function () {
        return this.suggest.content;
    };
    SearchSuggest.prototype.getId = function () {
        return this.suggest.identifier !== undefined ? this.suggest.identifier : this.suggest.link;
    };
    SearchSuggest.prototype.getIdentifier = function () {
        return this.suggest.identifier;
    };
    return SearchSuggest;
}());
export default SearchSuggest;
