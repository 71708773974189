'use strict';
import ConfigurationHelper from '../configuration/ConfigurationHelper';
var SubConfigApplier = {
    init: function (context) {
        var config = context.pluginConfiguration;
        var defaults = context.configDefaults;
        Object.keys(config.subConfigs).forEach(function (subConfigName) {
            var subConfig = config.subConfigs[subConfigName];
            Object.keys(subConfig).forEach(function (key) {
                if (defaults[key] === undefined) {
                    defaults[key] = ConfigurationHelper.getPart(config, key);
                }
            });
        });
    },
    apply: function (context) {
        var config = context.pluginConfiguration;
        var activeId = config.activeSubConfigId;
        if (activeId === undefined && context.areConfigDefaults)
            return;
        var defaults = context.configDefaults;
        if (!context.areConfigDefaults) { // reset defaults first, not all settings have to be overriden
            Object.keys(defaults).forEach(function (key) {
                context.core.changeConfig(key, defaults[key]);
            });
        }
        context.areConfigDefaults = activeId === undefined;
        var subConfig = config.subConfigs[activeId];
        if (subConfig === undefined)
            return;
        Object.keys(subConfig).forEach(function (key) {
            context.core.changeConfig(key, subConfig[key]);
        });
    }
};
export default SubConfigApplier;
