'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Handlers from './Handlers';
import Logger from '../../global/Logger';
import StyleApplier from '../components/StyleApplier';
import HiddenState from '../enums/HiddenState';
import SearchResultType from '../enums/SearchResultType';
import Icons from '../components/Icons';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
var Ss360DocIcons = ['pdf.svg', 'xls.svg', 'javascript.svg', 'odp.svg', 'ods.svg', 'odt.svg', 'ppt.svg', 'doc.svg'];
var UiHelper = {
    showLoadingAnimation: function (context) {
        context.getExistingLayer().find('.ss360-loader').fadeIn();
    },
    hideLoadingAnimation: function (context) {
        context.getExistingLayer().find('.ss360-loader').fadeOut();
    },
    showError: function (message) {
        sxQuery('.ss360DevError').remove();
        var errorMessage = sxQuery("<div class=\"ss360DevError\" style=\"padding:10px;width:100%;position:fixed;bottom:0;left:0;background-color:#C1063F;color:white;\"><b>Site Search 360 Error:</b> \n\t\t\t" + message + "</div>");
        sxQuery('body').append(errorMessage);
    },
    showFullscreenLayer: function (config, console) {
        var results = config.results, searchBox = config.searchBox, style = config.style;
        if ((results.fullScreenConfig || {}).transition === 'fade') {
            console.fadeIn(style.animationSpeed);
            StyleApplier.apply(SearchResultType.Fullscreen, config.searchBox, config.style);
        }
        else {
            console.animateTop('0%', style.animationSpeed);
        }
        sxQuery(searchBox.selector).focus();
        sxQuery('#ss360CloseLayerButton').css('position', 'fixed');
        if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 968)) {
            sxQuery('#ss360CloseLayerButton').css('right', '20px');
        }
        console.addClass('ss360-fullscreen-layer--open');
        sxQuery('body').css('overflow', 'hidden');
    },
    hideFullscreenLayer: function (config, $console) {
        var results = config.results, style = config.style;
        var hide = function () {
            $console.removeClass('ss360-fullscreen-layer--open');
            sxQuery('#ss360CloseLayerButton').css('position', 'absolute');
            sxQuery('#ss360CloseLayerButton').css('right', '8px');
            sxQuery('body').css('overflow', 'initial');
        };
        if ((results.fullScreenConfig || {}).transition === 'fade') {
            $console.fadeOut(style.animationSpeed, hide);
        }
        else {
            $console.animateTop('-100%', style.animationSpeed);
            hide();
        }
    },
    addDarkenInputLayer: function (selectedSearchBox) {
        UiHelper.removeDarkenInputLayer();
        var darkBg = sxQuery('<div id="ss360Darken-input" class="ss360-darken"></div>');
        selectedSearchBox.parent().css('z-index', '999999');
        selectedSearchBox.parent().parent().append(darkBg);
    },
    removeDarkenInputLayer: function () {
        sxQuery('#ss360Darken-input').remove();
    },
    isDocIcon: function (imagePath) {
        return imagePath !== undefined && imagePath !== null ? Ss360DocIcons.indexOf(imagePath.replace('https://sitesearch360.com/cdn/', '').replace('https://cdn.sitesearch360.com/', '')) !== -1 : false;
    },
    getHiddenState: function (mobile, desktop) {
        if (!mobile && !desktop) {
            return HiddenState.All;
        }
        if (!mobile) {
            return HiddenState.Mobile;
        }
        if (!desktop) {
            return HiddenState.Desktop;
        }
        return HiddenState.None;
    },
    getHiddenParts: function (layoutConfiguration) {
        var mobile = layoutConfiguration.mobile, desktop = layoutConfiguration.desktop;
        return {
            title: UiHelper.getHiddenState(mobile.showTitle, desktop.showTitle),
            url: UiHelper.getHiddenState(mobile.showUrl, desktop.showUrl),
            images: UiHelper.getHiddenState(mobile.showImages, desktop.showImages),
            snippet: UiHelper.getHiddenState(mobile.showSnippet, desktop.showSnippet),
            dataPoints: UiHelper.getHiddenState(mobile.showDataPoints, desktop.showDataPoints)
        };
    },
    updateLayerByHiddenParts: function (hiddenParts, layerContent) {
        if (layerContent === undefined) {
            return;
        }
        var toggle = function (key, value) {
            if (value === 'all') {
                return;
            }
            if (value === 'mobile') {
                layerContent.addClass("ss360-hide-" + key + "--sm");
            }
            if (value === 'desktop') {
                layerContent.addClass("ss360-hide-" + key + "--lg");
            }
        };
        toggle('title', hiddenParts.title);
        toggle('url', hiddenParts.url);
        toggle('images', hiddenParts.images);
        toggle('snippet', hiddenParts.snippet);
        toggle('dataPoints', hiddenParts.dataPoints);
    },
    getLogoSrc: function (allowCookies) {
        return allowCookies ? 'https://cdn.sitesearch360.com/ss360-logo.svg' : 'https://nc.sitesearch360.com/ss360-logo.svg';
    },
    highlightQueryTermsInResult: function (query, context, highlightContext) {
        if (highlightContext === void 0) { highlightContext = '.ss360-suggests__content > p, .ss360-suggests header a'; }
        var parts = query.split(' ');
        for (var p = 0; p < parts.length; p++) {
            try {
                context.find(highlightContext).highlight(parts[p], 'ss360-highlight');
            }
            catch (e) {
                Logger.warn(e);
            }
        }
    },
    createLayerContent: function (resultType, layout, headingId) {
        var layoutMobile = layout.mobile;
        var layoutDesktop = layout.desktop;
        var layerContent = sxQuery("<section class=\"ss360-layer__content ss360-n-section\" aria-labelledby=\"" + headingId + "\" \n\t\t\t" + (resultType === SearchResultType.Layover ? 'tabindex="-1" style="overflow-x:auto;overflow-y:auto;max-height:calc(100%-25px)"' : '') + ">");
        if (layoutDesktop.type !== 'grid') {
            layerContent.addClass("ss360-" + layoutDesktop.type + "--lg");
        }
        if (layoutMobile.type !== 'grid') {
            layerContent.addClass("ss360-" + layoutMobile.type + "--sm");
        }
        return layerContent;
    },
    prepareLayoverLayer: function (layer, closeLayerHandler, pluginConfig) {
        var results = pluginConfig.results, filters = pluginConfig.filters, layout = pluginConfig.layout;
        if (!layer.hasClass('ss360-layer--overlay')) {
            layer.addClass('ss360-layer--overlay');
        }
        var isWideOverlay = layout.navigation.position === 'left'
            || (filters.enabled && filters.position === 'left') || layout.desktop.type === 'grid';
        if (isWideOverlay) {
            layer.addClass('ss360-layer--wide-overlay');
        }
        else {
            layer.removeClass('ss360-layer--wide-overlay');
        }
        if (!layer.isVisible()) {
            layer.show();
            layer.addClass('ss360-animated ss360-us');
            Handlers.layoverResize(layer);
            // prevent scrolling the whole when search results are focused by keyboard
            layer.off('scroll.ss360Scroll');
            layer.on('scroll.ss360Scroll', undefined, Handlers.layoverScroll);
        }
        layer.scrollTop(0);
        // darken background
        if (sxQuery('#ss360Darken').length === 0) {
            var darkBg = sxQuery("<div id=\"ss360Darken\" class=\"ss360-darken ss360-animated ss360-fid" + (results.hideLayerOnBodyClick ? '' : ' ss360-darken--noclose') + "\"></div>");
            sxQuery('body').append(darkBg);
        }
        // add close layer button
        if (layer.find('#ss360CloseLayerButton').length === 0) {
            UiHelper.prependCloseButton(layer);
            // attach event handler
            sxQuery('#ss360CloseLayerButton').click(closeLayerHandler);
        }
        sxQuery('body').addClass('ss360-u-hide-y-overflow');
        var $window = sxQuery(window);
        $window.off('resize.ss360Resize');
        $window.on('resize.ss360Resize', function () {
            Handlers.layoverResize(layer);
        });
        Handlers.layoverResize(layer);
        setTimeout(function () {
            Handlers.layoverResize(layer);
        }, 100);
        setTimeout(function () {
            Handlers.layoverResize(layer);
        }, 1000);
    },
    hasVisibleContent: function (suggest, hiddenParts) {
        if (suggest instanceof Array) {
            suggest = suggest[0];
        }
        if (suggest.getType() === 'custom')
            return true;
        var name = suggest.getName();
        var image = suggest.getImage();
        var dataPoints = suggest.getDataPoints();
        var link = suggest.getLink();
        var content = suggest.getContent();
        var hasTitle = hiddenParts.title !== 'all' && name !== undefined && name.length > 0;
        if (hasTitle)
            return true;
        var hasSnippet = hiddenParts.snippet !== 'all' && content !== undefined && content.length > 0;
        if (hasSnippet)
            return true;
        var hasImage = hiddenParts.images !== 'all' && image !== null && image !== undefined && image.length > 0;
        if (hasImage)
            return true;
        var hasDataPoints = hiddenParts.dataPoints !== 'all' && (dataPoints || []).reduce(function (acc, dp) { return acc || dp.show; }, false);
        if (hasDataPoints)
            return true;
        var hasUrl = hiddenParts.url !== 'all' && link !== null && link !== undefined && link.length > 0;
        return hasUrl;
    },
    createCloseLayerButton: function (id, title) {
        return sxQuery("<button id=\"" + id + "\" aria-label=\"" + title + "\" title=\"" + title + "\" type=\"button\" class=\"ss360-close-button ss360-n-button\">\n\t\t\t<i aria-hidden=\"true\" role=\"presentation\" class=\"ss360-close-button__icon\">" + Icons.getSvgIcon(Icons.CROSS, '#626262', 'ss360-close-button__icon-svg') + "</i>\n\t\t\t<i aria-hidden=\"true\" role=\"presentation\" class=\"ss360-close-button__icon ss360-close-button__icon--active\">" + Icons.getSvgIcon(Icons.CROSS, '#4a4f62', 'ss360-close-button__icon-svg') + "</i>\n\t\t\t</button>");
    },
    getSearchButtonIconColor: function (pluginConfig) {
        var hasLabel = pluginConfig.searchBox.searchButtonLabel !== undefined;
        var searchButtonIconColor = '#fefefe';
        if (!hasLabel && pluginConfig.style.searchBox !== undefined) { // we need to check if the background was modified, otherwise we could end up with white on white
            var searchBoxStyle = pluginConfig.style.searchBox;
            if (searchBoxStyle.background !== undefined && searchBoxStyle.background.color !== undefined) {
                if (searchBoxStyle.button !== undefined && searchBoxStyle.button.textColor !== undefined) {
                    searchButtonIconColor = searchBoxStyle.button.textColor;
                }
                else if (searchBoxStyle.text !== undefined && searchBoxStyle.text.color !== undefined) {
                    searchButtonIconColor = searchBoxStyle.text.color;
                }
            }
        }
        return searchButtonIconColor;
    },
    getGridClassName: function (layoutConfig) {
        var gridClasses = [];
        var mobileType = layoutConfig.mobile.type;
        var desktopType = layoutConfig.desktop.type;
        var isGridMobile = mobileType === 'grid';
        var isGridDesktop = desktopType === 'grid';
        if (isGridMobile || isGridDesktop) {
            gridClasses.push('ss360-grid');
            if (isGridMobile) {
                gridClasses.push('ss360-grid--sm');
            }
            if (isGridDesktop) {
                gridClasses.push('ss360-grid--lg');
            }
            if (layoutConfig.singleLineGridTitle) {
                gridClasses.push('ss360-grid--singleline-title');
            }
        }
        var isMasonryMobile = mobileType === 'masonry';
        var isMasonryDesktop = desktopType === 'masonry';
        if (isMasonryDesktop || isMasonryMobile) {
            gridClasses.push('ss360-masonry');
            if (isMasonryDesktop) {
                gridClasses.push('ss360-masonry--lg');
            }
            if (isMasonryMobile) {
                gridClasses.push('ss360-masonry--sm');
            }
        }
        return gridClasses.length === 0 ? '' : " " + gridClasses.join(' ');
    },
    prependCloseButton: function (target) {
        target.prepend(UiHelper.createCloseLayerButton('ss360CloseLayerButton', 'Close Search Results'));
    },
    getResultCount: function (key, values, showAllResultsTab, searchResultConfig, data, totalResults) {
        var limit = searchResultConfig.num;
        if (showAllResultsTab && key === '*') {
            var keyset = data.getResultGroupNames().filter(function (key) { return key !== '*'; });
            return keyset.reduce(function (acc, groupName) { return acc + UiHelper.getResultCount(groupName, data.getSuggestsArray(groupName), showAllResultsTab, searchResultConfig, data, totalResults); }, 0);
        }
        var resultsAvailable = values.length;
        if (data.totalResultsPerContentGroup !== undefined && data.totalResultsPerContentGroup[key] !== undefined) {
            resultsAvailable = data.totalResultsPerContentGroup[key];
        }
        if (!searchResultConfig.group || Object.keys(data.plain.suggests).length === 1) {
            resultsAvailable = totalResults;
        }
        if (resultsAvailable === 0 && values.length > 0) {
            resultsAvailable = values.length;
        }
        resultsAvailable = resultsAvailable || totalResults;
        return Math.min(resultsAvailable, limit);
    }
};
export default UiHelper;
