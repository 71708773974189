'use strict';
import SearchSuggest from '../model/SearchSuggest';
import Helper from './Helper';
// TODO: should get a major cleanup
export default {
    buildAllResultsTab: function (data) {
        // check placements
        var allResultsPlacements = (((data.searchResults || []).filter(function (group) { return group.type === 'all' && group.placements !== undefined; }))[0] || {}).placements || [];
        var allResultPlacementMap = {};
        // @ts-ignore
        var allResultPlacementIds = allResultsPlacements.reduce(function (acc, placement) {
            if (placement.id !== undefined) {
                acc[placement.id] = placement.position;
            }
            return acc;
        }, {});
        var hasAllResultsPlacementIds = Object.keys(allResultPlacementIds).length > 0;
        var hasAllResultPlacements = allResultsPlacements.length > 0;
        // build all results tab
        var groupNames = data.getResultGroupNames();
        var allResultsTab = groupNames.reduce(function (acc, group) {
            var groupSuggests = data.getSuggestsArray(group);
            if (hasAllResultsPlacementIds) {
                groupSuggests = groupSuggests.filter(function (suggest) {
                    var firstSuggest = suggest instanceof Array ? suggest[0] : suggest;
                    var id = firstSuggest.getId();
                    if (allResultPlacementIds[id] !== undefined) {
                        allResultPlacementMap[id] = suggest;
                        return false;
                    }
                    return true;
                });
            }
            groupSuggests.forEach(function (suggest) {
                suggest.setOriginalContentGroup(group);
            });
            acc = acc.concat(groupSuggests);
            return acc;
        }, []).sort(function (a, b) {
            if (a instanceof Array) { // product search
                a = a[0];
            }
            if (b instanceof Array) { // product search
                b = b[0];
            }
            if (a.pinned && b.pinned && !hasAllResultPlacements) {
                return 0;
            }
            if (a.pinned && !hasAllResultPlacements) {
                return -1;
            }
            if (b.pinned && !hasAllResultPlacements) {
                return 1;
            }
            var aVal = a.relevance;
            var bVal = b.relevance;
            var isSorting = false;
            if (data.sorting !== undefined && data.sorting !== '') {
                aVal = parseFloat(((a.getDataPoints() || []).filter(function (dp) { return dp.key === data.sorting; })[0] || {}).value);
                bVal = parseFloat(((b.getDataPoints() || []).filter(function (dp) { return dp.key === data.sorting; })[0] || {}).value);
                isSorting = true;
            }
            if (aVal === undefined && bVal === undefined) {
                return 0;
            }
            if (isSorting && Helper.isNaN(aVal) && Helper.isNaN(bVal)) {
                return 0;
            }
            if (aVal === undefined || (isSorting && Helper.isNaN(aVal))) {
                return 1;
            }
            if (bVal === undefined || (isSorting && Helper.isNaN(bVal))) {
                return -1;
            }
            if (isSorting && data.sortingOrder === 'ASC') {
                return aVal - bVal;
            }
            return bVal - aVal; // descending (relevance or DESC sorting)
        });
        // inject placements
        if (allResultsPlacements.length > 0 && allResultsTab !== undefined) {
            // @ts-ignore
            var placements_1 = allResultsPlacements.reduce(function (acc, placement) {
                if (placement.id !== undefined && allResultPlacementMap[placement.id] !== undefined) {
                    acc[placement.position] = allResultPlacementMap[placement.id];
                }
                else {
                    acc[placement.position] = new SearchSuggest(placement);
                }
                return acc;
            }, {});
            Object.keys(placements_1).sort(function (a, b) { return a - b; }).forEach(function (position) {
                allResultsTab.splice(position, 0, placements_1[position]);
            });
        }
        data.addResultGroup('*', undefined, allResultsTab);
    }
};
