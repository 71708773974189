'use strict';
var Plan;
(function (Plan) {
    Plan["Free"] = "FREE";
    Plan["Columbo"] = "COLUMBO";
    Plan["Columbo2"] = "COLUMBO2";
    Plan["DudaFree"] = "DUDA_FREE";
    Plan["FirespringFree"] = "FIRESPRING_FREE";
    Plan["FirespringColumbo"] = "FIRESPRING_COLUMBO";
    Plan["FilrespringHolmes"] = "FIRESPRING_HOLMES";
    Plan["Custom"] = "CUSTOM";
})(Plan || (Plan = {}));
export default Plan;
