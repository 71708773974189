/* eslint-disable max-len */
'use strict';
var BASE_64_PREFIX = 'data:image/svg+xml;base64,';
var getSvgIcon = function (path, fill, className, width, height) {
    if (className === void 0) { className = ''; }
    if (width === void 0) { width = 24; }
    if (height === void 0) { height = 24; }
    // eslint-disable-next-line implicit-arrow-linebreak
    return "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"" + fill + "\" width=\"" + width + "\" height=\"" + height + "\" class=\"" + className + "\" viewBox=\"0 0 24 24\"><path d=\"" + path + "\"/></svg>";
};
var getBase64EncodedIcon = function (path, fill, className) {
    if (className === void 0) { className = ''; }
    return BASE_64_PREFIX + btoa(getSvgIcon(path, fill, className));
};
var getSvgIconNode = function (path, fill) {
    var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttributeNS(null, 'width', '24');
    svg.setAttributeNS(null, 'height', '24');
    svg.setAttributeNS(null, 'viewBox', '0 0 24 24');
    svg.setAttributeNS(null, 'fill', fill);
    var pathNode = document.createElementNS(svg.namespaceURI, 'path');
    pathNode.setAttributeNS(null, 'd', path);
    svg.appendChild(pathNode);
    return svg;
};
var Icons = {
    MAGNIFIER: 'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
    MICROPHONE: 'M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z',
    SHOPPING_CART: 'M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z',
    TRIANGLE_ARROW: 'M8 5v14l11-7z',
    SIMPLE_ARROW: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
    CROSS: 'M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z',
    FILLED_CROSS: 'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z',
    getSvgIcon: getSvgIcon,
    getBase64EncodedIcon: getBase64EncodedIcon,
    getSvgIconNode: getSvgIconNode
};
export default Icons;
