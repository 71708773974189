'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
var FlowCache = {};
var load = function (url, callback) {
    if (callback === void 0) { callback = function () { }; }
    if (FlowCache[url] !== undefined) {
        callback(FlowCache[url]);
        return;
    }
    SxQueryUtils.get(url, function (data) {
        FlowCache[url] = data;
        callback(data);
    }, function (err) {
        Logger.error(err);
        FlowCache[url] = null;
    });
};
var render = function (context, data, target) {
    if (data === null) {
        return;
    }
    var questions = data.questions;
    if (questions === undefined || questions.length === 0) {
        return;
    }
    var wrapper = sxQuery('<section class="ss360-product-finder ss360-n-section"></section>');
    var questionsWrapper = sxQuery('<div class="ss360-product-finder__questions"></div>');
    var controls = sxQuery('<div class="ss360-product-finder__controls"></div>');
    var backButton = sxQuery('<button class="ss360-product-finder__button ss360-product-finder__button--prev ss360-n-button">Back</button>');
    var nextButton = sxQuery('<button class="ss360-product-finder__button ss360-product-finder__button--next ss360-n-button ss360-tc-b ss360-tc-c">Continue</button>');
    var finishButton = sxQuery('<button class="ss360-product-finder__button ss360-product-finder__button--finish ss360-n-button ss360-ac-b ss360-ac-c">Finish</button>');
    var activePage = 0;
    var selectedAnswers = {};
    var onPageChanged = function () {
        backButton.attr('disabled', activePage === 0 ? 'disabled' : null);
        if (activePage !== questions.length - 1) {
            nextButton.show();
            finishButton.hide();
        }
        else {
            finishButton.show();
            nextButton.hide();
        }
        questionsWrapper.find('.ss360-product-finder__question').addClass('ss360-product-finder__question--hidden');
        questionsWrapper.find(".ss360-product-finder__question--" + activePage).removeClass('ss360-product-finder__question--hidden');
        // lazyload
        wrapper.find(".ss360-product-finder__question--" + (activePage + 1) + " img:not([src])").each(function (img) {
            var $img = sxQuery(img);
            // @ts-ignore
            $img.attr('src', $img.data('src'));
        });
    };
    finishButton.hide();
    backButton.on('click', function () { activePage--; onPageChanged(); });
    nextButton.on('click', function () {
        activePage++;
        onPageChanged();
    });
    controls.append(backButton);
    controls.append(nextButton);
    controls.append(finishButton);
    finishButton.on('click', function () {
        wrapper.fadeOut();
        // @ts-ignore
        var filters = Object.keys(selectedAnswers).reduce(function (acc, idx) {
            selectedAnswers[idx].filters.forEach(function (f) {
                if (f.type !== 'NUMERIC_ATTRIBUTE') {
                    acc.push(__assign(__assign({}, f), { name: f.attributeName, key: f.attributeId }));
                }
                else {
                    acc.push({
                        attributeName: f.attributeName,
                        attributeId: f.attributeId,
                        key: f.attributeId,
                        min: parseFloat(f.minDefinition),
                        max: parseFloat(f.maxDefinition),
                        name: f.attributeName
                    });
                }
            });
            return acc;
        }, []);
        context.core.showResults('*', {
            filters: filters
        });
    });
    questions.forEach(function (question, idx) {
        var questionWrapper = sxQuery("<div class=\"ss360-product-finder__question ss360-product-finder__question--" + idx + "\"></div>");
        questionWrapper.append("<span role=\"heading\" aria-level=\"2\" class=\"ss360-product-finder__question-title\">" + question.question + "</span>");
        var answerWrapper = sxQuery('<div class="ss360-product-finder__answers"></div>');
        var answers = question.answers;
        answers.forEach(function (answer) {
            var singleAnswer = sxQuery('<div class="ss360-product-finder__answer" role="button" tabindex="0"></div>');
            if (answer.picture !== undefined && answer.picture.length > 0) {
                singleAnswer.append("<img class=\"ss360-product-finder__answer-image\" " + (idx <= 1 ? '' : 'data-') + "src=\"" + answer.picture + "\" alt=\"" + answer.text + "\">");
            }
            singleAnswer.append("<div class=\"ss360-product-finder__answer-text\"><span>" + answer.text + "</span></div>");
            var onSelect = function () {
                selectedAnswers[idx] = answer;
                answerWrapper.find('.ss360-product-finder__answer').removeClass('ss360-ac-b');
                singleAnswer.addClass('ss360-ac-b');
            };
            singleAnswer.on('click', onSelect);
            singleAnswer.on('keydown', function (e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13 || keyCode === 32) {
                    onSelect();
                }
            });
            answerWrapper.append(singleAnswer);
        });
        questionWrapper.append(answerWrapper);
        questionsWrapper.append(questionWrapper);
    });
    onPageChanged();
    wrapper.append(questionsWrapper);
    wrapper.append(controls);
    target.append(wrapper);
};
export default {
    init: function (context, config) {
        if (config.source === undefined || config.target === undefined) {
            return;
        }
        var target = config.target, source = config.source;
        if (target.type === 'dom' && sxQuery(target.value).length > 0) {
            load(source, function (data) {
                render(context, data, sxQuery(target.value));
            });
        }
        else {
            load(source);
        }
    },
    loadAndRender: function (wrapper, config, context, callback) {
        if (callback === void 0) { callback = function () { }; }
        load(config.source, function (data) {
            // FIXME:
            render(context, data, wrapper);
            callback();
        });
    },
    exists: function (config) {
        var _a;
        if (FlowCache[config.source] === undefined) { // flow not loaded yet, let's asume it does exist
            return true;
        }
        return FlowCache[config.source] !== null && ((_a = FlowCache[config.source].questions) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
};
