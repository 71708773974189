'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from '../ui/UiHelper';
import Helper from '../utils/Helper';
var ResizedImageSettings = /** @class */ (function () {
    function ResizedImageSettings(layerId) {
        this.isInitialized = false;
        this.layerId = layerId;
    }
    ResizedImageSettings.prototype.init = function (layoutConfiguration) {
        if (this.isInitialized)
            return;
        var dummyGroup = sxQuery("<section class=\"ss360-n-section ss360-group\" style=\"visibility:hidden;position:absolute\">\n\t\t<ul class=\"ss360-list" + UiHelper.getGridClassName(layoutConfiguration) + "\"><li class=\"ss360-suggests\"><article class=\"ss360-n-section\"><div class=\"ss360-suggests__content\">\n\t\t<a class=\"ss360-suggests__image-wrap\"><img class=\"ss360-suggests__image\"></a></div></article></li></ul></section>");
        sxQuery("#" + this.layerId + " .ss360-layer__content").append(dummyGroup);
        var img = dummyGroup.find('img');
        var maxWidthString = img.css('maxWidth');
        var isRelativeMaxWidth = (maxWidthString || '').length > 0 && maxWidthString.indexOf('%') === maxWidthString.length - 1;
        var maxWidth = isRelativeMaxWidth ? NaN : parseInt(maxWidthString, 10); // if relative, we consider this to match the parents width, don't make crazy calculations with trying to compute parents width for now
        var maxHeight = parseInt(img.css('maxHeight'), 10);
        var height = img.height();
        var width = img.width();
        this.setWidth = Helper.isNaN(width);
        this.setHeight = Helper.isNaN(height);
        this.maxWidth = Helper.isNaN(maxWidth) ? undefined : maxWidth;
        this.maxHeight = Helper.isNaN(maxHeight) ? undefined : maxHeight;
        this.isInitialized = true;
        dummyGroup.remove();
    };
    return ResizedImageSettings;
}());
export default ResizedImageSettings;
