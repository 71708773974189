'use strict';
import StringHelper from '../../global/StringHelper';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from './UiHelper';
import Icons from '../components/Icons';
import SuggestType from '../enums/SuggestType';
var renderTitle = function (suggest, link, hiddenParts, headingId, linksOpenNewTab, headingLevel) {
    var cleanName = suggest.getName();
    if (cleanName.indexOf(' ') !== -1) { // &nbsp;
        cleanName = cleanName.split(' ').join(' ');
    }
    if (hiddenParts.title !== 'all') {
        var hrefAttr = link !== undefined && link !== null && link.length > 0 ? " href=\"" + link + "\"" : '';
        return "<header class=\"ss360-n-section ss360-suggests__header\"><span role=\"heading\" id=\"" + headingId + "\" class=\"ss360-suggests__link-wrap\" title=\"" + cleanName.replace(/"/g, '&quot;') + "\"\n\t\t\t\taria-level=\"" + headingLevel + "\"><a class=\"ss360-suggests__link ss360-ac-c\"" + hrefAttr + (linksOpenNewTab ? ' target="_blank"' : '') + ">" + cleanName + "</a></span></header>";
    }
    return '';
};
var renderLink = function (suggest, stripHttp, hiddenParts, fullLink, linksOpenNewTab) {
    if (hiddenParts.url !== 'all') {
        var link = decodeURI(suggest.getLink()); // get from suggest to ignore appended query params
        if (stripHttp) {
            if (link.toLowerCase().indexOf('https://') === 0) {
                link = link.substring(8);
            }
            else if (link.toLowerCase().indexOf('http://') === 0) {
                link = link.substring(7);
            }
        }
        return "<a tabindex=\"-1\" href=\"" + fullLink + "\" class=\"ss360-suggests__url\"" + (linksOpenNewTab ? ' target="_blank"' : '') + " aria-hidden=\"true\">" + link + "</a>";
    }
    return '';
};
var renderImage = function (suggest, hiddenParts, resultSettings, layoutSettings, groupHasImages, link, imageSettings) {
    var imageLine = '';
    var hasImage = suggest.hasImage();
    var image = suggest.getImage() || '';
    // Wrapper
    if (hiddenParts.images !== 'all' && groupHasImages && (hasImage || resultSettings.placeholderImage !== null)) {
        if (link !== undefined) {
            var classNames = [];
            if (!hasImage) {
                classNames.push('ss360-suggests__image-wrap--empty');
            }
            if (suggest.getType() === 'YOUTUBE_VIDEO') {
                classNames.push('ss360-suggests__image-wrap--video');
            }
            imageLine += "<div class=\"ss360-suggest__image-container\"><a class=\"ss360-suggests__image-wrap " + classNames.join(' ') + "\" aria-hidden=\"true\" tabindex=\"-1\" href=\"" + link + "\"";
            if (resultSettings.linksOpenNewTab || suggest.getType() === SuggestType.YOUTUBE_VIDEO) {
                imageLine += ' target="_blank"';
            }
            imageLine += '>';
        }
        // Comput image dimensions
        var styleAttribute = '';
        var isDocIcon = UiHelper.isDocIcon(image);
        var isPdfIcon = isDocIcon && image.indexOf('pdf.svg') !== -1;
        if (suggest.imageWidth !== undefined && suggest.imageHeight !== undefined && !isDocIcon) {
            imageSettings.init(layoutSettings);
            if (imageSettings.setWidth || imageSettings.setHeight) { // we only care if one is not explicitly set, we expect the object-fit to be contained
                var targetWidth = void 0;
                var targetHeight = void 0;
                var imageWidth = suggest.imageWidth, imageHeight = suggest.imageHeight;
                var maxWidth = imageSettings.maxWidth !== undefined ? imageSettings.maxWidth : imageWidth;
                var maxHeight = imageSettings.maxHeight !== undefined ? imageSettings.maxHeight : imageHeight;
                if (maxWidth < imageWidth && maxHeight < imageHeight) { // scale down both
                    var scaleRatio = Math.min(maxWidth / imageWidth, maxHeight / imageHeight);
                    targetWidth = scaleRatio * imageWidth;
                    targetHeight = scaleRatio * imageHeight;
                }
                else if (maxWidth < imageWidth) { // scale down width
                    targetWidth = maxWidth;
                    targetHeight = (targetWidth / imageWidth) * imageHeight;
                }
                else if (maxHeight < imageHeight) { // scale down height
                    targetHeight = maxHeight;
                    targetWidth = (targetHeight / imageHeight) * imageWidth;
                }
                else { // both are okay
                    targetWidth = imageWidth;
                    targetHeight = imageHeight;
                }
                styleAttribute = ' style="';
                if (imageSettings.setWidth) {
                    styleAttribute += "width:" + targetWidth + "px;";
                }
                if (imageSettings.setHeight) {
                    styleAttribute += "height:" + targetHeight + "px;";
                }
                styleAttribute += '"';
            }
        }
        // Image
        if (hasImage) {
            var lazyLoadImages = resultSettings.lazyLoadImages;
            var classNames = [];
            if (isDocIcon) {
                classNames.push('ss360-suggests__image--docs');
            }
            if (lazyLoadImages) {
                classNames.push('ss360-suggests__image--lazy ss360-shimmer');
            }
            if (isPdfIcon) {
                classNames.push('ss360-suggests__image--pdf');
            }
            imageLine += "<img " + (lazyLoadImages ? 'data-ss360-' : '') + "src=\"" + image + "\" alt aria-hidden=\"true\" role=\"presentation\" aria-label=\"" + suggest.getName() + "\" \n\t\t\t\t\tclass=\"ss360-suggests__image " + classNames.join(' ') + "\"\n\t\t\t\t\t\t" + (suggest.isResizedImage ? "data-fallback-src=\"" + suggest.getOriginalImage() + "\"" : '') + " " + styleAttribute + "/>";
            if (suggest.getType() === 'YOUTUBE_VIDEO') {
                imageLine += '<i role="presentation" aria-hidden="true" class="ss360-icon ss360-icon--play"></i>';
            }
        }
        if (link !== undefined) {
            imageLine += '</a></div>';
        }
    }
    return imageLine;
};
export default {
    renderSS360Suggest: function (renderSettings) {
        var suggest = renderSettings.suggest, contentGroup = renderSettings.contentGroup, pluginConfiguration = renderSettings.pluginConfiguration, hiddenParts = renderSettings.hiddenParts, headingLevel = renderSettings.headingLevel, query = renderSettings.query, groupHasImages = renderSettings.groupHasImages, imageSettings = renderSettings.imageSettings, hasCtas = renderSettings.hasCtas, cta = renderSettings.cta;
        var isHidden = renderSettings.isHidden, scripts = renderSettings.scripts;
        if (isHidden === undefined) {
            isHidden = false;
        }
        if (scripts === undefined) {
            scripts = [];
        }
        var originalContentGroup = suggest.getOriginalContentGroup();
        var headingId = "ss360-heading-" + Math.round(100000 * Math.random());
        var results = pluginConfiguration.results, layout = pluginConfiguration.layout, style = pluginConfiguration.style;
        var linksOpenNewTab = results.linksOpenNewTab;
        if (suggest.getType() === SuggestType.YOUTUBE_VIDEO) {
            linksOpenNewTab = true;
        }
        var originalGroup = contentGroup === '*' && originalContentGroup !== undefined ? " ss360-original-group-" + StringHelper.getSafeKey(originalContentGroup) : '';
        var styleAttr = !isHidden || pluginConfiguration.style.defaultCss ? '' : ' style="display:none;"';
        var identifierAttr = suggest.getIdentifier() !== undefined ? " data-ss360-identifier=\"" + suggest.getIdentifier() + "\"" : '';
        var suggestLine = "<li class=\"ss360-suggests" + (isHidden ? ' ss360-suggests--hidden' : '') + originalGroup + "\"" + styleAttr + identifierAttr + "><article class=\"ss360-suggests__wrap ss360-n-section\" aria-labelledby=\"" + headingId + "\">";
        var suggestLink = suggest.getLink(pluginConfiguration.queryTerm.highlightMatchedContent, query);
        if (suggest.getType() === 'custom') { // custom suggest html
            var html = suggest.getHtml();
            if (html !== undefined && html.indexOf('<script') !== -1) {
                try {
                    sxQuery(html).each(function (node) {
                        var $node = sxQuery(node);
                        if ($node.get()[0].tagName === 'SCRIPT') {
                            scripts.push($node);
                        }
                        else {
                            $node.find('script').each(function (script) {
                                scripts.push(sxQuery(script));
                            });
                        }
                    });
                }
                catch (ex) {
                    // ccl
                }
            }
            suggestLine = suggestLine.replace('ss360-suggests', 'ss360-suggests ss360-custom-result');
            suggestLine += html;
        }
        else { // standard suggestion result
            // image
            var dataPoints = suggest.getDataPoints();
            for (var i = 0; i < dataPoints.length; i++) {
                var dataPoint = dataPoints[i];
                if (dataPoint.key === 'resultImageUrl') {
                    var image = dataPoint.value;
                    suggest.setImage(image);
                    suggest.imageWidth = dataPoint.width !== undefined && dataPoint.width !== null ? parseInt(dataPoint.width.toString(), 10) : undefined;
                    suggest.imageHeight = dataPoint.height !== undefined && dataPoint.height !== null ? parseInt(dataPoint.height.toString(), 10) : undefined;
                    suggest.isResizedImage = true;
                    break;
                }
            }
            if (!suggest.hasImage()) {
                suggest.setPlaceholderImage(results.placeholderImage);
            }
            suggestLine += renderImage(suggest, hiddenParts, results, layout, groupHasImages, suggestLink, imageSettings);
            // link and name
            var shouldRenderHeader = (hiddenParts.title !== 'all' || hiddenParts.url !== 'all');
            // content and image
            suggestLine += '<div class="ss360-suggests__content">';
            if (shouldRenderHeader) {
                suggestLine += renderTitle(suggest, suggestLink, hiddenParts, headingId, linksOpenNewTab, headingLevel);
                if (suggestLink !== undefined) {
                    suggestLine += renderLink(suggest, results.stripHttp, hiddenParts, suggestLink, linksOpenNewTab);
                }
            }
            var content = suggest.getContent();
            if (content !== undefined && content !== null && hiddenParts.snippet !== 'all') {
                suggestLine += "<p class=\"ss360-suggests__snippet\">" + content + "</p>";
            }
            // Data Points
            if (hiddenParts.dataPoints !== 'all') {
                var keys_1 = [];
                var dataPointsConfig = pluginConfiguration.dataPoints;
                var excludedDataPoints_1 = dataPointsConfig.exclude;
                var singleDataPoints_1 = dataPointsConfig.single;
                var displayType_1 = dataPointsConfig.displayType, unique_1 = dataPointsConfig.unique;
                var groupedDataPoints_1 = (suggest.getDataPoints()).reduce(function (acc, dataPoint) {
                    if (dataPoint.show && excludedDataPoints_1.indexOf(dataPoint.key) === -1) {
                        if (acc[dataPoint.key] === undefined) {
                            acc[dataPoint.key] = [];
                        }
                        if (keys_1.indexOf(dataPoint.key) === -1) { // to keep order
                            keys_1.push(dataPoint.key);
                        }
                        if (acc[dataPoint.key].length === 0 || singleDataPoints_1.indexOf(dataPoint.key) === -1) {
                            var value = dataPoint.value;
                            if (!unique_1 || acc[dataPoint.key].indexOf(value) === -1) {
                                if (displayType_1 && dataPoint.type !== undefined) {
                                    value = value + " <span class=\"ss360-data-point__type\">[" + dataPoint.type + "]</span>";
                                }
                                acc[dataPoint.key].push(value);
                            }
                        }
                    }
                    return acc;
                }, {});
                var collapseDataPoints_1 = dataPointsConfig.collapseBy;
                var showNames_1 = dataPointsConfig.showNames;
                var buildRow_1 = function (key, value, isLast) {
                    if (isLast === void 0) { isLast = false; }
                    var keyCell = showNames_1 ? "<td class=\"ss360-data-point__cell ss360-data-point__cell--key\">" + (key || '') + "</td>" : '';
                    var valueCell = "<td class=\"ss360-data-point__cell ss360-data-point__cell--value\">" + value + "</td>";
                    return "<tr" + (showNames_1 ? '' : " aria-label=\"" + key + "\"") + " class=\"ss360-data-point" + (isLast ? ' ss360-data-point--last' : '') + " ss360-dp__" + StringHelper.getSafeKey((key || '').toLowerCase()) + "\">\n\t\t\t\t\t\t\t" + keyCell + valueCell + "</tr>";
                };
                var kvTableRows = keys_1.map(function (key, idx) {
                    if (collapseDataPoints_1 !== undefined && collapseDataPoints_1 !== null) {
                        var isLast = idx === keys_1.length - 1;
                        return buildRow_1(key, groupedDataPoints_1[key].join(collapseDataPoints_1), isLast);
                    }
                    return groupedDataPoints_1[key].reduce(function (acc, value, innerIdx) {
                        var isLast = idx === keys_1.length - 1 && innerIdx === groupedDataPoints_1[key].length - 1;
                        return acc + buildRow_1(key, value, isLast);
                    }, '');
                });
                var dataPointsDirection = dataPointsConfig.direction;
                if (kvTableRows.length > 0 || layout.mobile.type === 'grid' || layout.desktop.type === 'grid') {
                    suggestLine += "<table class=\"ss360-data-points ss360-data-points--" + dataPointsDirection + "\"><tbody class=\"ss360-data-points__body\">" + kvTableRows.join('') + "</tbody></table>";
                }
            }
            if (hasCtas) {
                var ctaWrapClassName = "ss360-suggests__cta-wrap ss360-suggests__cta-wrap--" + results.ctaDirection;
                if (results.ctaDirection === 'row') {
                    var firstDirection = cta[0].position || 'left';
                    if (firstDirection === 'left') {
                        ctaWrapClassName += ' ss360-flex--justify-start';
                    }
                    else if (firstDirection === 'center') {
                        ctaWrapClassName += ' ss360-flex--justify-center';
                    }
                    else if (firstDirection === 'right') {
                        ctaWrapClassName += ' ss360-flex--justify-end';
                    }
                }
                suggestLine += "<div class=\"" + ctaWrapClassName + "\">";
                cta.forEach(function (singleCta) {
                    var include = singleCta.includeContentGroups;
                    var exclude = singleCta.excludeContentGroups;
                    var showCta = true;
                    var resultContentGroup = contentGroup;
                    if (contentGroup === '*') {
                        resultContentGroup = suggest.getOriginalContentGroup();
                    }
                    if (include !== undefined && include.indexOf(resultContentGroup) === -1) {
                        showCta = false;
                    }
                    else if (exclude !== undefined && exclude.indexOf(resultContentGroup) !== -1) {
                        showCta = false;
                    }
                    if (showCta) {
                        var iconHtml = '';
                        var renderAsButton = singleCta.renderAsButton !== undefined ? singleCta.renderAsButton : true;
                        var icon = renderAsButton ? singleCta.icon || 'ss360:arrow' : 'none';
                        var position = singleCta.position !== undefined ? singleCta.position : 'left';
                        var className = "ss360-n-button ss360-tc-c ss360-tc-b ss360-cta ss360-cta--" + position + (icon !== undefined && icon !== 'none' ? '' : ' ss360-cta--noicon');
                        if (!renderAsButton) {
                            className += ' ss360-cta--plain';
                        }
                        else {
                            className += ' ss360-tc-bg--focus';
                        }
                        if (icon !== 'none') {
                            var iconInner = '';
                            if (icon === 'ss360:shopping-cart') {
                                iconInner = Icons.getSvgIcon(Icons.SHOPPING_CART, style.themeColor, 'ss360-cta__img');
                                className += ' ss360-cta--ss360-icon';
                            }
                            else if (icon === 'ss360:arrow') {
                                iconInner = Icons.getSvgIcon(Icons.TRIANGLE_ARROW, style.themeColor, 'ss360-cta__img');
                                className += ' ss360-cta--ss360-icon';
                            }
                            else if (icon.indexOf('<svg') !== -1 && icon.indexOf('</svg') !== -1) {
                                iconInner = icon;
                            }
                            else {
                                iconInner = "<img class=\"ss360-cta__img\" src=\"" + icon + "\">";
                            }
                            iconHtml = "<i role=\"presentation\" class=\"ss360-cta__icon\">" + iconInner + "</i>";
                        }
                        var text = singleCta.text !== undefined ? "<span>" + singleCta.text + "</span>" : '';
                        var link = void 0;
                        if (singleCta.getLink !== undefined) {
                            link = singleCta.getLink(suggest.suggest);
                        }
                        if (link === undefined) {
                            link = singleCta.link;
                        }
                        if (link === undefined && !renderAsButton) {
                            link = '#';
                        }
                        suggestLine += link !== undefined && link !== null
                            ? "<a class=\"" + className + "\" href=\"" + link.replace(/#RESULT_URL#/g, suggestLink) + "\">" + iconHtml + text + "</a>" : "<button class=\"" + className + "\">" + iconHtml + text + "</button>";
                    }
                });
                suggestLine += '</div>';
            }
            suggestLine += '</div></article>';
        }
        suggestLine += '</li>';
        return suggestLine;
    }
};
