'use strict';
import Polyfill from '../polyfills/Polyfill';
import sxQuery from '../../sxQuery/sxQuery';
var observer = null;
var LoadedImageCache = {};
var hideImage = function (node, placeholderImage) {
    node.hide();
    var $parent = node.parents('.ss360-suggests__image-wrap', true);
    if (placeholderImage !== null) {
        $parent.addClass('ss360-suggests__image-wrap--empty');
    }
    else {
        $parent.remove();
    }
};
var onError = function (node, callbacks, placeholderImage) { return function () {
    var resultImageError = callbacks.resultImageError;
    node.onerror = function () { };
    var $node = sxQuery(node);
    var newImage;
    // @ts-ignore
    var fallbackSrc = $node.data('fallbackSrc');
    if (fallbackSrc !== undefined && fallbackSrc !== null) {
        newImage = fallbackSrc;
        var fallbackImage = new Image();
        fallbackImage.onerror = function () {
            hideImage($node, placeholderImage);
        };
        fallbackImage.src = fallbackSrc;
    }
    else if (placeholderImage !== null && placeholderImage !== undefined) {
        newImage = placeholderImage;
    }
    else if (resultImageError !== undefined) {
        newImage = resultImageError($node.parents('.ss360-suggests', false)[0]);
    }
    if (!newImage) {
        hideImage($node, placeholderImage);
    }
    else {
        $node.removeClass('ss360-suggests__image--lazy ss360-shimmer');
        $node.attr('src', newImage);
    }
}; };
var load = function (node, pluginConfig) {
    // @ts-ignore
    var src = node.data('ss360Src');
    if (src === undefined && node.attr('src') !== null) { // load called for the second time on this node, don't hide it
        return;
    }
    node.data('ss360Src', null);
    node.attr('src', src);
    if (LoadedImageCache[src] !== undefined) {
        node.removeClass('ss360-suggests__image--lazy ss360-shimmer');
    }
    var _a = pluginConfig.results, placeholderImage = _a.placeholderImage, checkImageQuality = _a.checkImageQuality;
    var imageLoaded = pluginConfig.callbacks.imageLoaded;
    var img = new Image();
    img.onerror = onError(node, pluginConfig.callbacks, placeholderImage);
    img.onload = function () {
        LoadedImageCache[src] = true;
        var isSvg = src.toLowerCase().indexOf('.svg') === src.length - 4;
        var width = img.width;
        var height = img.height;
        var aspectRatio = width / height;
        var polyfillBackgroundSize = height >= 100 ? 'contain' : undefined;
        if (!isSvg && checkImageQuality) {
            if (aspectRatio < 4) {
                // do nothing, this is fine for now
            }
            else if (aspectRatio < 8 && height >= 150) {
                node.addClass('ss360-suggests__image--cover');
                polyfillBackgroundSize = 'cover';
            }
            else if ((aspectRatio >= 6 && height < 150) || aspectRatio >= 8) {
                hideImage(node, placeholderImage);
                return;
            }
        }
        node.removeClass('ss360-suggests__image--lazy ss360-shimmer');
        node.attr('style', null);
        if (!('objectFit' in document.documentElement.style)) {
            Polyfill.objectFit(sxQuery(node).parent(), polyfillBackgroundSize);
        }
        if (imageLoaded !== undefined) {
            imageLoaded(node.get()[0], src);
        }
    };
    img.src = src;
};
// TODO: should be layer specific + local global event listeners #multiple-instances
export default (function (context) {
    var pluginConfig = context.pluginConfiguration;
    if (observer !== null) {
        observer.disconnect();
    }
    observer = null; // destruct existing reference
    var $layer = sxQuery("#" + context.layer404Id + ", #" + context.layerId);
    var images = $layer.find('.ss360-suggests__image--lazy').get();
    if (images.length === 0) {
        var fallbackImages = $layer.find('.ss360-suggests__image[data-fallback-src]:not([data-fallback-src=""])');
        if (fallbackImages.length > 0) {
            fallbackImages.each(function (node) {
                node.onerror = onError(node, context.pluginConfiguration.callbacks, context.pluginConfiguration.results.placeholderImage);
            });
        }
        return;
    }
    var rootMargin = 1250;
    // @ts-ignore
    if (('connection' in navigator) && navigator.connection.effectiveType !== undefined && navigator.connection.effectiveType !== '4g') {
        rootMargin = 2500;
    }
    if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var $entry = sxQuery(entry.target);
                    load($entry, pluginConfig);
                    observer.unobserve(entry.target);
                }
            });
        }, {
            rootMargin: rootMargin + "px"
        });
        images.forEach(function (img) {
            observer.observe(img);
        });
    }
    else { // fallback for browsers not supporting IntersectionObserver
        var active_1 = false;
        var $document_1 = sxQuery(document);
        var $window_1 = sxQuery(window);
        var $overlay_1 = context.getExistingLayer().find('.ss360-layer--overlay .ss360-layer__content');
        var eventPostfix_1 = context.generateId('ss360LazyLoad');
        var removeHandlers_1 = function () {
            $document_1.off("scroll." + eventPostfix_1);
            $window_1.off("resize." + eventPostfix_1);
            $window_1.off("orientationchange." + eventPostfix_1);
            $overlay_1.off("scroll." + eventPostfix_1);
        };
        var lazyLoad = function () {
            if (active_1 === false) {
                active_1 = true;
                setTimeout(function () {
                    images.forEach(function (lazyImage) {
                        if ((lazyImage.getBoundingClientRect().top <= (window.innerHeight + rootMargin) && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== 'none') {
                            if (sxQuery(lazyImage).parents('.ss360-suggests--hidden').length > 0) {
                                return;
                            }
                            load(sxQuery(lazyImage), pluginConfig);
                            images = images.filter(function (image) { return image !== lazyImage; });
                            if (images.length === 0) {
                                removeHandlers_1();
                            }
                        }
                    });
                    active_1 = false;
                }, 200);
            }
        };
        removeHandlers_1();
        $document_1.on("scroll." + eventPostfix_1, undefined, lazyLoad, { passive: true });
        $overlay_1.on("scroll." + eventPostfix_1, undefined, lazyLoad, { passive: true });
        $window_1.on("resize." + eventPostfix_1, lazyLoad);
        $window_1.on("orientationchange." + eventPostfix_1, lazyLoad);
        lazyLoad();
    }
});
