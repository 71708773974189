'use strict';
import UrlBuilder from './api/UrlBuilder';
import sxQuery from '../sxQuery/sxQuery';
import SearchResultType from './enums/SearchResultType';
import Reporter from './reporter/Reporter';
import SS360Insights from '../insights/insights';
import Logger from '../global/Logger';
import StringHelper from '../global/StringHelper';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
var Context = /** @class */ (function () {
    function Context(urlBuilder, configuration, core, layer, isDefaultInstance) {
        if (isDefaultInstance === void 0) { isDefaultInstance = true; }
        this.urlBuilder = urlBuilder;
        this.pluginConfiguration = configuration;
        this._isSmart404 = false;
        this.core = core;
        this.layer = layer;
        this.reporter = new Reporter(this.pluginConfiguration);
        this.isClosing = false;
        this.configDefaults = {};
        this.areConfigDefaults = true;
        this.listeners = {};
        this.isDefaultInstance = isDefaultInstance;
        this.alias = StringHelper.getSafeKey(configuration.alias || Math.round(Math.random() * 1000).toString());
        this.layerId = this.generateId('ss360-layer');
        this.consoleId = this.generateId('ss360-search-console');
        this.filteredResultsId = this.generateId('ss360-filtered-results');
        this.filterLayerId = this.generateId('ss360-filter');
        this.customSearchBoxId = this.generateId('ss360-custom-searchbox');
        this.errorLayerId = this.generateId('ss360-error-layer');
        this.customSearchId = this.generateId('ss360-custom-search');
        this.customSearchButtonId = this.generateId('ss360-custom-searchbutton');
        this.resultsId = this.generateId('ss360-results');
        this.layer404Id = this.generateId('ss360-404-layer');
        this.noResultsLayerId = this.generateId('ss360-no-results');
        this.flexWrapperId = this.generateId('ss360-flex-wrapper');
        this.sortingId = this.generateId('ss360-sorting');
        this.searchResultHeadingId = this.generateId('ss360-search-result-heading');
        this.navDropdownId = this.generateId('ss360-nav-dropdown');
        this.queryCorrectionId = this.generateId('ss360-query-correction');
        this.uniboxId = this.generateId('unibox-suggest-box');
        this.specialUniboxId = this.generateId('unibox-suggest-box-special');
        this.specialSearchBoxId = this.generateId('unibox-special-searchbox');
        this.uniboxControlsId = this.generateId('unibox-controls-description');
        this.uniboxStatusMessageId = this.generateId('unibox-status-message');
        this.uniboxInvisibleId = this.generateId('unibox-invisible');
        this.specialUniboxWrapId = this.generateId('unibox-special');
        this.specialUniboxBackgroundId = this.generateId('unibox-special-transition-background');
        this.specialUniboxHiddenContentId = this.generateId('unibox-special-hidden-content');
    }
    Context.prototype.generateId = function (prefix) {
        return (this.isDefaultInstance ? prefix : prefix + "--" + StringHelper.getSafeKey(this.alias));
    };
    Context.prototype.getSearchResultType = function () {
        var results = this.pluginConfiguration.results;
        if (results.fullScreenConfig !== undefined && results.fullScreenConfig.trigger !== undefined) {
            return SearchResultType.Fullscreen;
        }
        if (results.embedConfig !== undefined) {
            var embedConfig = results.embedConfig;
            var willRequireRedirect = embedConfig.url !== undefined && document.location.href.indexOf(embedConfig.url) === -1;
            if (willRequireRedirect) { // keep embed if we need to redirect to a search result page
                return SearchResultType.Embed;
            }
            // fallback to layover if 'contentBlock' not present on the page
            return sxQuery(embedConfig.contentBlock).length > 0 ? SearchResultType.Embed : SearchResultType.Layover;
        }
        return SearchResultType.Layover;
    };
    Context.prototype.setIsSmart404 = function (val) { this._isSmart404 = val; };
    Context.prototype.isSmart404 = function () { return this._isSmart404; };
    Context.prototype.setIsClosing = function (val) {
        this.isClosing = val;
    };
    Context.prototype.hasInsights = function () {
        return this.insights !== undefined;
    };
    Context.prototype.createInsights = function () {
        var cookiePostFix = this.isDefaultInstance ? '' : "--" + this.alias;
        this.insights = new SS360Insights(this.pluginConfiguration.siteId, this.pluginConfiguration.allowCookies, this.pluginConfiguration.tracking, cookiePostFix);
        if (this.isDefaultInstance) {
            window.SS360Insights = this.insights;
        }
    };
    Context.prototype.getInsights = function () {
        return this.insights;
    };
    Context.prototype.disableInsights = function () {
        if (this.isDefaultInstance) {
            delete window.SS360Insights;
        }
        this.insights = undefined;
    };
    Context.prototype.notify = function (event, value) {
        var listeners = this.listeners;
        if (listeners[event] !== undefined) {
            listeners[event].forEach(function (listener) {
                try {
                    listener.handler(value);
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            });
        }
    };
    Context.prototype.subscribe = function (event, listenerKey, handler) {
        var listener = { key: listenerKey, handler: handler };
        var listeners = this.listeners;
        var listenerFound = false;
        if (listeners[event] === undefined) {
            listeners[event] = [];
        }
        else {
            listeners[event].forEach(function (existingListener) {
                if (existingListener.key === listenerKey) {
                    existingListener.handler = handler;
                    listenerFound = true;
                }
            });
        }
        if (!listenerFound) {
            listeners[event].push(listener);
        }
    };
    Context.prototype.getExistingLayer = function () {
        return sxQuery("#" + this.layerId);
    };
    Context.prototype.getExistingSearchConsole = function () {
        return sxQuery("#" + this.consoleId);
    };
    Context.prototype.getExistingFilteredResultsLayer = function () {
        return sxQuery("#" + this.filteredResultsId);
    };
    Context.prototype.getExistingFilterLayer = function () {
        return sxQuery("#" + this.filterLayerId);
    };
    Context.prototype.getExistingCustomSearchBox = function () {
        return sxQuery("#" + this.customSearchBoxId);
    };
    Context.prototype.getExistingCustomSearch = function () {
        return sxQuery("#" + this.customSearchId);
    };
    Context.prototype.getExistingLayer404 = function () {
        return sxQuery("#" + this.layer404Id);
    };
    Context.prototype.getExistingNoResultsLayer = function () {
        return sxQuery("#" + this.noResultsLayerId);
    };
    Context.prototype.getExistingFlexWrapper = function () {
        return sxQuery("#" + this.flexWrapperId);
    };
    Context.prototype.getExistingSorting = function () {
        return sxQuery("#" + this.sortingId);
    };
    Context.prototype.getExistingHeading = function () {
        return sxQuery("#" + this.searchResultHeadingId);
    };
    Context.prototype.createCookie = function (baseKey, value, days) {
        var cookieKey = this.generateId(baseKey);
        SxQueryUtils.createCookie(cookieKey, value, days);
    };
    Context.prototype.readCookie = function (baseKey) {
        var cookieKey = this.generateId(baseKey);
        return SxQueryUtils.readCookie(cookieKey);
    };
    Context.prototype.storeObject = function (baseKey, value) {
        var key = this.generateId(baseKey);
        SxQueryUtils._storeObject(key, value);
    };
    Context.prototype.readObject = function (baseKey, defaultValue) {
        var key = this.generateId(baseKey);
        return SxQueryUtils._readObject(key, defaultValue);
    };
    Context.prototype.getInsightsEventKey = function () {
        return this.generateId('ss360Insights');
    };
    return Context;
}());
export function createContext(apiConfig, configuration, core, layer, isDefaultInstance) {
    if (isDefaultInstance === void 0) { isDefaultInstance = true; }
    return new Context(new UrlBuilder(apiConfig, configuration), configuration, core, layer, isDefaultInstance);
}
export default Context;
