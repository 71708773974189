'use strict';
import sxQuery from './sxQuery/sxQuery';
import DefaultConfig from './siteSearch/configuration/DefaultConfig';
import ConfigurationHelper from './siteSearch/configuration/ConfigurationHelper';
import Core from './siteSearch/core';
import Helper from './siteSearch/utils/Helper';
import UniboxDefaultConfig from './siteSearch/configuration/UniboxDefaultConfig';
import NavigatorHelper from './siteSearch/utils/NavigatorHelper';
import SubConfigApplier from './siteSearch/utils/SubConfigApplier';
import Logger from './global/Logger';
import SxQueryUtils from './sxQuery/SxQueryUtils';
import FilterPosition from './siteSearch/enums/FilterPosition';
import CallbackEnhanceType from './siteSearch/enums/CallbackEnhanceType';
import SearchResultType from './siteSearch/enums/SearchResultType';
import MediaQueryMatchType from './sxQuery/MediaQueryMatchType';
var enhanceCallbacks = function (settings, core) {
    var callbacks = settings.callbacks;
    callbacks.enter = Helper.enhanceCallback(callbacks.enter, core.showResults.bind(core), CallbackEnhanceType.Enter, core.getContext());
    callbacks.focus = Helper.enhanceCallback(callbacks.focus, core.focus.bind(core), CallbackEnhanceType.Focus, core.getContext());
    callbacks.blur = Helper.enhanceCallback(callbacks.blur, core.blur.bind(core), CallbackEnhanceType.Blur, core.getContext());
    callbacks.enterResult = Helper.enhanceCallback(callbacks.enterResult, core.followLink.bind(core), CallbackEnhanceType.EnterResult, core.getContext());
    callbacks.type = Helper.enhanceCallback(callbacks.type, core.recordTyping.bind(core), CallbackEnhanceType.Type, core.getContext());
};
var initializeUnibox = function (ss360Settings, core, configInstance) {
    (function (sxQuery) {
        sxQuery.fn.unibox = function (options, useFullUnibox, callback) {
            var _this = this;
            // @ts-ignore
            options = Helper.copyObject(options || {});
            options.hasMultipleSearchBoxes = this.length > 1;
            ConfigurationHelper.extendUniboxOptions(ss360Settings, options, core, configInstance);
            if (!useFullUnibox) {
                return this.map(function (idx, searchBox) {
                    searchBox = sxQuery(searchBox);
                    searchBox.on('keyup.uniboxDummy', function (event) {
                        event = event || window.event;
                        var keyCode = event.keyCode || event.which;
                        if (keyCode === 13 && !ss360Settings.results.ignoreEnter) {
                            options.callbacks.enter(searchBox.val(), undefined, undefined);
                        }
                    });
                    searchBox.on('keydown.uniboxDummy', undefined, function (e) {
                        options.callbacks.type.call(searchBox.get()[0], e, searchBox.val());
                    });
                    searchBox.on('focus.uniboxDummy', undefined, function (e) {
                        options.callbacks.focus.call(searchBox.get()[0], e, searchBox.val());
                    });
                    searchBox.on('focusout.uniboxDummy', undefined, function (e) {
                        options.callbacks.blur.call(searchBox.get()[0], e, searchBox.val(), true);
                    });
                    if (ss360Settings.searchBox.placeholder !== undefined && searchBox.data('ss360KeepPlaceholder') !== 'true') {
                        searchBox.attr('placeholder', ss360Settings.searchBox.placeholder);
                    }
                    searchBox.getText = function () { return searchBox.val(); };
                    searchBox.updateSuggestUrl = function () { }; // dummy
                    return searchBox;
                });
            }
            // @ts-ignore
            import(/* webpackChunkName: "unibox" */ './unibox/unibox').then(function (_a) {
                var UniBox = _a.default;
                var boxesArray = _this.map(function (idx, searchBox) {
                    var isActive = document.activeElement === searchBox;
                    searchBox = sxQuery(searchBox);
                    searchBox.off('keyup.uniboxDummy, keydown.uniboxDummy, focus.uniboxDummy, focusout.uniboxDummy');
                    // settings with default options.
                    var settings = SxQueryUtils.extend(Helper.copyObject(UniboxDefaultConfig), options);
                    if (settings.searchBoxContainerSelector === undefined) {
                        settings.searchBoxContainer = searchBox.parent();
                    }
                    else {
                        settings.searchBoxContainer = sxQuery(settings.searchBoxContainerSelector);
                    }
                    settings.context = core.getContext();
                    var individualUnibox = new UniBox(searchBox, settings);
                    individualUnibox.init();
                    if (isActive && settings.specialMobileSuggest !== undefined && settings.specialMobileSuggest.enabled && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767)) {
                        individualUnibox.showSpecialSuggest();
                    }
                    return individualUnibox;
                });
                if (callback !== undefined) {
                    callback(boxesArray.length === 1 ? boxesArray[0] : boxesArray);
                }
            });
            return undefined;
        };
    }(sxQuery));
};
var handleQueryParams = function (opts, pluginConfiguration) {
    var searchQueryParamName = opts.searchQueryParamName, filtersEnabled = opts.filtersEnabled, searchBoxSelector = opts.searchBoxSelector, highlightSearchTerms = opts.highlightSearchTerms, shouldPopulateSearchBox = opts.shouldPopulateSearchBox, core = opts.core;
    var queryTerm = pluginConfiguration.queryTerm;
    var queryDict = NavigatorHelper.buildQueryDict();
    // have we gotten redirected here? maybe we need to highlight something?
    if (queryDict.ss360SearchTerm !== undefined && highlightSearchTerms) {
        try {
            var HIGHLIGHT_CLASS_NAME_1 = 'ss360-search-term-highlight';
            var targetElements_1 = queryTerm.highlightContext === undefined
                ? sxQuery('div, section, main')
                : sxQuery(queryTerm.highlightContext).find('div, section, main');
            var highlightTerms = [queryDict.ss360SearchTerm];
            if (queryTerm.tokenize) {
                var queryParts = queryDict.ss360SearchTerm.split(' ');
                if (queryParts.length > 1) {
                    for (var tokenLength = queryParts.length - 1; tokenLength > 0; tokenLength--) {
                        for (var i = 0; i <= queryParts.length - tokenLength; i++) {
                            var tokenParts = [];
                            for (var j = 0; j < tokenLength; j++) {
                                tokenParts.push(queryParts[j + i]);
                            }
                            highlightTerms.push(tokenParts.join(' '));
                        }
                    }
                }
            }
            highlightTerms.forEach(function (term) {
                targetElements_1.highlight(term, HIGHLIGHT_CLASS_NAME_1);
            });
            // only scroll there if we find exactly one match
            var highlightedPart = sxQuery("." + HIGHLIGHT_CLASS_NAME_1);
            if (highlightedPart.length === 0 && queryDict.ss360SearchTerm.indexOf('+') !== -1) { // try replacing all plus signs with a space
                targetElements_1.highlight(queryDict.ss360SearchTerm.replace(/\+/g, ' '), HIGHLIGHT_CLASS_NAME_1);
                highlightedPart = sxQuery("." + HIGHLIGHT_CLASS_NAME_1);
            }
            if (highlightedPart.length === 1 || (queryTerm.scrollOnMultiMatch && highlightedPart.length > 0)) {
                var block = queryTerm.scrollIntoViewBlock;
                if (block !== 'none') {
                    sxQuery("." + HIGHLIGHT_CLASS_NAME_1).get()[0].scrollIntoView({ behavior: queryTerm.scrollIntoViewBehavior, block: block, inline: 'nearest' });
                    // should we try to expand accordion?
                    var parent = highlightedPart.parent();
                    if (parent.get()[0].nodeName !== 'A' || (parent.attr('href') === null || parent.attr('href').length === 0 || parent.attr('href').indexOf('#') === 0)) {
                        parent.get()[0].click();
                    }
                }
            }
        }
        catch (e) {
            Logger.info(e);
        }
    }
    // is this a search page deep link? Also should be triggered if the user presses back
    var query;
    if (opts.semanticMode) {
        query = NavigatorHelper.getSemanticModeQuery(opts.embedUrl, opts.semanticModeParamName, pluginConfiguration.results);
    }
    else if (queryDict[searchQueryParamName] !== undefined) {
        query = (queryDict[searchQueryParamName] || core.context.readCookie('ss360LastQuery'));
        if (query !== undefined && query !== null) {
            if (core.getSearchResultType() === SearchResultType.Fullscreen) {
                core.context.getExistingCustomSearchBox().val(query);
                if (core.context.getExistingSearchConsole().css('top') !== '0px') {
                    core.showFullscreenLayer();
                }
            }
            if (core.getSearchResultType() === SearchResultType.Embed && query.indexOf('+') !== -1 && window.location.href.indexOf(searchQueryParamName + "=" + query) !== -1) { // check if the search param is properly encoded (e.g. personello.de 'photo pillow')
                query = query.replace(/\+/g, ' ');
            }
        }
    }
    if (query !== undefined) {
        if (shouldPopulateSearchBox) {
            sxQuery(searchBoxSelector).val(query);
        }
        var filters = void 0;
        if (filtersEnabled) {
            filters = NavigatorHelper.getFilters(pluginConfiguration.results);
        }
        var sort = void 0;
        var sortParamName = NavigatorHelper.escapeQueryParam(pluginConfiguration.results.sortingParamName);
        if (queryDict[sortParamName] !== undefined) {
            sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
        }
        core.showResults(query, {
            filters: filters,
            shouldPushState: false,
            submitSource: 'pageload',
            sort: sort
        });
    }
};
var init = function (config, isDefaultInstance) {
    if (isDefaultInstance === void 0) { isDefaultInstance = true; }
    if (config === undefined) {
        Logger.warn("ss360Config object is not defined. The document.location.host will be used as siteId and '#searchBox' as search box selector.");
        config = {};
    }
    if (config.siteId === undefined) {
        config.siteId = document.location.host;
    }
    if (config.disableInfoLogs === true) {
        if (config.loggers === undefined) {
            config.loggers = {};
        }
        if (config.loggers.info === undefined) {
            config.loggers.info = function () { };
        }
    }
    if (config.loggers !== undefined) {
        if (config.loggers.info !== undefined) {
            Logger.set('info', config.loggers.info);
        }
        if (config.loggers.warn !== undefined) {
            Logger.set('warn', config.loggers.warn);
        }
        if (config.loggers.error !== undefined) {
            Logger.set('error', config.loggers.error);
        }
    }
    var defaults = Helper.copyObject(DefaultConfig);
    var always = function () {
        // @ts-ignore
        var ss360Settings = SxQueryUtils.extend(defaults, config);
        if (ss360Settings.filters.enabled && config.filters.position === undefined && ss360Settings.results.embedConfig === undefined && ss360Settings.results.fullScreenConfig === undefined) { // filter position top, if layover
            ss360Settings.filters.position = FilterPosition.Top;
        }
        if (config.layout !== undefined && config.layout.masonryCols !== undefined) {
            ss360Settings.layout.masonryCols = Helper.copyObject(config.layout.masonryCols);
        }
        if (ss360Settings.style.defaultCss) {
            // eslint-disable-next-line global-require
            var css = require('../sass/siteSearch/critical.scss'); // load the default css
            var $head = sxQuery('head');
            $head.find('#ss360-custom-search-input').remove();
            $head.append("<style id=\"ss360-custom-search-input\" type=\"text/css\">" + css + "</style>");
        }
        ConfigurationHelper.assert(config, ss360Settings);
        var core = new Core(ss360Settings, isDefaultInstance);
        SubConfigApplier.init(core.getContext());
        SubConfigApplier.apply(core.getContext());
        // make sure all calbacks work as expected
        enhanceCallbacks(ss360Settings, core);
        // expose public methods
        var publicInstance = {
            changeConfig: core.changeConfig.bind(core),
            setSiteId: core.setSiteId.bind(core),
            init: core.init.bind(core),
            showResults: core.showResults.bind(core),
            showFullscreenLayer: core.showFullscreenLayer.bind(core),
            closeLayer: core.closeLayer.bind(core),
            showLoading: core.showLoading.bind(core),
            hideLoading: core.hideLoading.bind(core),
            isInitialized: core.isInitialized.bind(core),
            setBaseUrl: core.setBaseUrl.bind(core),
            setSuggestUrl: core.setSuggestUrl.bind(core),
            renderResults: core.renderResults.bind(core),
            openTab: core.openTab.bind(core),
            hideSearchResults: core.hideSearchResults.bind(core),
            getVersion: function () {
                return core.VERSION;
            },
            getSettings: function () {
                return core.getSettings(); // FIXME
            }
        };
        if (isDefaultInstance) {
            window.SS360 = publicInstance;
        }
        else if (ss360Settings.alias !== undefined) {
            window[ss360Settings.alias] = publicInstance;
        }
        // init
        sxQuery(document).ready(function () {
            initializeUnibox(ss360Settings, core, config);
            core.init();
            if (ss360Settings.style.defaultCss) {
                // @ts-ignore
                import(/* webpackChunkName: "styles" */ './siteSearch/styles/Styles').then(function (_a) {
                    var Styles = _a.default;
                    Styles.renderDefaultStyles(ss360Settings, core.context.layerId);
                });
            }
            var shouldPopulateSearchBox = ss360Settings.results.embedConfig === undefined
                || ss360Settings.results.embedConfig.populateSearchBoxOnRedirect === undefined
                || ss360Settings.results.embedConfig.populateSearchBoxOnRedirect !== false;
            var opts = {
                searchQueryParamName: ss360Settings.results.searchQueryParamName,
                filtersEnabled: ss360Settings.filters.enabled,
                searchBoxSelector: ss360Settings.searchBox.selector,
                highlightSearchTerms: ss360Settings.queryTerm.highlight,
                semanticMode: ss360Settings.results.semanticMode,
                semanticModeParamName: ss360Settings.results.semanticModeParamName,
                embedUrl: ss360Settings.results.embedConfig !== undefined ? ss360Settings.results.embedConfig.url : undefined,
                shouldPopulateSearchBox: shouldPopulateSearchBox,
                core: core
            };
            handleQueryParams(opts, ss360Settings);
        });
    };
    if (config.language !== undefined) {
        var lang_1 = config.language.toString().toLowerCase().trim();
        if (lang_1.length > 0 && lang_1 !== 'en') {
            // @ts-ignore
            import(/* webpackChunkName: "translations" */ './siteSearch/configuration/ConfigTranslations').then(function (_a) {
                var ConfigTranslations = _a.default;
                var languageMap = {
                    fr: ConfigTranslations.FR,
                    de: ConfigTranslations.DE,
                    nl: ConfigTranslations.NL,
                    tr: ConfigTranslations.TR,
                    it: ConfigTranslations.IT,
                    pl: ConfigTranslations.PL,
                    es: ConfigTranslations.ES,
                    'es-mx': ConfigTranslations.ES_MX,
                    pt: ConfigTranslations.PT,
                    da: ConfigTranslations.DA,
                    fi: ConfigTranslations.FI,
                    sv: ConfigTranslations.SV,
                    no: ConfigTranslations.NO,
                    lt: ConfigTranslations.LT,
                    ro: ConfigTranslations.RO,
                    lv: ConfigTranslations.LV,
                    el: ConfigTranslations.EL,
                    hu: ConfigTranslations.HU,
                    cz: ConfigTranslations.CZ,
                    ru: ConfigTranslations.RU
                };
                var translations = languageMap[lang_1];
                if (translations !== undefined) {
                    defaults = SxQueryUtils.extend(defaults, translations);
                }
                always();
            });
        }
        else {
            always();
        }
    }
    else {
        always();
    }
};
window.sxQuery = sxQuery; // make sxQuery public
var initializeSs360 = function (config) {
    config = config || window.ss360Config;
    if (config instanceof Array) {
        config.forEach(function (singleConfig, idx) {
            if (idx !== 0 && singleConfig.alias === undefined) {
                singleConfig.alias = "SS360_" + idx;
            }
            init(singleConfig, idx === 0);
        });
    }
    else if (config !== undefined || !('ss360Configs' in window)) {
        init(config, true);
    }
    if ('ss360Configs' in window) {
        Object.keys(window.ss360Configs).forEach(function (key) {
            var singleConfig = window.ss360Configs[key];
            if (singleConfig.alias === undefined) {
                singleConfig.alias = key;
            }
            init(singleConfig, false);
        });
    }
};
window.initializeSs360 = initializeSs360;
/** SS360 ends here * */
if (!('ss360Config' in window) || window.ss360Config === undefined || window.ss360Config.autoInit !== false) { // TOD: provide public API and init over here
    if ('ss360Config' in window || 'ss360Configs' in window) {
        initializeSs360();
    }
    else {
        sxQuery(document).ready(function () {
            initializeSs360();
        });
    }
}
