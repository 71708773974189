'use strict';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import SearchResultType from '../enums/SearchResultType';
var MENU_BAR_MARKUP = '<ul class="ss360-nav__menu" role="menubar"></ul>';
var PRE_MARKUP = '<div class="ss360-nav__pre ss360-tc-b" role="presentation"></div>';
var POST_MARKUP = '<div class="ss360-nav__post ss360-tc-b" role="presentation"></div>';
var getDropdownMarkup = function (id) { return "<div class=\"ss360-nav__select-wrapper ss360-tc-bg\"><label class=\"ss360-nav__select-label ss360-tc-bg\" for=\"" + id + "\"><span></span></label><select class=\"ss360-nav__select\" id=\"" + id + "\"></select></div>"; };
var Navigation = /** @class */ (function () {
    function Navigation(data, context) {
        this.context = context;
        var pluginConfiguration = context.pluginConfiguration;
        var navigation = pluginConfiguration.layout.navigation;
        this.position = navigation.position;
        var maxTabs = this.position === 'top' ? 5 : 10;
        this._isTabbed = navigation.type === 'tabs';
        var forceTabs = navigation.forceTabs;
        this.entryCount = Object.keys(data.suggests).length;
        this._isDropdown = this._isTabbed && ((!forceTabs && this.entryCount > maxTabs) || SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991));
        if (navigation.fallbackToScroll && this._isDropdown && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992)) {
            this._isTabbed = false;
            this._isDropdown = false;
        }
        this.forceFlex = !this._isDropdown && this.position === 'left';
        this.pluginConfiguration = pluginConfiguration;
        this.nav = null;
        this.headings = {};
        this.keyToName = {};
        this.data = data;
    }
    Navigation.prototype.getNav = function () { return this.nav; };
    Navigation.prototype.shouldForceFlex = function () { return this.forceFlex; };
    Navigation.prototype.getPosition = function () { return this.position; };
    Navigation.prototype.isTabbed = function () { return this._isTabbed; };
    Navigation.prototype.isDropdown = function () { return this._isDropdown; };
    Navigation.prototype.show = function () {
        if (this.nav !== null) {
            this.nav.show();
        }
    };
    Navigation.prototype.hide = function () {
        if (this.nav !== null) {
            this.nav.hide();
        }
    };
    Navigation.prototype.bindClickHandler = function (navEntry, safeKey, heading, searchResultType, searchQuery, contentGroupName) {
        var _this = this;
        navEntry.find('button').on('click', function () {
            var group = _this.context.getExistingLayer().find(".ss360-group-" + safeKey);
            var $toFocus = group.find('.ss360-suggests:first .ss360-suggests__link');
            // toggle tab if tabbed
            if (_this._isTabbed) {
                if (sxQuery(navEntry).hasClass('ss360-nav__menu-item--active')) { // same tab
                    return;
                }
                var layer = _this.context.getExistingLayer();
                layer.find('.ss360-group').removeClass('ss360-group--active');
                layer.find('nav li').removeClass('ss360-nav__menu-item--active');
                group.addClass('ss360-group--active');
                navEntry.addClass('ss360-nav__menu-item--active');
                _this.context.getExistingHeading().find('a').html(heading);
            }
            if (!_this._isTabbed) {
                _this.scrollTo(group, searchResultType);
            }
            else {
                _this.persistOpenTab(searchQuery, safeKey);
            }
            _this.callNavigationClickCallback(contentGroupName);
            // focus first result
            if (_this.pluginConfiguration.results.focusResultBlock) {
                setTimeout(function () {
                    $toFocus.focus();
                }, 5);
            }
        });
    };
    Navigation.prototype._buildNav = function (position) {
        if (position !== 'left' && position !== 'top') { // invalid position
            return null;
        }
        // class name
        var className = "ss360-nav ss360-nav--" + position;
        if (position === 'top') {
            className = className + " ss360-n-section";
        }
        if (this._isTabbed) {
            className += ' ss360-nav--tabs ss360-flex ss360-flex--wrap';
            if (position === 'left') {
                className += ' ss360-flex--column';
            }
        }
        if (this._isDropdown) {
            className += ' ss360-dropdown ss360-flex--align-center ss360-flex--justify-center';
        }
        var markup = "<nav role=\"navigation\" class=\"" + className + "\" aria-label=\"Search Result Navigation\">";
        if (!this._isDropdown) {
            markup += (this._isTabbed ? PRE_MARKUP : '') + MENU_BAR_MARKUP + (this._isTabbed ? POST_MARKUP : '');
        }
        else {
            markup += getDropdownMarkup(this.context.navDropdownId);
        }
        markup += '</nav>';
        return sxQuery(markup);
    };
    Navigation.prototype.build = function (layer, layerContent) {
        if (this.entryCount < 2)
            return;
        this.nav = this._buildNav(this.position);
        if (this.nav === null) {
            return;
        }
        if (!this._isDropdown && this.position !== 'top') {
            layerContent.addClass('ss360-layer__content--right');
        }
        if (this._isTabbed) {
            layerContent.addClass('ss360-layer__content--tabs');
        }
        if (this._isDropdown) {
            this.bindDropdown(layer);
        }
        if (!this.forceFlex) {
            layer.append(this.nav);
        }
        else {
            var flexWrapper = this.context.getExistingFlexWrapper();
            if (flexWrapper.length === 0) {
                flexWrapper = sxQuery("<div class=\"ss360-flex ss360-flex--column-sm ss360-flex--align-stretch ss360-layer__flex-wrapper\" id=\"" + this.context.flexWrapperId + "\"></div>");
                layer.append(flexWrapper);
            }
            flexWrapper.append(this.nav);
        }
    };
    // eslint-disable-next-line class-methods-use-this
    Navigation.prototype.scrollTo = function (el, searchResultType) {
        var animationSpeed = this.pluginConfiguration.style.animationSpeed;
        var layer = this.context.getExistingLayer();
        // scroll to first result if not tabbed
        if (searchResultType === SearchResultType.Layover) {
            var top = layer.find('.ss360-layer__content').scrollTop() + el.position().top;
            layer.find('.ss360-layer__content').animateScrollTop(top, animationSpeed);
        }
        if (searchResultType === 'fullscreen') {
            this.context.getExistingSearchConsole().animateScrollTop(el.offset().top, animationSpeed);
        }
        if (searchResultType === 'embed') {
            sxQuery('html, body').animateScrollTop(el.offset().top, animationSpeed);
        }
    };
    Navigation.prototype.focusTab = function (contentGroupKey) {
        if (this.nav === null || !this._isTabbed) {
            return;
        }
        var targetGroup = this.context.getExistingLayer().find(".ss360-group-" + contentGroupKey);
        if (targetGroup.length === 0) {
            return;
        }
        if (this._isDropdown) {
            var navSelect = this.context.getExistingLayer().find('.ss360-nav__select');
            // @ts-ignore
            this.context.getExistingLayer().find('.ss360-nav__select-label').text(navSelect.find("option[value='" + contentGroupKey + "']").text());
            navSelect.val(contentGroupKey);
        }
        else {
            this.context.getExistingLayer().find('.ss360-nav--tabs li').removeClass('ss360-nav__menu-item--active');
            this.context.getExistingLayer().find(".ss360-nav--tabs li[data-cgkey='" + contentGroupKey + "'").addClass('ss360-nav__menu-item--active');
        }
        this.context.getExistingLayer().find('.ss360-group').removeClass('ss360-group--active');
        targetGroup.addClass('ss360-group--active');
        this.context.getExistingHeading().find('a').html(this.headings[contentGroupKey]);
    };
    Navigation.prototype.addEntry = function (viewKey, safeKey, query, resultCount, idx, searchResultType, entryIdx, total, originalKey) {
        if (this.nav === null || viewKey === undefined || viewKey.length === 0) {
            return;
        }
        var aid = Math.round(10000 * Math.random());
        var navigation = this.pluginConfiguration.layout.navigation;
        // heading
        // @ts-ignore
        var headingTemplate = navigation.tabTitle || this.context.getExistingHeading().find('a').text();
        var rawHeadingString = safeKey !== 'ss360_all_results' ? headingTemplate : (navigation.allResultsTabTitle || '');
        var heading = rawHeadingString.replace(/#COUNT#/g, resultCount.toString()).replace(/#NAME#/g, viewKey).replace(/#QUERY#/g, query);
        this.headings[safeKey] = heading;
        this.keyToName[safeKey] = viewKey;
        var navEntry = this.createNavigationEntry(aid, viewKey, safeKey, navigation.showGroupResultCount, resultCount, entryIdx, total);
        // is current entry active?
        if (idx === 0 && this._isTabbed) {
            if (this._isDropdown) {
                this.nav.find('label').text(navEntry.text());
            }
            this.context.getExistingHeading().find('a').html(heading);
            navEntry.addClass('ss360-nav__menu-item--active');
        }
        if (!this._isDropdown) {
            this.bindClickHandler(navEntry, safeKey, heading, searchResultType, query, originalKey);
        }
        this.nav.find(this._isDropdown ? 'select' : 'ul').append(navEntry);
    };
    Navigation.prototype.onResultsRendered = function (isPopState) {
        var _this = this;
        if (isPopState === void 0) { isPopState = false; }
        if (this._isTabbed) {
            var lastOpenTab = this.context.readCookie('ss360-open-tab');
            var navigation = this.pluginConfiguration.layout.navigation;
            var query = this.data.query;
            if (lastOpenTab !== null && (lastOpenTab.split('<#>')[0] === query || isPopState || navigation.keepOpenTab)) { // should we reopen a certain tab, e.g. after a page refresh? in case of a popstate we also keep the current tab open
                var groupName = lastOpenTab.split('<#>')[1];
                this.focusTab(groupName);
            }
            if (this.nav !== null && !this._isDropdown) {
                SxQueryUtils.requestAnimationFrame(function () {
                    var firstOffsetTop = _this.nav.find('.ss360-nav__menu-item:first').offset().top;
                    var lastOffsetTop = _this.nav.find('.ss360-nav__menu-item:last-of-type').offset().top;
                    if (Math.abs(Math.floor(firstOffsetTop) - Math.floor(lastOffsetTop)) > 3) { // some offset because of border
                        _this.nav.find('.ss360-nav__pre, .ss360-nav__post').hide();
                    }
                });
            }
        }
    };
    Navigation.prototype.persistOpenTab = function (searchQuery, safeKey) {
        if (this.pluginConfiguration.allowCookies) { // store open tab
            var cookieValue = searchQuery + "<#>" + safeKey;
            this.context.createCookie('ss360-open-tab', cookieValue, 1 / 24);
        }
    };
    Navigation.prototype.callNavigationClickCallback = function (contentGroupName) {
        var navigationClick = this.pluginConfiguration.callbacks.navigationClick;
        if (navigationClick !== undefined && (typeof navigationClick) === 'function') {
            navigationClick(contentGroupName);
        }
        this.context.notify('navigationChange', contentGroupName);
    };
    Navigation.prototype.bindDropdown = function (layer) {
        var _this = this;
        var $select = this.nav.find('select');
        var $label = this.nav.find('label');
        $select.on('focus', function () {
            $label.addClass('ss360-nav__select-label--focus');
        });
        $select.on('focusout', function () {
            $label.removeClass('ss360-nav__select-label--focus ss360-nav__select-label--open');
        });
        $select.on('change', function (e) {
            var text = sxQuery(e.target.options[e.target.selectedIndex]).text();
            $label.text(text);
        });
        $select.on('click', function () {
            if (sxQuery($label).hasClass('ss360-nav__select-label--open')) {
                $label.removeClass('ss360-nav__select-label--open');
            }
            else {
                $label.addClass('ss360-nav__select-label--open');
            }
        });
        $select.on('change', function (e) {
            var val = e.target.value;
            var group = _this.context.getExistingLayer().find(".ss360-group-" + val);
            _this.context.getExistingLayer().find('nav li').removeClass('ss360-nav__menu-item--active');
            _this.context.getExistingLayer().find('.ss360-group').removeClass('ss360-group--active');
            group.addClass('ss360-group--active');
            _this.context.getExistingHeading().find('a').html(_this.headings[val]);
            _this.persistOpenTab(_this.data.query, val);
            _this.callNavigationClickCallback(_this.keyToName[val]);
        });
    };
    Navigation.prototype.createNavigationEntry = function (aid, viewKey, safeKey, shouldShowCount, count, idx, total) {
        if (this._isDropdown) {
            var selectOptionText = viewKey + (shouldShowCount ? (" (" + count + ")") : '');
            return sxQuery("<option class=\"ss360-nav__select-option\" value=\"" + safeKey + "\">" + selectOptionText + "</option>");
        }
        var entry = sxQuery("<li class=\"ss360-nav__menu-item ss360-tc-b ss360-n-li\" data-cgkey=\"" + safeKey + "\" role=\"none\"></li>");
        var btn = sxQuery("<button type=\"button\" id=\"navelement" + aid + "\" class=\"ss360-nav__entry ss360-n-button ss360-tc-b ss360-ac-bg--hover\" role=\"menuitem\">" + viewKey + "</button>");
        if (!this._isTabbed) {
            btn.addClass('ss360-tc-c ss360-ac-bg--hover ss360-ac-b--hover');
        }
        entry.append(btn);
        if (shouldShowCount) {
            entry.find('button').append("<span class=\"ss360-nav__result-count\">" + count + "</span>");
        }
        if (idx === 0) {
            btn.addClass('ss360-nav__entry--first');
        }
        if (idx === total - 1) {
            btn.addClass('ss360-nav__entry--last');
        }
        return entry;
    };
    return Navigation;
}());
export default Navigation;
