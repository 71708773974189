'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Icons from './Icons';
var isAvailable = ('webkitSpeechRecognition' in window);
if (isAvailable) { // doesn't work on Chrome on iOS (iOS 14/15 and Chrome 9x.xx)
    try {
        var userAgent = ('navigator' in window) && window.navigator.userAgent ? window.navigator.userAgent : '';
        isAvailable = (userAgent.match(/CPU iPhone OS (14|15)/) === null || userAgent.match(/CriOS\/9/) === null);
    }
    catch (err) {
        // ccl
    }
}
var VoiceSearch = /** @class */ (function () {
    function VoiceSearch(searchBox, callback, voiceSearchConfig, isCustomSearchBox) {
        if (isCustomSearchBox === void 0) { isCustomSearchBox = false; }
        if (!isAvailable) {
            return;
        }
        this.searchBox = searchBox;
        this.callback = callback;
        this.config = voiceSearchConfig;
        var lang = voiceSearchConfig.lang;
        var color = voiceSearchConfig.color;
        var repositionTrigger = voiceSearchConfig.repositionTrigger;
        var autoPosition = voiceSearchConfig.autoPosition;
        var voiceButton = sxQuery("<button aria-label='Search by speech' class='ss360-voice-search ss360-flex ss360-flex--align-center ss360-flex--justify-center ss360-n-button'\n\t\t\t" + (autoPosition ? " style='display: none;position:absolute'" : '') + ">" + Icons.getSvgIcon(Icons.MICROPHONE, color) + "</button>'");
        var timeoutId = -1;
        // position
        if (isCustomSearchBox) {
            voiceButton.show();
        }
        if (autoPosition) {
            var resizeCallback_1 = function () {
                var sbHeight = searchBox.outerHeight();
                var voicePadding = 0;
                if (sbHeight >= 40) {
                    voicePadding = 10;
                }
                else if (sbHeight >= 30) {
                    voicePadding = sbHeight - 30;
                }
                var voiceButtonDimension = sbHeight - voicePadding;
                var topOffset = searchBox.offset().top - searchBox.parent().offset().top;
                var leftOffset = searchBox.offset().left - searchBox.parent().offset().left;
                var sbPadding = voiceButtonDimension + (voicePadding / 2);
                // @ts-ignore
                var stylesheetPadding = parseInt((searchBox.css('paddingRight') || '0').replace('px', ''), 10);
                voiceButton.css('position', 'absolute');
                voiceButton.css('top', topOffset + (voicePadding / 2));
                voiceButton.css('height', voiceButtonDimension);
                voiceButton.css('width', voiceButtonDimension);
                voiceButton.css('left', leftOffset + searchBox.outerWidth() - voiceButtonDimension - (voicePadding / 2));
                if (searchBox.css('boxSizing') !== 'border-box') {
                    // @ts-ignore
                    searchBox.css('width', parseFloat((searchBox.css('width') || '').replace('px', '')) - sbPadding + stylesheetPadding);
                }
                searchBox.css('paddingRight', sbPadding);
                voiceButton.show();
            };
            var resizeVoiceButton = function () {
                try {
                    clearTimeout(timeoutId);
                    searchBox.get()[0].style.paddingRight = null;
                    searchBox.get()[0].style.width = null;
                    // @ts-ignore
                    timeoutId = setTimeout(resizeCallback_1, 250);
                }
                catch (ex) {
                    // ccl
                }
            };
            resizeCallback_1();
            resizeVoiceButton();
            sxQuery(window).on('resize', resizeVoiceButton);
            if (repositionTrigger !== undefined) {
                sxQuery(repositionTrigger).on('click', resizeVoiceButton);
            }
            this.reposition = resizeVoiceButton;
        }
        // recognition
        var recognition;
        var stop = function () {
            voiceButton.removeClass('ss360-voice-search--recording');
            if (recognition !== undefined) {
                recognition.stop();
                recognition = undefined;
            }
        };
        // bind listener and render
        voiceButton.click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (recognition !== undefined) {
                stop();
            }
            else {
                /* eslint-disable */
                // @ts-ignore
                recognition = new webkitSpeechRecognition();
                /* eslint-enable */
                recognition.continuous = false;
                recognition.interimResults = false;
                recognition.lang = lang;
                recognition.onstart = function () {
                    voiceButton.addClass('ss360-voice-search--recording');
                    if (voiceButton.find('svg').find('line').length > 0) {
                        var svgCopy = sxQuery(voiceButton.find('svg').get()[0].outerHTML);
                        svgCopy.find('line').remove();
                        voiceButton.get()[0].innerHTML = svgCopy.get()[0].outerHTML;
                    }
                };
                recognition.onresult = function (event) {
                    var query = event.results[0][0].transcript;
                    sxQuery(searchBox).val(query);
                    callback(query, {
                        shouldPushState: true,
                        sbRef: sxQuery(voiceButton)
                    });
                    stop();
                };
                recognition.onerror = function (error) {
                    var err = error.error;
                    console.log(err);
                    if (err === 'not-allowed') {
                        if (voiceButton.find('svg').find('line').length === 0) {
                            var svgCopy = sxQuery(voiceButton.find('svg').get()[0].outerHTML);
                            svgCopy.append('<line y1="24" x2="24.5" y2="0" stroke="#E05350" x1="-0.5" stroke-width="3"></line>');
                            // voiceButton.find("svg").remove();
                            voiceButton.get()[0].innerHTML = svgCopy.get()[0].outerHTML;
                        }
                        voiceButton.attr('title', 'Permission to use microphone is blocked. Please go to your browser settings to enable microphone usage.');
                    }
                    stop();
                };
                recognition.onend = function () {
                    stop();
                };
                recognition.start();
            }
        });
        searchBox.parent().append(voiceButton);
    }
    return VoiceSearch;
}());
export default VoiceSearch;
