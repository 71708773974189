'use strict';
import SearchSuggest from './SearchSuggest';
var ResultGroup = /** @class */ (function () {
    function ResultGroup(name, suggests, searchSuggests) {
        this.name = name;
        if (suggests !== undefined) {
            this.suggests = suggests.map(function (suggest) { return new SearchSuggest(suggest); });
        }
        else {
            this.suggests = searchSuggests;
        }
    }
    return ResultGroup;
}());
export default ResultGroup;
